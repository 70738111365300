import axios, { AxiosProgressEvent } from "axios";
import axiosInstance from "../helpers/axios";
import { BATCH_SIZE, CHUNK_SIZE } from "../helpers/constant";
import { UploadedPart } from "../helpers/types";

class StorageService {
  public async uploadFileMultiPart(file: any, setLoadedData: Function, totalSize?: number, folder?: string) {
    try {
      const chunks = Math.ceil(file.size / CHUNK_SIZE);

      const { uploadId, filename, signedUrls } = await this.initializeMultiPartUpload({
        filename: file.name,
        contentType: file.type,
        partNumbers: chunks,
        totalSize,
        folder,
      });

      let allUploadedParts: UploadedPart[] = [];
      for (let i = 0; i < chunks; i += BATCH_SIZE) {
        const uploadedParts = await this.uploadChunkBatch(i, BATCH_SIZE, chunks, file, signedUrls, setLoadedData);
        allUploadedParts = [...allUploadedParts, ...uploadedParts];
      }

      // Finalize the multipart upload once all chunks are uploaded
      const result = await this.finalizeMultipartUpload(uploadId, allUploadedParts, filename);
      return result;
    } catch (error: any) {
      throw new Error(error.message || "file-upload-failed");
    }
  }

  public initializeMultiPartUpload(data: any) {
    return new Promise<{ uploadId: string; filename: string; signedUrls: string[] }>((resolve, reject) => {
      axiosInstance
        .post(`/storage/init-upload`, data)
        .then((response) => {
          if (response.data.message) {
            reject(new Error(response.data.message));
          } else if (response?.data) {
            resolve(response.data);
          }
        })
        .catch((error) => {
          reject(new Error(error.response?.data?.message || "Something went wrong."));
        });
    });
  }

  // Helper function to process a batch of chunks
  public uploadChunkBatch = async (
    startIndex: number,
    batchSize: number,
    chunks: number,
    file: any,
    signedUrls: string[],
    setLoadedData: Function
  ) => {
    const uploadPromises: Promise<UploadedPart>[] = [];

    // Prepare a batch of `batchSize` chunks
    for (let i = startIndex; i < Math.min(startIndex + batchSize, chunks); i++) {
      const start = i * CHUNK_SIZE;
      const end = Math.min(file.size, start + CHUNK_SIZE);
      const chunk = file.originFileObj.slice(start, end);

      uploadPromises.push(this.uploadChunkToS3(chunk, signedUrls[i], i + 1, file.type, setLoadedData));
    }

    // Wait for the batch to finish uploading
    return await Promise.all(uploadPromises);
  };

  public uploadChunkToS3(chunk: Blob, signedUrl: string, partNumber: number, fileType: string, setLoadedData: Function) {
    let previousLoaded = 0;
    return new Promise<UploadedPart>((resolve, reject) => {
      axios
        .put(signedUrl, chunk, {
          headers: {
            "Content-Type": fileType,
          },
          onUploadProgress: (progressEvent: AxiosProgressEvent) => {
            const currentLoaded = progressEvent.loaded;
            const chunkLoaded = currentLoaded - previousLoaded;
            setLoadedData((prev: number) => prev + chunkLoaded);
            previousLoaded = currentLoaded;
          },
        })
        .then((response) => {
          const etag = response.headers["etag"];
          if (etag) {
            resolve({
              ETag: etag,
              PartNumber: partNumber,
            });
          } else {
            reject("Something went wrong.");
          }
        })
        .catch((error) => {
          reject(error.response?.data?.message || "Something went wrong.");
        });
    });
  }

  /* public uploadMultiChunk(data: FormData, setLoadedData: Function) {
    let previousLoaded = 0;

    return new Promise<string>((resolve, reject) => {
      axiosInstance
        .post(`/storage/upload-chunk`, data, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent: AxiosProgressEvent) => {
            const currentLoaded = progressEvent.loaded;
            const chunkLoaded = currentLoaded - previousLoaded;
            setLoadedData((prev: number) => prev + chunkLoaded);
            previousLoaded = currentLoaded;
          },
        })
        .then((response) => {
          if (response?.data?.ETag) {
            resolve(response.data.ETag);
          } else {
            reject("Something went wrong.");
          }
        })
        .catch((error) => {
          reject(error.response?.data?.message || "Something went wrong.");
        });
    });
  } */

  public finalizeMultipartUpload(uploadId: string, parts: any[], filename: string) {
    return new Promise<any>((resolve, reject) => {
      axiosInstance
        .post(`/storage/finalize-upload`, { uploadId, parts, filename })
        .then((response) => {
          if (response?.data?.message) {
            resolve(response.data.message);
          } else {
            reject("Something went wrong.");
          }
        })
        .catch((error) => {
          reject(error.response?.data?.message || "Something went wrong.");
        });
    });
  }

  public cancelMultipartUpload(uploadId: string, filename: string) {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`/storage/cancel-upload`, { uploadId, filename })
        .then((response) => {
          if (response?.data?.message) {
            resolve(response.data.message);
          } else {
            reject("Something went wrong.");
          }
        })
        .catch((error) => {
          reject(error.response?.data?.message || "Something went wrong.");
        });
    });
  }
}

export const storageService = new StorageService();
