import { Row, Typography } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import "./index.less";

type DeleteMediasAlbumModalProps = {
  visible: boolean;
  onDelete?: () => void;
  onClose?: () => void;
};

const DeleteMediasAlbumModalComponent: React.FC<DeleteMediasAlbumModalProps> = ({ visible, onDelete, onClose }) => {
  const { t } = useTranslation();

  if (!visible) return null;

  const handleOverlayClick = () => {
    if (onClose) {
      onClose();
    }
  };

  const handleModalClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
  };

  return (
    <div className="bottom-modal-overlay" onClick={handleOverlayClick}>
      <div className="bottom-modal-content" onClick={handleModalClick}>
        <Row className="w-full justify-content-center mt-10">
          <div className="header-icon-grey" onClick={handleOverlayClick}></div>
        </Row>
        <div className="bottom-modal-header">
          <Typography.Text className="bottom-modal-title f-26">{t("Do-you-really-want-delete-these-files?")}</Typography.Text>
        </div>
        <div className="bottom-modal-content mt-10">
          <button className="bottom-modal-button mt-14" onClick={onDelete}>
            <Typography.Text className="bottom-modal-button-title">{t("Yes-delete")}</Typography.Text>
          </button>

          <button className="bottom-modal-cancel-button" onClick={onClose}>
            <Typography.Text className="bottom-modal-cancel-button-title">{t("cancel")}</Typography.Text>
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteMediasAlbumModalComponent;
