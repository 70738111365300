import { Button, Col, Image, Layout, Modal, Row, Spin, Typography, message } from "antd";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import VideoIcon from "../../../assets/icons/jouer.svg";
import SelectedIcon from "../../../assets/icons/selected.svg";
import UnselectIcon from "../../../assets/icons/unselect.svg";
import ArrowLeftIconComponent from "../../../components/ArrowLeftIconComponent";
import SpinnerComponent from "../../../components/SpinnerComponent/SpinnerComponent";
import VideoPlayer from "../../../components/VideoPlayerComponent";
import { FALLBACK_IMAGE, FILE_STATUS, THUMBNAIL_TYPES } from "../../../helpers/constant";
import { IThumbnail } from "../../../helpers/types";
import { getQuickFileAlbumDetails, updateQuickFile } from "../../../redux/quickFile/quickFileSlice";
import { AppDispatch } from "../../../redux/store";
import { quickFileService } from "../../../services/quickFile.service";
import { socket } from "../../../socket";
import "./index.less";
import DeleteMediasAlbumModalComponent from "../../../components/DeleteMediasAlbumModalComponent";

const CreatorAlbumFeedsComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { albumId } = useParams();
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();
  const AlbumFeeds = useSelector((state: any) => state.quickFile.item);
  const shouldFetch = useRef(true);
  const [select, setSelect] = useState<any>([]);
  const [edit, setEdit] = useState(false);
  const [modelIsOpen, setModalIsOpen] = useState(false);
  const [queryParams, setQueryParams] = useState({ id: "", conversationId: "", messageId: "", receiverId: "", collectionTitle: "" });
  const [selectSendImages, setSelectSendImages] = useState<any>([]);
  const [visible, setVisible] = useState<boolean>(false);
  const [currentVideoUrl, setCurrentVideoUrl] = useState<string>("");

  useEffect(() => {
    if (shouldFetch.current) {
      shouldFetch.current = false;
      dispatch(getQuickFileAlbumDetails(albumId));
    }

    function handleFileUpdated(data: { fileId: string; absolutePath: string; thumbnails: any; status: string }) {
      dispatch(updateQuickFile({ albumId, data }));
    }

    socket.on("file-updated", handleFileUpdated);

    return () => {
      socket.off("file-updated", handleFileUpdated);
    };
  }, []);

  const selectFeed = (id: any) => {
    if (edit) {
      const isSelected = select.some((item: any) => item.id === id);
      if (queryParams.id) {
        if (queryParams.id !== "unlock-link") {
          if (isSelected) {
            setSelectSendImages(select.filter((item: any) => item !== id));
          } else {
            let items = selectSendImages;
            items.push(id);
            setSelectSendImages(items);
          }
        } else {
          setSelectSendImages([id]);
        }
      }
      if (isSelected) {
        setSelect(select.filter((item: any) => item.id !== id));
      } else {
        if (queryParams.id === "unlock-link") {
          setSelect([{ id }]);
        } else {
          setSelect([...select, { id }]);
        }
      }
    }
  };

  const handleVideoIconClick = (url: string, isVisible: boolean) => {
    setCurrentVideoUrl(isVisible ? url : "");
    setVisible(isVisible);
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const id = params.get("id") || "";
    const conversationId = params.get("conversationId") || "";
    const receiverId = params.get("receiverId") || "";
    const messageId = params.get("messageId") || "";
    const collectionTitle = params.get("collectionTitle") || "";
    if (id) {
      setEdit(true);
    }
    setQueryParams({ id, conversationId, receiverId, messageId, collectionTitle });
  }, []);

  async function deleteFiles() {
    let files: any = [];
    select.forEach((item: any) => {
      files.push(item.id);
    });
    const payload = {
      albumId: albumId,
      filesId: files,
    };
    try {
      quickFileService
        .deleteAlbumFiles(payload)
        .then((res) => {
          message.success(t("deleted-files-success-message"));
          setModalIsOpen(false);
          setEdit(false);
          setSelect([]);
          dispatch(getQuickFileAlbumDetails(albumId));
        })
        .catch((err) => {
          message.error("Something went wrong");
          console.log("err", err);
        });
    } catch (err) {
      message.error("Something went wrong");
      console.error("err", err);
    }
  }

  const handleSendClick = () => {
    if (queryParams.id === "conversation") {
      navigate(`/creator/conversation/${queryParams.conversationId}?selected=${JSON.stringify(selectSendImages)}`);
    } else if (queryParams.id === "mediaPush") {
      navigate(
        `/creator/mediaPush?conversation=${queryParams?.conversationId !== ""}&conversationId=${queryParams?.conversationId}&receiverId=${queryParams?.receiverId}&messageId=${queryParams?.messageId}&selected=${JSON.stringify(selectSendImages)}`
      );
    } else if (queryParams.id === "unlock-link") {
      navigate(`/creator/createDecrypt?selected=${JSON.stringify(selectSendImages)}`);
    } else if (queryParams.id === "collection") {
      navigate(`/creator/create/collection?collectionTitle=${queryParams.collectionTitle}&selected=${JSON.stringify(selectSendImages)}`);
    }
  };

  return (
    <Layout className="gradient-background-blue-settings">
      <Layout.Content className="content CreatorAlbumContentWrapper background-transparent">
        <Row className="relative justify-content-center header-container-title-quick-file">
          {queryParams?.conversationId ? (
            <ArrowLeftIconComponent
              left={16}
              top={10}
              navigateTo={`/creator/quickLink?id=mediaPush&conversationId=${queryParams?.conversationId}&receiverId=${queryParams?.receiverId}&messageId=${queryParams?.messageId}`}
            />
          ) : (
            <ArrowLeftIconComponent top={10} left={16} />
          )}
          <Typography.Text className="header-title-black-35">{AlbumFeeds?.name}</Typography.Text>
        </Row>
        <Row>
          <Typography.Text className="ml-20 mt-30 font-18-bold text-white-color">
            {queryParams?.id ? t("select-the-media-you-want-to-send") : !edit ? t("what-do-you-want-to-do") : t("select-media")}
          </Typography.Text>
        </Row>
        {!queryParams?.id && (
          <Row className="creatorAlbumButton w-full">
            <div className="w-full justify-center">
              {edit && select.length > 0 ? (
                <div className="button-container">
                  <Button onClick={() => setModalIsOpen(true)} className="CreatorAlbumActionButton half-width-button">
                    {t("Delete")}
                  </Button>
                  <Button
                    onClick={() => {
                      setEdit(false);
                      setSelect([]);
                    }}
                    className="CreatorAlbumActionButton half-width-button"
                  >
                    {t("cancel")}
                  </Button>
                </div>
              ) : edit ? (
                <div className="button-container">
                  <Button className="CreatorAlbumActionButton disable half-width-button" disabled>
                    {t("Delete")}
                  </Button>
                  <Button
                    onClick={() => {
                      setEdit(false);
                      setSelect([]);
                    }}
                    className="CreatorAlbumActionButton half-width-button"
                  >
                    {t("cancel")}
                  </Button>
                </div>
              ) : (
                <div className="button-container">
                  {AlbumFeeds?.files.length > 0 && (
                    <Button onClick={() => setEdit(true)} className="CreatorAlbumActionButton half-width-button">
                      {t("modify-album")}
                    </Button>
                  )}
                  <Button onClick={() => navigate("/creator/AddMedia/" + albumId)} className="CreatorAlbumActionButton half-width-button">
                    {t("add-media")}
                  </Button>
                </div>
              )}
            </div>
          </Row>
        )}
        {!queryParams?.id && (
          <Row>
            <Typography.Text className="ml-20 mb-10 mt-10 font-24-bold text-white-color">{t("media-for-your-album")}</Typography.Text>
          </Row>
        )}
        <Row className="fanBookmarksContent mt-5">
          {AlbumFeeds?.files?.length > 0 &&
            AlbumFeeds?.files?.map((item: any, index: any) => {
              const isSelected = select.some((selectedItem: any) => selectedItem.id === item.fileId);
              const isUploading: boolean = item?.status === FILE_STATUS.UPLOADING;
              return (
                <Col onClick={() => selectFeed(item.fileId)} key={index} span={8} className="p-1 relative">
                  {edit ? (
                    <>
                      <div className="cover"></div>
                      <img
                        className="absolute selectIcon"
                        src={isSelected ? SelectedIcon : UnselectIcon}
                        alt={isSelected ? "selected" : "unselected"}
                      />
                    </>
                  ) : null}
                  <Spin spinning={isUploading} className="feedSpinner" indicator={<SpinnerComponent />} />
                  {isUploading ? (
                    <div className="imagePlaceHolder" />
                  ) : (
                    <Image
                      src={
                        item.url?.includes(".mp4")
                          ? item.thumbnails?.find((thumb: IThumbnail) => thumb.type === THUMBNAIL_TYPES.CLEAR)?.absolutePath
                          : item.url
                      }
                      fallback={FALLBACK_IMAGE}
                      preview={
                        !item.url?.includes(".mp4")
                          ? {}
                          : {
                              destroyOnClose: true,
                              visible: visible && currentVideoUrl === item.url,
                              onVisibleChange: (value) => handleVideoIconClick(item.url, value),
                              modalRender: () => {
                                if (currentVideoUrl === item.url) {
                                  return (
                                    <div className="video-preview-wrapper">
                                      <VideoPlayer videoUrl={currentVideoUrl} thumbnailUrl={""} autoPlay={true} />
                                    </div>
                                  );
                                }
                                return undefined;
                              },
                            }
                      }
                    />
                  )}
                  {item.url?.includes(".mp4") && !isUploading && (
                    <img src={VideoIcon} alt="Loly - video icon" className="albumVideoIcon" onClick={() => handleVideoIconClick(item.url, true)} />
                  )}
                </Col>
              );
            })}
        </Row>
        <DeleteMediasAlbumModalComponent visible={modelIsOpen} onClose={() => setModalIsOpen(false)} onDelete={deleteFiles} />
      </Layout.Content>
      {queryParams?.id && (
        <Layout.Footer className="CreatorAlbumFooter">
          <Row className="w-full justify-content-center">
            {select.length === 0 ? (
              <Button className={`CreatorAlbumSelectButton disable`}>{t(`send`)}</Button>
            ) : (
              <Button className={`CreatorAlbumSelectButton `} onClick={handleSendClick}>
                {t(`send`)}
              </Button>
            )}
          </Row>
        </Layout.Footer>
      )}
    </Layout>
  );
};

export default CreatorAlbumFeedsComponent;
