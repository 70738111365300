import React from "react";
import { Row, Space, Typography } from "antd";
import { ILiveComment, ITop3Tips } from "../../helpers/types";
import LiveTipsNumberOneIcon from "../../assets/icons/live-tips-1.svg";
import LiveTipsNumberTwoIcon from "../../assets/icons/live-tips-2.svg";
import LiveTipsNumberThreeIcon from "../../assets/icons/live-tips-3.svg";
import "./index.less";

type props = {
  comments: ILiveComment[] | null;
  topTips?: ITop3Tips[] | null;
};

const LiveCommentsComponent: React.FC<props> = ({ comments, topTips }) => {
  const tipIcons = [LiveTipsNumberOneIcon, LiveTipsNumberTwoIcon, LiveTipsNumberThreeIcon];

  return (
    <Space className="w-full flex-col-reverse gap-10" direction="vertical">
      {comments?.map((comment, index) => {
        const tipIndex = topTips?.findIndex((tip) => tip._id === comment?.user?._id);
        const TipIcon = tipIndex !== undefined && tipIndex >= 0 && tipIndex < tipIcons.length ? tipIcons[tipIndex] : null;

        return (
          <Row className="ml-10" key={index}>
            <Space className="gap-6" align="start">
              <Space className="gap-0" direction="vertical">
                <div className="live-comments-row">
                  <Typography.Text className="live-username-text">{comment?.user?.username}</Typography.Text>
                  {TipIcon && <img src={TipIcon} className="top-icon" />}
                </div>

                <Typography.Text className="live-comment-text">{comment?.text}</Typography.Text>
              </Space>
            </Space>
          </Row>
        );
      })}
    </Space>
  );
};

export default LiveCommentsComponent;
