import React from "react";
import { Typography } from "antd";
import { useSelector } from "react-redux";
import { StoreState } from "../../redux/store";
import { EURO_SYMBOLE } from "../../helpers/constant";
import "./index.less";

interface LiVeTipsProps {
  fromFan?: boolean;
}

const LiveTipsComponent: React.FC<LiVeTipsProps> = ({ fromFan = false }) => {
  const liveTip = useSelector((state: StoreState) => state.liveStreamStats.tips.tips);

  return (
    <>
      {liveTip && (
        <div className="tips-container last-tips fan-style">
          <Typography.Text className={`username-text ${fromFan ? "fan-text" : ""}`}>{liveTip.username}</Typography.Text>
          <Typography.Text className={`price-text ${fromFan ? "fan-text" : ""}`}>
            {parseFloat(liveTip.amount).toFixed(2)} {EURO_SYMBOLE}
          </Typography.Text>
        </div>
      )}
    </>
  );
};

export default LiveTipsComponent;
