import { Col, Modal, Row, Typography } from "antd";
import { useEffect } from "react";
import "./index.less";
import { useTranslation } from "react-i18next";

type Props = {
  open: boolean;
  handleToggle: () => void;
  handleConfirm: () => void;
  confBtnMsg: string;
  title?: string;
  subTitle?: string;
};

export const ConfirmationModal = (props: Props) => {
  const { t } = useTranslation();
  const { open, handleConfirm, handleToggle, confBtnMsg, title, subTitle } = props;

  if (!open) return null;

  const handleOverlayClick = () => {
    if (handleToggle) {
      handleToggle();
    }
  };

  const handleModalClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
  };

  return (
    <div className="bottom-modal-overlay" onClick={handleOverlayClick}>
      <div className="bottom-modal-content" onClick={handleModalClick}>
        <Row className="w-full justify-content-center mt-10">
          <div className="header-icon-grey" onClick={handleOverlayClick}></div>
        </Row>
        <div className="bottom-modal-header px-20">
          <Typography.Text className="bottom-modal-title f-26">{title}</Typography.Text>
          {subTitle && <Typography.Text className="bottom-modal-sub-title">{subTitle}</Typography.Text>}
        </div>
        <div className="bottom-modal-content">
          <button className="bottom-modal-button" onClick={handleConfirm}>
            <Typography.Text className="bottom-modal-button-title">{confBtnMsg}</Typography.Text>
          </button>

          <button className="bottom-modal-cancel-button" onClick={handleToggle}>
            <Typography.Text className="bottom-modal-cancel-button-title">{t("cancel")}</Typography.Text>
          </button>
        </div>
      </div>
    </div>
  );
};
