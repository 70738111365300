import axios from "../helpers/axios";
import { DEFAULT_COLLECTION_PAGE_REPORT } from "../helpers/constant";
import { ICollections, ICreatorCollection, IDeleteResponse, IFanCollection, PageableData } from "../helpers/types";

class CollectionService {
  public createCollection = (data: FormData, setProgress: Function) => {
    return new Promise<ICollections>((resolve, reject) => {
      axios
        .post(`/collections/creators`, data, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (event) => {
            let progress = Math.round((100 * event.loaded) / event.total!);
            progress = Math.round(progress / 2);
            if (progress <= 50) {
              setProgress(progress);
            }
          },
        })
        .then((response) => {
          if (response?.data?.data) {
            resolve(response.data.data);
          } else if (response?.data?.message) {
            resolve(response.data);
          } else {
            reject("Something went wrong.");
          }
        })
        .catch((error) => {
          if (error?.response?.data?.message) reject(error.response.data.message);
          else reject("Something went wrong.");
        });
    });
  };

  public getCreatorCollections = (page: number) => {
    const url = `/collections/creators?page=${page}`;
    return new Promise<PageableData<ICollections>>((resolve, reject) => {
      axios
        .get(url)
        .then((response) => {
          if (response?.data?.data) resolve(response.data.data);
          else reject("Something went wrong");
        })
        .catch((error) => {
          if (error?.response?.data?.message) reject(error.response.data.message);
          else reject("Something went wrong");
        });
    });
  };

  public getFanCollections = (creatorId: string, page: number) => {
    const url = `/collections?page=${page}&creatorId=${creatorId}`;
    return new Promise<PageableData<ICollections>>((resolve, reject) => {
      axios
        .get(url)
        .then((response) => {
          if (response?.data?.data) resolve(response.data.data);
          else reject("Something went wrong");
        })
        .catch((error) => {
          if (error?.response?.data?.message) reject(error.response.data.message);
          else reject("Something went wrong");
        });
    });
  };

  public getCreatorCollectionDetail = (id: string) => {
    const url = `/collections/creators/data/${id}`;
    return new Promise<ICreatorCollection>((resolve, reject) => {
      axios
        .get(url)
        .then((response) => {
          if (response?.data?.data) resolve(response.data.data);
          else reject("Something went wrong");
        })
        .catch((error) => {
          if (error?.response?.data?.message) reject(error.response.data.message);
          else reject("Something went wrong.");
        });
    });
  };

  public getFanCollectionDetail = (id: string) => {
    const url = `/collections/fan/${id}`;
    return new Promise<IFanCollection>((resolve, reject) => {
      axios
        .get(url)
        .then((response) => {
          if (response?.data?.data) resolve(response.data.data);
          else reject("Something went wrong");
        })
        .catch((error) => {
          if (error?.response?.data?.message) reject(error.response.data.message);
          else reject("Something went wrong.");
        });
    });
  };

  public deleteCollection = (id: string) => {
    const url = `/collections/creators/${id}`;
    return new Promise<IDeleteResponse>((resolve, reject) => {
      axios
        .delete(url)
        .then((response) => {
          if (response?.data?.data) {
            resolve({ success: true, _id: id });
          } else {
            reject("Something went wrong");
          }
        })
        .catch((error) => {
          if (error?.response?.data?.message) reject(error.response.data.message);
          else reject("Something went wrong.");
        });
    });
  };

  public getPublicCollections = (creatorId: string, page: number) => {
    const url = `/collections/public/${creatorId}?page=${page}`;
    return new Promise<PageableData<ICollections>>((resolve, reject) => {
      axios
        .get(url)
        .then((response) => {
          if (response?.data?.data) resolve(response.data.data);
          else reject("Something went wrong");
        })
        .catch((error) => {
          if (error?.response?.data?.message) reject(error.response.data.message);
          else reject("Something went wrong");
        });
    });
  };

  public stripeCollection = (body: any) => {
    const url = `/payment/stripe/collectionPurchase`;
    return axios
      .post(url, body)
      .then((res) => {
        return res?.data;
      })
      .catch((error) => {
        return error?.response;
      });
  };

  public applePayCollection = (body: any) => {
    const url = `/payment/applePay/collectionPurchase`;
    return axios
      .post(url, body)
      .then((res) => {
        return res?.data;
      })
      .catch((error) => {
        return error?.response;
      });
  };

  public verifyPaymentStatus = (id: string) => {
    const url = `/payment/psp/verifyPaymentStatus/${id}`;
    return new Promise<any>((resolve, reject) => {
      axios
        .get(url)
        .then((response) => {
          if (response?.data?.data) {
            resolve(response.data.data);
          } else {
            reject("Something went wrong.");
          }
        })
        .catch((error) => {
          if (error?.response?.data?.message) reject(error.response.data.message);
          else reject("Something went wrong.");
        });
    });
  };

  public create3dSecureTransaction = (transactionId: any, token: any) => {
    const url = `/payment/shift/oneTimeThree3DSVerification`;
    return axios
      .post(url, { transactionId, token })
      .then((res) => {
        return res?.data;
      })
      .catch((error) => {
        return error?.response;
      });
  };

  public getCreatorEarningReport = (id : string , page: any) => {
    const url = `/collections/creators/report?page=${page}&collectionId=${id}&limit=${DEFAULT_COLLECTION_PAGE_REPORT}`;
    return new Promise<PageableData<ICollections>>((resolve, reject) => {
      axios
        .get(url)
        .then((response) => {
          if (response?.data?.data) resolve(response.data.data);
          else reject("Something went wrong");
        })
        .catch((error) => {
          if (error?.response?.data?.message) reject(error.response.data.message);
          else reject("Something went wrong");
        });
    });
  };

}

export const collectionService = new CollectionService();
