import type { TabsProps } from "antd";
import { Layout, Row, Tabs, Typography } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import RefreshIcon from "../../../assets/icons/refresh.svg";
import Flame from "../../../assets/images/flame.webp";
import Popcorn from "../../../assets/images/popcorn.webp";
import FanEventsListTabComponent from "../../../components/FanEventsListTabComponent";
import FanLivesSwiper from "../../../components/FanLivesSwiper";
import FooterComponent from "../../../components/FooterComponent";
import { FOOTER_IDS, LIVE_TYPES } from "../../../helpers/constant";
import { getLivesForFan, newLivePublished } from "../../../redux/lives/livesSlice";
import { AppDispatch, StoreState } from "../../../redux/store";
import "./index.less";

const FanLivesPage = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const [activeTabKey, setActiveTabKey] = useState<string>(searchParams.get("tab") || "schedule");
  const isNewLiveAvailable = useSelector((state: StoreState) => state.lives.isNewLive);

  const tabs: TabsProps["items"] = [
    {
      key: LIVE_TYPES.NOW,
      label: (
        <span className="label-tabs">
          <img className="icon-tabs" src={Flame} alt="Flame" />
          {t("onlive")}
        </span>
      ),
      children: <FanLivesSwiper />,
    },
    {
      key: LIVE_TYPES.SCHEDULE,
      label: (
        <span className="label-tabs">
          <img className="icon-tabs" src={Popcorn} alt="Popcorn" />
          {t("events")}
        </span>
      ),
      children: <FanEventsListTabComponent />,
    },
  ];

  useEffect(() => {
    if (!activeTabKey) return;
    navigate(`/p/fan/lives?tab=${activeTabKey}`);
  }, [activeTabKey]);

  const getLives = async () => {
    await dispatch(getLivesForFan({ startType: LIVE_TYPES.NOW }));
    dispatch(newLivePublished(false));
  };

  return (
    <Layout>
      <Layout.Content className="fanLivesContentWrapper gradient-background-live-event">
        <Row className="header-live justify-content-between" align="middle">
          <Typography.Text className="header-title-black-35 uppercase">{t("lives")}</Typography.Text>
          {isNewLiveAvailable && activeTabKey === LIVE_TYPES.NOW && (
            <div className="new-live-button" onClick={getLives}>
              <img src={RefreshIcon} width={14} />
              <Typography.Text className="new-live-text">{t("new-live-refresh")}</Typography.Text>
            </div>
          )}
        </Row>
        <Tabs className="live-tabs" items={tabs} activeKey={activeTabKey} onTabClick={(key: string) => setActiveTabKey(key)} centered />
      </Layout.Content>
      <FooterComponent type="fan" page={FOOTER_IDS.LIVE} />
    </Layout>
  );
};

export default FanLivesPage;
