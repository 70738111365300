import { useEffect, useState } from "react";
import { authService } from "../../services";
import { addWatermark } from "../../utls/FunctionsUtil";

type props = {
  shouldAddWatermark?: boolean;
  image: string;
  classProps: string;
};

const WatermarkedImageComponent: React.FC<props> = ({ image, shouldAddWatermark, classProps }) => {
  const [watermarkedImageUrl, setWatermarkedImageUrl] = useState<string>(shouldAddWatermark ? "" : image);

  useEffect(() => {
    addWatermark(image, authService.getUser().username)
      .then((newImageUrl: string) => {
        setWatermarkedImageUrl(newImageUrl);
      })
      .catch((err: any) => {
        console.log("Error generating watermarked image:", err);
        setWatermarkedImageUrl(image);
      });
  }, [image, shouldAddWatermark]);

  return <img className={classProps} src={watermarkedImageUrl} alt={"watermarked"} />;
};

export default WatermarkedImageComponent;
