import { useState } from "react";
import AuthSignInTabComponent from "../../../components/AuthSignInFanTabComponent";
import AuthGoogleUnavailableComponent from "../../../components/AuthGoogleUnavailableComponent";

const SignInPage = () => {
  const [authGoogleUnavailable, setAuthGoogleUnavailable] = useState<boolean>(false);

  const googleUnavailable = () => {
    setAuthGoogleUnavailable(true);
  };

  const googleUnavailableCancel = () => {
    setAuthGoogleUnavailable(false);
  };

  return (
    <>
      {authGoogleUnavailable ? (
        <AuthGoogleUnavailableComponent cancel={googleUnavailableCancel} />
      ) : (
        <AuthSignInTabComponent googleUnavailable={googleUnavailable} />
      )}
    </>
  );
};

export default SignInPage;
