import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { Button, Col, Modal, Row, Typography } from "antd";
import "./index.less";

type CollectionDeleteModalProps = {
  isOpen: boolean;
  onClose: () => void;
  deleteCollection: () => void;
};

const CollectionDeleteModalComponent: React.FC<CollectionDeleteModalProps> = ({ isOpen, onClose, deleteCollection }) => {
  const { t } = useTranslation();

  if (!isOpen) return null;

  const handleOverlayClick = () => {
    if (onClose) {
      onClose();
    }
  };

  const handleModalClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
  };

  return (
    <div className="bottom-modal-overlay" onClick={handleOverlayClick}>
      <div className="bottom-modal-content" onClick={handleModalClick}>
        <Row className="w-full justify-content-center mt-10">
          <div className="header-icon-grey" onClick={handleOverlayClick}></div>
        </Row>
        <div className="bottom-modal-header">
          <Typography.Text className="bottom-modal-title f-24">{t("delete-collection-modal-title")}</Typography.Text>
        </div>
        <div className="bottom-modal-content mt-10">
          <button className="bottom-modal-button mt-14" onClick={deleteCollection}>
            <Typography.Text className="bottom-modal-button-title">{t("Yes-delete")}</Typography.Text>
          </button>

          <button className="bottom-modal-cancel-button" onClick={onClose}>
            <Typography.Text className="bottom-modal-cancel-button-title">{t("cancel")}</Typography.Text>
          </button>
        </div>
      </div>
    </div>
  );
};

export default CollectionDeleteModalComponent;
