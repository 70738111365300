import { Layout, Row, Space, Typography } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ArrowLeftIconComponent from "../../../components/ArrowLeftIconComponent";
import AvatarComponent from "../../../components/AvatarComponent";
import SearchComponent from "../../../components/SearchComponent";
import SpinnerComponent from "../../../components/SpinnerComponent/SpinnerComponent";
import VerifiedUserIconComponent from "../../../components/VerifiedUserIconComponent/VerifiyedUserIconComponent";
import { CREATOR, FAN } from "../../../helpers/constant";
import { IConversation, IUser } from "../../../helpers/types";
import { selectConversation } from "../../../redux/messages/messagesSlice";
import { AppDispatch, StoreState } from "../../../redux/store";
import { resetList, search, searchMoreUsers } from "../../../redux/users/usersSlice";
import { authService, messageService } from "../../../services";
import "./index.less";

const NB_USER_TO_SHOW = 20;

const NewMessagePage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [searchingValue, setSearchingValue] = useState<string>("");
  const { list: creators, total, loading } = useSelector((state: StoreState) => state.users);
  const [page, setPage] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(false);

  const searchCreators = (value: string) => {
    if (value !== "") {
      setSearchingValue(value);
      setIsSearching(true);
      setHasNextPage(true);
      setPage(1);
      const payload = {
        q: value,
        limit: NB_USER_TO_SHOW,
        getSelf: "false",
      };
      dispatch(search(payload));
    } else {
      setSearchingValue("");
      setIsSearching(false);
      setHasNextPage(false);
      setPage(1);
      dispatch(resetList());
    }
  };

  function loadMoreUsers() {
    if (isSearching) {
      const nextPage = page + 1;
      const totalPages = Math.ceil(total! / NB_USER_TO_SHOW);
      const payload = {
        q: searchingValue,
        limit: NB_USER_TO_SHOW,
        offset: page * NB_USER_TO_SHOW,
        getSelf: "false",
      };
      dispatch(searchMoreUsers(payload));
      if (nextPage >= totalPages) {
        setHasNextPage(false);
      }
      setPage(nextPage);
    }
  }

  const handleChange = (event: any) => {
    searchCreators(event.target.value);
  };

  const creatorClicked = async (creator: IUser) => {
    const currentUserRole = authService.getRole();
    let urlToNavigate = currentUserRole === CREATOR ? "/creator/conversation/new" : "/fan/requestMedia/new";
    const currentUserId = authService.getId();
    const conversationId = await messageService.isConversationExist({
      fanId: currentUserId,
      creatorId: creator._id !== undefined ? creator._id : "",
    });
    if (conversationId === null) {
      const newConversation: IConversation = {
        _id: "new",
        fanCanSendMessage: true,
        interlocutor: {
          userId: creator._id,
          avatar: creator.avatar,
          name: creator.name,
          role: CREATOR,
        },
        currentUser: {
          userId: currentUserId,
          avatar: "",
          name: "",
          role: FAN,
        },
      };
      dispatch(selectConversation(newConversation));
      urlToNavigate = urlToNavigate + `?newConversation=${JSON.stringify(newConversation)}`;
    } else {
      urlToNavigate = urlToNavigate.replace("new", conversationId);
    }
    navigate(urlToNavigate, { replace: true });
  };

  const [sentryRef] = useInfiniteScroll({
    loading: loading,
    hasNextPage,
    onLoadMore: loadMoreUsers,
  });

  return (
    <Layout>
      <Layout.Content className="content newMessageContentWrapper gradient-background-black-blue">
        <Row className="relative justify-content-center header-title-container-60 mb-20">
          <ArrowLeftIconComponent top={2} left={0} />
          <Typography.Text className="header-title-black-24">{t("new-message")}</Typography.Text>
        </Row>
        <SearchComponent placeholder={t("search-2")} handleChange={handleChange} />
        {isSearching && creators
          ? creators.map((creator) => {
              return (
                <Row className="mt-14 cursor-pointer" key={creator._id} onClick={() => creatorClicked(creator)}>
                  <Space className="gap-14">
                    <AvatarComponent image={creator.avatar} size={61} />
                    <Space className="gap-4" direction="vertical">
                      <Space className="items-center">
                        <Typography.Text className="name-label">{creator.name}</Typography.Text>
                        <VerifiedUserIconComponent verified={creator?.verifiedAccount} fetch={false} />
                      </Space>
                      <Typography.Text className="text-grey-color">@{creator.username}</Typography.Text>
                    </Space>
                  </Space>
                </Row>
              );
            })
          : ""}
        {(loading || hasNextPage) && <SpinnerComponent refProp={sentryRef} />}
      </Layout.Content>
    </Layout>
  );
};

export default NewMessagePage;
