import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IMediapushState } from "../../helpers/types";
import { mediapushService } from "../../services";

const initialState: IMediapushState = {
  list: null,
  item: null,
  loading: false,
  error: null,
  files: [],
  quickFiles: [],
};

export const createMediapush = createAsyncThunk("mediapush/createMediapush", async (data: FormData) => {
  return mediapushService.createMediaPush(data, () => {});
});

const mediaPushSlice = createSlice({
  name: "mediaPushes",
  initialState,
  reducers: {
    setSelectFiles: (state, action) => {
      if (action.payload.files !== undefined) {
        state.files = action.payload.files;
      }
      if (action.payload.quickFiles !== undefined) {
        state.quickFiles = action.payload.quickFiles;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createMediapush.fulfilled, (state, action) => {
      state.item = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(createMediapush.pending, (state, action) => {
      state.item = null;
      state.loading = true;
      state.error = null;
    });
    builder.addCase(createMediapush.rejected, (state, action) => {
      state.item = null;
      state.loading = false;
      state.error = action.error.message || "Something went wrong";
    });
  },
});

export const { setSelectFiles } = mediaPushSlice.actions;

export default mediaPushSlice.reducer;
