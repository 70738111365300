import { Button, Dropdown, Layout, Menu, MenuProps, message, Radio, Row, Space, Typography } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ArrowBottomGreenIcon from "../../../assets/icons/arrow-bottom-green.svg";
import ArrowRightGreenIcon from "../../../assets/icons/arrow-right-green.svg";
import GreenBottomChevronIcon from "../../../assets/icons/green-bottom-chevron.svg";
import InfoBlackGreyIcon from "../../../assets/icons/info-black-grey.svg";
import EurFlagImg from "../../../assets/images/eur-flag.webp";
import CreatorRevenueChartComponent from "../../../components/CreatorRevenueChartComponent";
import CreatorRevenueTabComponent from "../../../components/CreatorRevenueTabComponent";
import FooterComponent from "../../../components/FooterComponent";
import InfoModal from "../../../components/InfoModal";
import { FOOTER_IDS } from "../../../helpers/constant";
import { IRevenue } from "../../../helpers/types";
import { userService } from "../../../services";
import useInfiniteScroll from "react-infinite-scroll-hook";
import SpinnerComponent from "../../../components/SpinnerComponent/SpinnerComponent";
import "./index.less";

const CreatorRevenuePage = () => {
  const { t } = useTranslation();
  const [revenue, setRevenue] = useState<IRevenue>();
  const [graphData, setGraphData] = useState<string>("today");
  const [isInfoModal, setIsInfoModal] = useState<boolean>(false);
  const [infoContent, setInfoContent] = useState<any>(null);
  const [isPendingPaymentOpen, setIsPendingPaymentOpen] = useState<boolean>(true);
  const [activeTab, setActiveTab] = useState<"daily" | "monthly" | "detailed">("monthly");

  const [loading, setLoading] = useState<boolean>(false);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [limit, setLimit] = useState<number>(10);
  const [offset, setOffset] = useState<number>(0);

  const loadMoreData = async () => {
    if (loading || !hasMore) return;

    setLoading(true);
    try {
      if (activeTab === "detailed") {
        const response = await userService.getRevenue(graphData, limit, offset);
        if (response?.detailPaymentReport?.length > 0) {
          setRevenue((prev: any) => ({
            ...prev,
            detailPaymentReport: [...(prev?.detailPaymentReport || []), ...response.detailPaymentReport],
          }));
          setOffset((prevOffset) => prevOffset + response.detailPaymentReport.length);
          setLimit((prevLimit) => prevLimit + 10);
        } else {
          setHasMore(false);
        }
      }
    } catch (error) {
      message.error("Something went wrong.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (activeTab === "detailed") {
      loadMoreData();
    } else {
      userService
        .getRevenue(graphData)
        .then((response) => {
          setRevenue(response);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [graphData, activeTab]);

  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage: hasMore,
    onLoadMore: loadMoreData,
  });

  const menuItems: MenuProps["items"] = [
    {
      key: "daily",
      label: t("daily"),
    },
    {
      key: "monthly",
      label: t("monthly"),
    },
    {
      key: "detailed",
      label: t("detailed"),
    },
  ];

  const handleMenuClick = (e: { key: string }) => {
    setActiveTab(e.key as "daily" | "monthly" | "detailed");
  };

  return (
    <Layout className="gradient-background-black-blue">
      <Layout.Content className="content creatorRevenueContentWrapper background-transparent">
        <Row className="relative pl-20 header-title-container-60">
          <Typography.Text className="header-title-black-35">{t("revenues")}</Typography.Text>
        </Row>
        <Row className="payment-information" align="middle">
          <Row className="payment-information-first-container">
            <Row className="next-payment-container">
              <Row>
                <Typography.Text className="font-32-bold text-white-color mr-10">
                  {revenue &&
                    revenue.nextInstallment?.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }) + " €"}
                </Typography.Text>
                {!isPendingPaymentOpen ? (
                  <img
                    src={ArrowRightGreenIcon}
                    width={34}
                    alt="open"
                    onClick={() => {
                      setIsPendingPaymentOpen(true);
                    }}
                  />
                ) : (
                  <img
                    src={ArrowBottomGreenIcon}
                    width={34}
                    alt="open"
                    onClick={() => {
                      setIsPendingPaymentOpen(false);
                    }}
                  />
                )}
              </Row>
              <Row>
                <Typography.Text>
                  <span className="text-description-first">{t("validated-for-the-next-payment-1")}</span>{" "}
                  <span className="text-description-second">{t("validated-for-the-next-payment-2")}</span>
                </Typography.Text>
                <img
                  src={InfoBlackGreyIcon}
                  width={12}
                  alt="info"
                  onClick={() => {
                    setIsInfoModal(true);
                    setInfoContent(1);
                  }}
                />
              </Row>
            </Row>
            <div className="currency-img">
              <img src={EurFlagImg} width={45} alt="EUR" />
              <Typography.Text className="eur-text">EUR</Typography.Text>
            </div>
          </Row>
          {isPendingPaymentOpen && (
            <Row className="payment-information-seconde-container">
              <Row className="pending-payment-container">
                <Row>
                  <Typography.Text className="font-24-bold text-white-color mr-10">
                    {revenue &&
                      revenue.waitingForValidation?.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }) + " €"}
                  </Typography.Text>
                </Row>
                <Row>
                  <Typography.Text>
                    <span className="text-description-first">{t("waiting-for-validation-1")}</span>{" "}
                    <span className="text-description-second">{t("waiting-for-validation-2")}</span>
                  </Typography.Text>
                  <img
                    src={InfoBlackGreyIcon}
                    width={12}
                    alt="info"
                    onClick={() => {
                      setIsInfoModal(true);
                      setInfoContent(2);
                    }}
                  />
                </Row>
              </Row>
            </Row>
          )}
        </Row>
        <Row className="revenue-radio-container">
          <Radio.Group
            defaultValue="today"
            onChange={(e) => {
              setGraphData(e.target.value);
            }}
            buttonStyle="solid"
          >
            <Space className="gap-0">
              <Radio.Button className="revenuePeriodRadio" value="today">
                {t("today")}
              </Radio.Button>
              <Radio.Button className="revenuePeriodRadio" value="week">
                {t("this-week")}
              </Radio.Button>
              <Radio.Button className="revenuePeriodRadio" value="month">
                {t("this-month")}
              </Radio.Button>
              <Radio.Button className="revenuePeriodRadio" value="total">
                {t("all")}
              </Radio.Button>
            </Space>
          </Radio.Group>
        </Row>
        <Row className="chart-container">
          <Row className="chart-data-row">
            <div>
              <Space className="gap-0" direction="vertical">
                <Typography.Text className="chart-price-text">
                  {(() => {
                    switch (graphData) {
                      case "today":
                        return (
                          revenue &&
                          revenue?.todayEarnings?.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }) + " €"
                        );
                      case "month":
                        return (
                          revenue &&
                          revenue?.oneMonthEarning?.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }) + " €"
                        );
                      case "week":
                        return (
                          revenue &&
                          revenue?.weeklyTotalEarnings?.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }) + " €"
                        );
                      case "total":
                        return (
                          revenue &&
                          revenue?.totalEarnings?.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }) + " €"
                        );
                      default:
                        return (
                          revenue &&
                          revenue?.todayEarnings?.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }) + " €"
                        );
                    }
                  })()}
                </Typography.Text>
                <Typography.Text className="chart-description-text">
                  {(() => {
                    switch (graphData) {
                      case "today":
                        return t("daily-total");
                      case "week":
                        return t("weekly-total");
                      case "month":
                        return t("monthly-total");
                      case "total":
                        return t("all-total");
                      default:
                        return t("daily-total");
                    }
                  })()}
                </Typography.Text>
              </Space>
            </div>
            <div>
              <Space className="gap-0" direction="vertical">
                <Typography.Text className="chart-price-text">
                  {revenue &&
                    revenue?.totalNetPrice?.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }) + " €"}
                </Typography.Text>
                <Typography.Text className="chart-description-text">{t("receipt-in-your-bank-account")}</Typography.Text>
              </Space>
            </div>
          </Row>
          <Row className="w-full">
            <CreatorRevenueChartComponent statsData={revenue?.statsData} graphData={graphData} />
          </Row>
        </Row>

        <Row className="last-report-container">
          <Row className="last-report-header">
            <Typography.Text className="font-18-bold text-white-color">{t("last-report")}</Typography.Text>
            <Dropdown menu={{ items: menuItems, onClick: handleMenuClick }} trigger={["click"]} className="revenue-dropdown">
              <div className="information-management-button">
                <Typography.Text className="information-management-button-text">
                  {activeTab === "daily" && "Journalier"}
                  {activeTab === "monthly" && "Mensuel"}
                  {activeTab === "detailed" && "Détaillé"}
                </Typography.Text>
                <img src={GreenBottomChevronIcon} width={12} alt="change" />
              </div>
            </Dropdown>
          </Row>
          <Row className="last-report-content">
            {activeTab === "daily" && <CreatorRevenueTabComponent dailyPreviousReport={revenue?.dailyPreviousReport} />}

            {activeTab === "monthly" && <CreatorRevenueTabComponent monthlyPreviousReport={revenue?.monthlyPreviousReport} />}

            {activeTab === "detailed" && (
              <div className="detailed-report">
                <CreatorRevenueTabComponent detailedPreviousReport={revenue?.detailPaymentReport} />
                {(loading || hasMore) && <SpinnerComponent refProp={sentryRef} />}
              </div>
            )}
          </Row>
        </Row>
      </Layout.Content>
      <FooterComponent page={FOOTER_IDS.REVENUES} type="creator" />
      <InfoModal
        isOpen={isInfoModal}
        onClose={() => {
          setIsInfoModal(false);
          setInfoContent(null);
        }}
        description={infoContent === 1 ? t("earnings-confirm-for-payemnt") : infoContent === 2 ? t("revenues-earning-validation-info") : ""}
      />
    </Layout>
  );
};

export default CreatorRevenuePage;
