import React, { useState } from "react";
import { Space, Avatar, Typography, Button } from "antd";
import VerifyIcon from "../../assets/icons/verify.svg";
import "./index.less";

type props = {
  className?: string;
  size: number;
  image: string | undefined;
  name?: string;
  username?: string;
  firstName?: string;
  lastName?: string;
  fontSize?: number;
  fontWeight?: boolean;
  lineHeight?: number;
  isShowVeritify?: boolean;
  fromSuggestions?: boolean;
  isLive?: boolean;
  isLargeView?: boolean;
  onClick?: () => void;
};

const AvatarComponent: React.FC<props> = ({
  className,
  size,
  image,
  name,
  username,
  fontSize,
  fontWeight,
  lineHeight,
  isShowVeritify,
  onClick,
  firstName,
  lastName,
  fromSuggestions,
  isLive = false,
  isLargeView = false,
}) => {
  const [isImageLarge, setIsImageLarge] = useState(false);

  const handleAvatarClick = () => {
    if (isLargeView) {
      setIsImageLarge(!isImageLarge);
    }
    if (onClick) onClick();
  };

  return (
    <>
      <Space className={`${className} gap-10`} direction="vertical" align="center">
        <Space className={`avatarWrap ${isLive ? "live-border" : ""}`}>
          <div className={`${isLive ? "avatarBorder" : ""}`}>
            <Avatar
              className="avatar"
              size={size}
              src={image !== undefined ? image : ""}
              alt={"avatar"}
              onClick={handleAvatarClick} // Utiliser handleAvatarClick pour gérer le clic
            />
            {isLive && <div className="live-badge">LIVE</div>}
          </div>
        </Space>
        {username && (
          <Space align="center">
            <Typography.Text
              className={`responsive-name text-white-color ${fontWeight ? "primary-font-bold" : "primary-font-regular"}`}
              style={{
                fontSize: fontSize,
                lineHeight: `${lineHeight}px`,
              }}
            >
              {name ? name : username}
            </Typography.Text>
            {isShowVeritify && <img src={VerifyIcon} alt="verify" />}
          </Space>
        )}
      </Space>

      {isImageLarge && (
        <div className="avatar-image-overlay" onClick={() => setIsImageLarge(false)}>
          <img className="avatar-large-image" src={image} alt="Avatar en grand" />
        </div>
      )}
    </>
  );
};

export default AvatarComponent;
