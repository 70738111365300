import { Row, Typography } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import "./index.less";

type props = {
  isOpen: boolean;
  onClose: () => void;
  onMyDevice: () => void;
  onMyAlbums: () => void;
};

const MailboxMediaModal: React.FC<props> = ({ isOpen, onClose, onMyDevice, onMyAlbums }) => {
  const { t } = useTranslation();

  if (!isOpen) return null;

  const handleOverlayClick = () => {
    if (onClose) {
      onClose();
    }
  };

  const handleModalClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
  };

  return (
    <div className="bottom-modal-overlay" onClick={handleOverlayClick}>
      <div className="bottom-modal-content" onClick={handleModalClick}>
        <Row className="w-full justify-content-center mt-10">
          <div className="header-icon-grey" onClick={handleOverlayClick}></div>
        </Row>
        <div className="bottom-modal-header">
          <Typography.Text className="bottom-modal-title f-30">{t("importation")}</Typography.Text>
        </div>
        <div className="bottom-modal-content mt-10">
          <button className="bottom-modal-button mt-14" onClick={onMyAlbums}>
            <Typography.Text className="bottom-modal-button-title">{t("Quick-Files")}</Typography.Text>
          </button>

          <button className="bottom-modal-button mt-14" onClick={onMyDevice}>
            <Typography.Text className="bottom-modal-button-title">{t("my-device")}</Typography.Text>
          </button>

          <button className="bottom-modal-cancel-button" onClick={onClose}>
            <Typography.Text className="bottom-modal-cancel-button-title">{t("cancel")}</Typography.Text>
          </button>
        </div>
      </div>
    </div>
  );
};

export default MailboxMediaModal;
