import { Checkbox, Input, Layout, Row, Typography, message } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import LolyLogoWhite from "../../assets/icons/loly-logo-white.svg";
import { authService } from "../../services";
import ArrowLeftIconComponent from "../ArrowLeftIconComponent";
import AuthButtonComponent from "../AuthButtonCompnent";
import AuthGoogleButtonComponent from "../AuthGoogleButtonComponent";
import AuthOrDividerComponent from "../AuthOrDividerComponent";
import "./index.less";

type AuthSignInTabProps = {
  googleUnavailable: () => void;
};

const AuthSignInTabComponent: React.FC<AuthSignInTabProps> = ({ googleUnavailable }) => {
  const { t } = useTranslation();
  const [usernameOrEmail, setUsernameOrEmail] = useState<string | null>(null);
  const [password, setPassword] = useState<string | null>(null);
  const [remember, setRemember] = useState<boolean>(true);
  const navigate = useNavigate();

  const login = () => {
    if (!usernameOrEmail || usernameOrEmail?.length < 3) return message.warning(t("username-valid"));
    if (!password || password?.length < 6) return message.warning(t("password-valid"));

    authService
      .login(usernameOrEmail, password, remember, navigate)
      .then(() => {
        window.location.reload();
      })
      .catch((error) => message.error(t(error)));
  };

  const googleLogin = (accessToken: string) => {
    authService
      .googleLogin(accessToken, navigate)
      .then((data: string) => {
        window.location.reload();
      })
      .catch(async (error: string) => {
        await message.error(t(error));
      });
  };

  return (
    <Layout className="intro-layout gradient-background">
      <div style={{ position: "absolute", top: "30px", left: "30px" }}>
        <Row className="relative justify-content-center align-items-center">
          <ArrowLeftIconComponent left={-7} top={1} color="white" navigateTo="auth/intro" />
        </Row>
      </div>
      <Layout.Content className="authContentWrapper">
        <div className="intro-container">
          <Row className="content-wrapper justify-content-center">
            <img src={LolyLogoWhite} width={50} alt="Loly" />
          </Row>
          <Typography.Text className="auth-title">{t("auth-signIn-title")}</Typography.Text>
          <Row className="w-full mt-20">
            <Input
              className="customInput"
              placeholder={t("email-and-username") ?? ""}
              type="email"
              value={usernameOrEmail || ""}
              onChange={(e) => {
                const newValue = e?.target?.value?.replace(/\s+/g, "")?.toLowerCase();
                setUsernameOrEmail(newValue);
              }}
            />
          </Row>
          <Row className="w-full mt-20">
            <Input.Password
              className="customInput"
              placeholder={t("password-label") ?? ""}
              type="password"
              value={password || ""}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Row>
          <Row className="w-full mt-10">
            <AuthOrDividerComponent text={t("auth-signUp-divider")} />
          </Row>
          <Row className="row mt-10">
            <AuthGoogleButtonComponent
              label={t("auth-social-google-connection")!}
              onLoginSuccess={googleLogin}
              googleUnavailable={googleUnavailable}
            />
          </Row>
          <Row className="w-full mt-20 justify-content-between">
            <Typography.Text className="authForgotPasswordLabel cursor-pointer" onClick={() => navigate("/auth/forgot")}>
              {t("auth-forgot-password-question-text")}
            </Typography.Text>
            <Checkbox className="authRememberMeCheckbox" checked={remember} onChange={(e) => setRemember(e.target.checked)}>
              {t("auth-remember-me")}
            </Checkbox>
          </Row>
          <Row className="row mt-20">
            <AuthButtonComponent label={t("auth-signIn-button")} height="60px" onClick={login} />
          </Row>
          <Row className="w-full justify-content-center mt-70 mb-30">
            <Typography.Text className="bottom-text-label">{t("not-account-yet")}</Typography.Text>
            <Link to="/auth/signUp">
              <Typography.Text className="bottom-text-register">{t("sign-up")}</Typography.Text>
            </Link>
          </Row>
        </div>
      </Layout.Content>
    </Layout>
  );
};

export default AuthSignInTabComponent;
