import { Row, Typography } from "antd";
import { useTranslation } from "react-i18next";

type ExpireUnlockLinkModalProps = {
  isOpen: boolean;
  onClose?: () => void;
  expireLink?: () => void;
};

const ExpireUnlockLinkModalComponent: React.FC<ExpireUnlockLinkModalProps> = ({ isOpen, onClose, expireLink }) => {
  const { t } = useTranslation();

  if (!isOpen) return null;

  const handleOverlayClick = () => {
    if (onClose) {
      onClose();
    }
  };

  const handleModalClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
  };

  return (
    <div className="bottom-modal-overlay" onClick={handleOverlayClick}>
      <div className="bottom-modal-content" onClick={handleModalClick}>
        <Row className="w-full justify-content-center mt-10">
          <div className="header-icon-grey" onClick={handleOverlayClick}></div>
        </Row>
        <div className="bottom-modal-header px-20">
          <Typography.Text className="bottom-modal-title f-24">{t("expire-link-modal-description")}</Typography.Text>
        </div>
        <div className="bottom-modal-content">
          <button className="bottom-modal-button" onClick={expireLink}>
            <Typography.Text className="bottom-modal-button-title">{t("expire-link")}</Typography.Text>
          </button>

          <button className="bottom-modal-cancel-button" onClick={onClose}>
            <Typography.Text className="bottom-modal-cancel-button-title">{t("cancel")}</Typography.Text>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ExpireUnlockLinkModalComponent;
