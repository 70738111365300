import React, { useState, useEffect } from "react";
import { Typography, Row } from "antd";
import { useTranslation } from "react-i18next";
import PublicAuthViewComponent from "./ViewsComponents/PublicAuthViewComponent";
import PublicRegisterViewComponent from "./ViewsComponents/PublicRegisterViewComponent";
import NewArrowLeftIcon from "../../assets/icons/new-arrow-left.svg";
import ShowIcon from "../../assets/icons/show-insta.svg";
import PublicLoginViewComponent from "./ViewsComponents/PublicLoginViewComponent";
import SpinnerComponent from "../SpinnerComponent/SpinnerComponent";
import ExplanationComponent from "../AuthGoogleUnavailableComponent/ExplanationComponent";
import "./index.less";

interface PublicAuthentificationModalComponentProps {
  avatar?: string;
  name?: string;
  lastName?: string;
  visible: boolean;
  title?: string;
  onClose: () => void;
  isFightEvent?: boolean;
}

const PublicAuthentificationModalComponent: React.FC<PublicAuthentificationModalComponentProps> = ({
  avatar,
  name,
  visible,
  title,
  onClose,
  isFightEvent,
}) => {
  const { t } = useTranslation();
  const [currentView, setCurrentView] = useState<"auth-view" | "register-view" | "login-view">("auth-view");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [authGoogleUnavailable, setAuthGoogleUnavailable] = useState<boolean>(false);

  useEffect(() => {
    if (visible) {
      setCurrentView("auth-view");
    }
  }, [visible]);

  if (!visible) return null;

  const googleUnavailable = () => {
    setAuthGoogleUnavailable(true);
  };

  const googleUnavailableCancel = () => {
    setAuthGoogleUnavailable(false);
  };

  const handleOverlayClick = () => {
    onClose();
  };

  const handleModalClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
  };

  const handleBackClick = () => {
    setCurrentView("auth-view");
  };

  const handleRegisterClick = () => setCurrentView("register-view");

  const handleLoginClick = () => setCurrentView("login-view");

  const renderView = () => {
    switch (currentView) {
      case "auth-view":
        return (
          <PublicAuthViewComponent onRegisterClick={handleRegisterClick} onLoginClick={handleLoginClick} googleUnavailable={googleUnavailable} />
        );
      case "login-view":
        return <PublicLoginViewComponent googleUnavailable={googleUnavailable} />;
      case "register-view":
        return <PublicRegisterViewComponent onClose={onClose} />;
      default:
        return null;
    }
  };

  return (
    <div className="public-auth-modal-overlay" onClick={handleOverlayClick}>
      {authGoogleUnavailable && (
        <div className="profile-public-show-icon-container">
          <img src={ShowIcon} className="show-icon" alt="login" />
        </div>
      )}
      <div
        className={`public-auth-modal-content ${currentView === "login-view" ? "gradient-background" : ""}  ${isLoading ? "center-loader" : ""}`}
        onClick={handleModalClick}
      >
        {isLoading ? (
          <SpinnerComponent />
        ) : (
          <div>
            {currentView !== "login-view" || authGoogleUnavailable ? (
              <div
                className="modal-img-header"
                style={{
                  backgroundImage: `url(${avatar})`,
                }}
              >
                <Row className="w-full justify-content-center">
                  <div className="header-icon-grey mt-5" onClick={handleOverlayClick}></div>
                </Row>

                {currentView !== "auth-view" && (
                  <img className="header-arrow-back" src={NewArrowLeftIcon} width={34} onClick={handleBackClick} alt="Back" />
                )}

                <div className="w-full modal-img-header-info">
                  <Typography.Text className="modal-img-header-info-text">
                    {authGoogleUnavailable
                      ? t("public-profile-modal-title-auth-google-insta")
                      : title
                        ? t(title)
                        : `${t("public-profile-modal-title-1-1")} ${t("public-profile-modal-title-2", { name: `${name}` })}`}
                  </Typography.Text>
                </div>
              </div>
            ) : (
              <div className="header-public-login">
                <Row className="w-full justify-content-center mt-10">
                  <div className="header-icon-grey" onClick={handleOverlayClick}></div>
                </Row>
                <img className="header-arrow-back" src={NewArrowLeftIcon} width={34} onClick={handleBackClick} alt="Back" />
              </div>
            )}
            {authGoogleUnavailable ? (
              <ExplanationComponent cancel={googleUnavailableCancel} fromPublic={true} />
            ) : (
              <div className="modal-content">{renderView()}</div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default PublicAuthentificationModalComponent;
