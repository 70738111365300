import { Row, Space, Tag, Typography } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import GrowthIcon from "../../assets/icons/growth.svg";
import DecreasingIcon from "../../assets/icons/decreasing.svg";
import CreatorRevenueDetailedComponent from "../CreatorRevenueDetailedComponent";
import "./index.less";

interface props {
  dailyPreviousReport?: any;
  monthlyPreviousReport?: any;
  detailedPreviousReport?: any;
}
const CreatorRevenueTabComponent: React.FC<props> = ({ dailyPreviousReport, monthlyPreviousReport, detailedPreviousReport }) => {
  const { t } = useTranslation();
  const [openDetailedModal, setOpenDetailedModal] = useState<boolean>(false);
  const [fromDate, setFromDate] = useState<string>("");
  const [toDate, setToDate] = useState<string>("");
  const [monthLabel, setMonthLabel] = useState<string | null>(null);
  const [year, setYear] = useState<number | null>(null);

  const convertDate = (date: string) => {
    const createdAt = new Date(date);
    const formattedDate = createdAt
      .toLocaleDateString(`${t("current-language")}`, {
        day: "2-digit",
        month: "short",
        year: "numeric",
      })
      .replace(".", "");
    return formattedDate;
  };

  const getMonthLabel = (monthShort: string): string => {
    let monthLabel = "";
    switch (monthShort) {
      case "Jan":
        monthLabel = t("january-label");
        break;
      case "Feb":
        monthLabel = t("february-label");
        break;
      case "Mar":
        monthLabel = t("march-label");
        break;
      case "Apr":
        monthLabel = t("april-label");
        break;
      case "May":
        monthLabel = t("may-label");
        break;
      case "Jun":
        monthLabel = t("june-label");
        break;
      case "Jul":
        monthLabel = t("july-label");
        break;
      case "Aug":
        monthLabel = t("august-label");
        break;
      case "Sep":
        monthLabel = t("september-label");
        break;
      case "Oct":
        monthLabel = t("october-label");
        break;
      case "Nov":
        monthLabel = t("november-label");
        break;
      case "Dec":
        monthLabel = t("december-label");
        break;
      default:
        break;
    }
    return monthLabel;
  };

  const closeDetailedModal = () => {
    setOpenDetailedModal(false);
  };

  const setFromDateAndToDate = (month: string, year: number) => {
    const monthMap: Record<string, number> = {
      Jan: 0,
      Feb: 1,
      Mar: 2,
      Apr: 3,
      May: 4,
      Jun: 5,
      Jul: 6,
      Aug: 7,
      Sep: 8,
      Oct: 9,
      Nov: 10,
      Dec: 11,
    };

    const startDate = new Date(year, monthMap[month], 1);
    const endDate = new Date(year, monthMap[month] === new Date().getMonth() ? monthMap[month] : monthMap[month] + 1, 0);

    const today = new Date();
    const isCurrentMonth = startDate.getMonth() === today.getMonth() && startDate.getFullYear() === today.getFullYear();

    const formatDate = (date: Date) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    };

    const formattedFromDate = formatDate(startDate);
    const formattedToDate = isCurrentMonth ? formatDate(today) : formatDate(endDate);

    return { formattedFromDate, formattedToDate };
  };

  return (
    <>
      <Space className="w-full gap-10" direction="vertical">
        {dailyPreviousReport &&
          dailyPreviousReport.map(
            (
              revenue: {
                total_cost_month: number;
                stats: string;
                day: number;
                month: string;
              },
              index: number
            ) => {
              return (
                <Row className="revenue-row" key={index}>
                  <div className="icon-date-increase-container">
                    <img src={revenue.stats.includes("+") ? GrowthIcon : DecreasingIcon} className="increase-icon" alt="increased" />
                    <div className="date-increase-container">
                      <Typography.Text className="font-16-medium text-white-color">
                        {revenue.day + " "}
                        {getMonthLabel(revenue.month)}
                      </Typography.Text>
                      <Typography.Text className="daily-monthly-revenue-text">
                        <span className={revenue.stats.includes("+") ? "increased" : "decreased"}>{revenue.stats}</span> de croissance
                      </Typography.Text>
                    </div>
                  </div>

                  <Typography.Text className="font-18-bold text-white-color">
                    {revenue.total_cost_month.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                    €
                  </Typography.Text>
                </Row>
              );
            }
          )}
        {monthlyPreviousReport &&
          monthlyPreviousReport.map(
            (
              revenue: {
                year: number;
                month: string;
                total_cost_month: number;
                stats: string;
                isRefund: boolean;
              },
              index: number
            ) => {
              return (
                <Row
                  className="revenue-row"
                  key={index}
                  onClick={() => {
                    const { formattedFromDate, formattedToDate } = setFromDateAndToDate(revenue.month, revenue.year);
                    setFromDate(formattedFromDate);
                    setToDate(formattedToDate);
                    setMonthLabel(getMonthLabel(revenue.month));
                    setYear(revenue.year);
                    setOpenDetailedModal(true);
                  }}
                >
                  <div className="icon-date-increase-container">
                    <img src={revenue.stats.includes("+") ? GrowthIcon : DecreasingIcon} width={45} alt="increased" />
                    <div className="date-increase-container">
                      <Typography.Text className="font-16-medium text-white-color">
                        {getMonthLabel(revenue.month)}
                        {" " + revenue.year}
                      </Typography.Text>
                      <Typography.Text className="daily-monthly-revenue-text">
                        <span className={revenue.stats.includes("+") ? "increased" : "decreased"}>{revenue.stats}</span> de croissance
                      </Typography.Text>
                    </div>
                  </div>

                  <Typography.Text className="font-18-bold text-white-color">
                    {revenue.total_cost_month.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                    €
                  </Typography.Text>
                </Row>
              );
            }
          )}
        {detailedPreviousReport &&
          detailedPreviousReport.map(
            (
              revenue: {
                isRefund: boolean;
                netPrice: number;
                isValidate: boolean;
                createdAt: string;
                transactionId: string;
                type: string;
              },
              index: number
            ) => {
              return (
                <Row className="justify-content-between" key={index}>
                  <Space direction="vertical" className="gap-2">
                    <Typography.Text className="font-14-bold text-white-color">
                      {revenue.type === "monthly_subscription"
                        ? t("monthly-subscription")
                        : revenue.type === "trimestrial_subscription"
                          ? t("three-months-subscription")
                          : // : revenue.type === "half_yearly_subscription"
                            //   ? t("six-months-label") + " " + t("subscription")
                            //   : revenue.type === "yearly_subscription"
                            //     ? t("yearly-label") + " " + t("subscription")
                            t(revenue.type)}
                    </Typography.Text>
                    <Typography.Text className="font-14-bold text-white-color">{convertDate(revenue?.createdAt)}</Typography.Text>
                  </Space>
                  <Space className="gap-2" direction="vertical" align="end">
                    <Typography.Text className="font-14-bold text-white-color ms-auto">{revenue?.netPrice?.toFixed(2)}€</Typography.Text>
                    <Typography.Text className="font-14-bold text-white-color">
                      {revenue?.isRefund ? "🔴 " + t("refund") : revenue?.isValidate ? "🟢 " + t("validated") : "🟡 " + t("pending")}
                    </Typography.Text>
                  </Space>
                </Row>
              );
            }
          )}
      </Space>
      <CreatorRevenueDetailedComponent
        toDate={toDate}
        fromDate={fromDate}
        visible={openDetailedModal}
        onClose={closeDetailedModal}
        monthLabel={monthLabel}
        year={year}
      />
    </>
  );
};

export default CreatorRevenueTabComponent;
