import { Col, Layout, Row, Typography } from "antd";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import VideoIcon from "../../assets/icons/jouer.svg";
import { THUMBNAIL_TYPES } from "../../helpers/constant";
import { getBookmarkFeeds } from "../../redux/feeds/feedsSlice";
import { AppDispatch, StoreState } from "../../redux/store";
import ArrowLeftIconComponent from "../ArrowLeftIconComponent";
import AvatarComponent from "../AvatarComponent";
import WatermarkedImageComponent from "../MediaComponent/WatermarkedImageComponent";
import SpinnerComponent from "../SpinnerComponent/SpinnerComponent";
import "./index.less";

const FanBookmarksComponent = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const shouldFetch = useRef(true);
  const { bookmarkFeeds: bookmarks, loadingBookmarkFeeds: isLoader } = useSelector((state: StoreState) => state.feeds);

  useEffect(() => {
    if (shouldFetch.current) {
      shouldFetch.current = false;
      if (bookmarks.length === 0) {
        dispatch(getBookmarkFeeds());
      }
    }
  }, []);

  return (
    <Layout className="gradient-background-black">
      <Layout.Content className="content fanBookmarksContentWrapper background-transaprent">
        <Row className="relative justify-content-center header-title-container-60">
          <ArrowLeftIconComponent top={7} left={16} />
          <Typography.Text className="header-title-black-30">{t("bookmarks")}</Typography.Text>
        </Row>
        <Row className="fanBookmarksContent mt-10">
          <Row className="mt-50 mx-auto">
            {isLoader === "pending" && <SpinnerComponent />}
            {!(isLoader === "pending") && bookmarks.length === 0 && (
              <Typography.Text className="text-white font-16-medium">{t("no-bookmarks-found")}</Typography.Text>
            )}
          </Row>
          {!(isLoader === "pending") &&
            bookmarks &&
            bookmarks?.map((feed) => {
              const isVideo = feed.type === "video";
              let feedThumbnail: string | undefined = "";
              if (feed.files !== undefined) {
                feedThumbnail = isVideo
                  ? feed.isPrivateForCurrentUser
                    ? feed.files[0]?.thumbnails?.find((thumb) => thumb.type === THUMBNAIL_TYPES.BLURRED)?.absolutePath
                    : feed.files[0]?.thumbnails?.find((thumb) => thumb.type === THUMBNAIL_TYPES.CLEAR)?.absolutePath
                  : feed.isPrivateForCurrentUser
                    ? feed.files[0].thumbnails?.find((thumb) => thumb.type === THUMBNAIL_TYPES.BLURRED)?.absolutePath
                    : feed.files[0].url;
              }
              return (
                <Col key={feed._id} span={12} className="container-bookmarks" onClick={() => navigate(`/fan/bookmarkFeed/${feed._id}`)}>
                  <div style={{ position: "relative" }}>
                    <WatermarkedImageComponent classProps="w-full h-full img-bookmarks" image={feedThumbnail!} shouldAddWatermark={true} />
                    <div className="text-bookmarks">
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <AvatarComponent image={feed.creatorInfo?.avatar} size={30} />
                        <Typography.Text style={{ marginLeft: "10px" }} className="font-14-bold text-white-color">
                          {feed.creatorInfo?.username}
                        </Typography.Text>
                      </div>
                    </div>
                  </div>
                  {isVideo && (
                    <img src={VideoIcon} style={{ position: "absolute", right: "11px", bottom: "11px" }} alt="video icon" className="feedVideoIcon" />
                  )}
                </Col>
              );
            })}
        </Row>
      </Layout.Content>
      {/* <FooterComponent type="creator" page={FOOTER_IDS.PROFILE} /> */}
    </Layout>
  );
};

export default FanBookmarksComponent;
