import { Col, Layout, message, Row, Space, Switch, Typography } from "antd";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ArrowLeftIconComponent from "../../../components/ArrowLeftIconComponent";
import { getEmailNotifications, MAX_PRICE } from "../../../helpers/constant";
import { AppDispatch, StoreState } from "../../../redux/store";
import { userService } from "../../../services";
import "./index.less";
import MailIcon from "../../../assets/icons/mail.svg";
import { getMyProfile } from "../../../redux/users/usersSlice";

const FanEmailNotificationPage = () => {
  const { t } = useTranslation();
  const shouldFetch = useRef(true);
  const dispatch = useDispatch<AppDispatch>();
  const notificationsInformations = getEmailNotifications(t);
  const user = useSelector((state: StoreState) => state.users.currentUserProfile);
  const [notifications , setNotifications] = useState({
    mediaPush : true,
    stream : true,
    scheduleStream : true,
    reminderStream : true
  })

  useEffect(() => {
    if(shouldFetch.current){
        shouldFetch.current = false;
        getProfile()
    }
  }, []);

  const getProfile = () => {
    dispatch(getMyProfile());
  };

  const handleSwitchChange = async (e: any, frequency: string) => {
    const data = {...notifications , [frequency] : e}
   await userService.updateNotificationSetting(data).then((res : any) => {
    if(res.status === 0){
        setNotifications(data)
    }else{
        message.error(t("something-want-wrong"))
    }
   }).catch(err => {
    console.log(err?.message)
    message.error(t("something-want-wrong"))
   })
  };

  useEffect(()=>{
   if(user){
    setNotifications(user.notification)
   }
  },[user])


  return (
    <Layout>
      <Layout.Content className="content fanEmailNotificationWrapper gradient-background">
        <Row className="relative justify-content-center header-title-container-70">
          <ArrowLeftIconComponent top={1} />
          <Typography.Text className="header-title-black-24">{t("email-Notification-title")}</Typography.Text>
        </Row>
        <Row className="pl-20 pr-13 mt-50">
          <Space className="w-full gap-20" direction="vertical">
            {notificationsInformations.map((monetization, index) => {
              return (
                <Col key={index} className="FanNotificationBox">
                  <Row className="justify-content-between">
                    <Space direction="horizontal" className="items-start fanNotificationtitleandSubtile">
                   <img src={MailIcon} width="14px" height="14px" className="mt-7"/> 
                   <Col className="d-flex flex-column"> <Typography.Text className="FanNotificationItemTitle m-0">{monetization.title}</Typography.Text>
                   <Typography.Text className="FanNotificationItemSubTitle m-0">{monetization.subTitle}</Typography.Text>
                   </Col>
                    </Space>
                    <Switch
                      className="switch revenueSwitch"
                      checked={
                        monetization.frequency === "stream"
                          ? notifications.stream
                          : monetization.frequency === "mediaPush"
                            ? notifications.mediaPush : 
                            monetization.frequency === "reminderStream"
                            ? notifications.reminderStream
                            : monetization.frequency === "scheduleStream"
                              ? notifications.scheduleStream : false
                              
                      }
                      onChange={(e) => {
                        handleSwitchChange(e, monetization.frequency);
                      }}
                    />
                  </Row>
                </Col>
              );
            })}
          </Space>
        </Row>
      </Layout.Content>
    </Layout>
  );
};

export default FanEmailNotificationPage;
