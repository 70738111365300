import React, { useEffect } from 'react'
import { socket } from '../../socket';
import { clearAllConversations, getConversationById, getConversationsAfterMediaPush, handleConversationTypeChange, handleEditMessage, handleNewMessageData, handleNextUndeliveredMessageChange, updateConversationHasNewMessages, updateConversations } from '../../redux/messages/messagesSlice';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../redux/store';
import { IConversationDTO, IMessage, ISwitchConversationType, ISwitchUndeliveredMessage } from '../types';
import { newLivePublished, updateEndLive } from '../../redux/lives/livesSlice';
import { C_CURRENT_TAB, CONVERSATION_TYPES } from '../constant';

export const MessagesInterceptor = () => {
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    socket.on("message", handleMessage);
    socket.on("conversation-created", handleConversationCreated);
    socket.on("update-message", handleUpdateMessage);
    socket.on("switch-conversation-type", handleSwitchConversationType);
    socket.on("switch-next-message-to-deliver", handleSwitchToNextUndeliveredMessage);
    socket.on("conversation-updated", handleConversationUpdated);
    socket.on("streams_updated", streamLiveUpdated);
    socket.on("mediaPush-sent", handleConversationsAfterMediaPush);

    return () => {
      socket.off("message", handleMessage);
      socket.off("conversation-created", handleConversationCreated);
      socket.off("update-message", handleUpdateMessage);
      socket.off("switch-conversation-type", handleSwitchConversationType);
      socket.off("switch-next-message-to-deliver", handleSwitchToNextUndeliveredMessage);
      socket.off("conversation-updated", handleConversationUpdated);
      socket.off("streams_updated", streamLiveUpdated);
      socket.off("mediaPush-sent", handleConversationsAfterMediaPush);

    };
  }, []);

  function handleMessage(data: any) {
    dispatch(
      updateConversationHasNewMessages({
        conversationId: data.conversationId,
        createdAt: data.createdAt,
        conversationType: data.conversationType,
        isReceiver: data.isReceiver,
        lastMessage: data.text,
      })
    );
    dispatch(handleNewMessageData(data));
  }

  function handleConversationCreated(data: IConversationDTO) {
    dispatch(updateConversations(data));
  }

  function handleUpdateMessage(data: IMessage) {
    dispatch(handleEditMessage(data));
  }

  function handleSwitchConversationType(data: ISwitchConversationType) {
    dispatch(handleConversationTypeChange(data));
  }

  function handleSwitchToNextUndeliveredMessage(data: ISwitchUndeliveredMessage) {
    dispatch(handleNextUndeliveredMessageChange(data));
  }

  function handleConversationUpdated(data: IConversationDTO) {
    dispatch({
      type: getConversationById.fulfilled.type,
      payload: data,
    });
  }

  const streamLiveUpdated = (data: any) => {
    if(data?.isNewStream && data?.stream) {
      dispatch(newLivePublished(true));
    }
    if(data?.isStreamingEnds && data?.streamId) {
      dispatch(updateEndLive({streamId: data?.streamId, isStreamingEnds: data?.isStreamingEnds}));
    }
  };

  const handleConversationsAfterMediaPush = (data: any) => {
    //dispatch(clearAllConversations());
    dispatch(getConversationsAfterMediaPush({ page: 1, type: window.localStorage.getItem(C_CURRENT_TAB) || CONVERSATION_TYPES.SUBSCRIPTION }));
  };

  return <></>;
};
