import { Row, Space, Spin, Typography, message } from "antd";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { useDispatch, useSelector } from "react-redux";
import PullToRefresh from "react-simple-pull-to-refresh";
import { DEFAULT_HOME_PAGE_FEEDS, FILE_STATUS, THUMBNAIL_TYPES } from "../../helpers/constant";
import { IFeed } from "../../helpers/types";
import { getHomePageFeeds, loadMorePageFeeds } from "../../redux/feeds/feedsSlice";
import { AppDispatch, StoreState } from "../../redux/store";
import { authService, reportService, userService } from "../../services";
import MainSwiperSlideItemV2 from "../MainSwiperSlideItemV2/MainSwiperSlideItemV2";
import { FanHomePageSkelton } from "../SkeltonsComponents/FanHomePageSkelton";
import SpinnerComponent from "../SpinnerComponent/SpinnerComponent";
import { getSuggestionByType } from "../../redux/CreatorSuggestion/CreatorSuggestion";
import SuggestionSectionFour from "../FanHomeSuggestionComponent/FanHomeSuggestionSectionsComponent/SuggestionSectionFour";
import "./index.less";

interface Props {
  onRefresh?: () => void;
}

const MainSwiper = (props: Props) => {
  const { t } = useTranslation();
  const shouldFetch = useRef(true);
  const dispatch = useDispatch<AppDispatch>();
  const { homeFeeds, loadingHome: loading, error, totalPages } = useSelector((state: StoreState) => state.feeds);
  //const [page, setPage] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(false);
  const selectionWeek = useSelector((state: StoreState) => state.suggestion.suggestionByTypeList.get(4) || []);
  const [creatorSubscriptions, setCreatorSubscriptions] = useState(false);
  const [followers, setFollowers] = useState(-1);
  const [showSelectionOfWeek, setShowSelectionOfWeek] = useState(true);

  useEffect(() => {
    if (shouldFetch.current) {
      shouldFetch.current = false;
      if (homeFeeds.length === 0) {
        dispatch(getHomePageFeeds(1));

        if (!selectionWeek.length) {
          dispatch(getSuggestionByType(4));
        }
      }
    }
  }, []);

  useEffect(() => {
    if (homeFeeds.length !== 0 && totalPages !== -1) {
      setHasNextPage(
        Math.ceil(homeFeeds.length / DEFAULT_HOME_PAGE_FEEDS) < totalPages || Math.ceil(homeFeeds.length / DEFAULT_HOME_PAGE_FEEDS) !== totalPages
      );
    }
  }, [homeFeeds, totalPages]);

  useEffect(() => {
    if (loading === "failed" && error) {
      message.error("Something went wrong.");
    }
  }, [loading, error]);

  useEffect(() => {
    if ((homeFeeds.length <= 10 || followers <= 3) && !["pending", "idle"].includes(loading)) {
      setCreatorSubscriptions(true);
    } else {
      setCreatorSubscriptions(false);
    }
  }, [homeFeeds, followers, loading]);

  useEffect(() => {
    if (homeFeeds.length > 3) {
      setShowSelectionOfWeek(false);
    } else {
      setShowSelectionOfWeek(true);
    }
  }, [homeFeeds]);

  useEffect(() => {
    userService
      .getFollowedCreator()
      .then((data: any) => {
        setFollowers(data);
      })
      .catch((error: any) => {
        message.error(error || t("something-want-wrong"));
      });
  }, []);

  const loadMoreFeeds = () => {
    if (hasNextPage) {
      dispatch(loadMorePageFeeds(Math.ceil(homeFeeds?.length / DEFAULT_HOME_PAGE_FEEDS) + 1));
    }
  };

  const [sentryRef] = useInfiniteScroll({
    loading: loading === "pending",
    hasNextPage: hasNextPage,
    onLoadMore: loadMoreFeeds,
  });

  const onReportClick = async (feed: IFeed) => {
    const payload = {
      target: "feed",
      targetId: feed._id,
      title: "report",
      description: "",
    };
    const resp = await reportService.createReport(payload);
    if (resp?.data) {
      message.success(t("report-success"));
    } else if (resp?.message) {
      message.error(resp.message);
    }
  };

  const handleRefresh = async () => {
    await dispatch(getHomePageFeeds(1));
    //setPage(1);
    setHasNextPage(false);
  };

  return (
    <>
      {loading === "pending" && <FanHomePageSkelton loading={loading === "pending"} />}
      {homeFeeds.length > 0 && (
        <PullToRefresh
          isPullable={true}
          onRefresh={handleRefresh}
          resistance={4}
          pullDownThreshold={90}
          pullingContent={
            <Row className="mt-20 mb-20 d-flex justify-content-center">
              <div className="beat-loader">
                <div></div>
                <div></div>
                <div></div>
              </div>
            </Row>
          }
        >
          <>
            {homeFeeds?.map((feed, index) => {
              let thumbnail = "";
              let fileUrl = "";
              let isUploading: boolean = false;
              if (feed.files !== undefined) {
                isUploading = feed.files[0].status === FILE_STATUS.UPLOADING;
                if (!feed.isPrivateForCurrentUser) {
                  fileUrl = feed.files[0].url!;
                  thumbnail = feed.files[0].thumbnails?.find((thumb) => thumb.type === THUMBNAIL_TYPES.CLEAR)?.absolutePath!;
                } else {
                  thumbnail = feed.files[0].thumbnails?.find((thumb) => thumb.type === THUMBNAIL_TYPES.BLURRED)?.absolutePath!;
                  fileUrl = thumbnail;
                }
              }
              if (isUploading) {
                return "";
              }

              const shouldInsertSelectionOfTheWeek = index === 3;

              return (
                <>
                  {shouldInsertSelectionOfTheWeek && (
                    <Space direction="vertical" className="suggestionPageWrapper m-negativ-50">
                      <SuggestionSectionFour creatorSuggestionSection={selectionWeek} />
                    </Space>
                  )}
                  <MainSwiperSlideItemV2
                    refProp={null}
                    feedId={feed._id!}
                    key={feed._id}
                    name={feed.creatorInfo?.name!}
                    username={feed.creatorInfo?.username!}
                    avatar={feed.creatorInfo?.avatar!}
                    creatorId={feed.creatorInfo?._id}
                    totalLikes={feed.totalLike}
                    fileUrl={fileUrl}
                    thumbnail={thumbnail}
                    isLiked={feed.isLiked}
                    isBookMarked={feed.isBookMarked}
                    isPrivate={feed.isPrivateForCurrentUser}
                    description={feed.text!}
                    onReportClick={() => feed && onReportClick(feed)}
                    verifiedAccount={feed.creatorInfo?.verifiedAccount}
                    type={feed.type!}
                    isCurrentUser={feed.creatorInfo?._id === authService.getId()}
                    isFanHomePage={true}
                    isUploading={isUploading}
                  />
                </>
              );
            })}
          </>
        </PullToRefresh>
      )}
      {(loading === "pending" || hasNextPage) && (
        <Row className="next-page-loading">
          <SpinnerComponent refProp={sentryRef} />
        </Row>
      )}
    </>
  );
};

export default MainSwiper;
