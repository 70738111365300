import React, { useEffect } from "react";
import { Button } from "antd";
import { useGoogleLogin, TokenResponse } from "@react-oauth/google";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import GoogleLogo from "../../assets/images/google_logo_resultat.webp";
import "./index.less";

const isInstagramBrowser = (): boolean => {
  const userAgent = navigator.userAgent || navigator.vendor;
  return /instagram/i.test(userAgent);
};

type Props = {
  className?: string;
  label?: string;
  onLoginSuccess?: (accessToken: string) => void;
  googleUnavailable?: () => void;
};

const AuthGoogleButtonComponent: React.FC<Props> = ({ className, onLoginSuccess, label, googleUnavailable }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleLoginClick = () => {
    if (googleUnavailable && isInstagramBrowser()) {
      googleUnavailable();
    } else {
      login();
    }
  };

  const handleLoginSuccess = (tokenResponse: TokenResponse) => {
    if (tokenResponse.access_token && onLoginSuccess) {
      onLoginSuccess(tokenResponse.access_token);
    }
  };

  const login = useGoogleLogin({ onSuccess: handleLoginSuccess });

  return (
    <Button
      className={className ? className : "authGoogleBtn"}
      onClick={handleLoginClick}
      icon={<img height={30} width={30} src={GoogleLogo} alt="google" />}
    >
      {label ? label : t("auth-social-google")}
    </Button>
  );
};

export default AuthGoogleButtonComponent;
