import { Typography } from "antd";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import ProgressBarGif from "../../../assets/gif/challenge.gif";
import { ILiveChallenge } from "../../../helpers/types";
import { endSuccessChallenge } from "../../../redux/lives/livesSlice";
import { AppDispatch } from "../../../redux/store";
import { liveService } from "../../../services";
import "./index.less";

interface ChallengeProgressionBarProps {
  challenge: ILiveChallenge;
  isCreator?: boolean;
}

const ChallengeProgressionBarComponent: React.FC<ChallengeProgressionBarProps> = ({ challenge, isCreator }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const progressPercentage = Math.min((challenge.collectedAmount / challenge.price) * 100, 100);
  const isCompleted = progressPercentage >= 100;

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;

    if (challenge && challenge._id && isCompleted) {
      timer = setTimeout(() => {
        dispatch(endSuccessChallenge());
        if (isCreator) liveService.updateStatusChallenge(challenge._id!);
      }, 120000);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [isCompleted, dispatch, challenge._id]);

  return (
    <div className="progress-bar-wrapper">
      <div className="progress-bar-title-container">
        <Typography.Text className="progress-bar-title">{challenge.title}</Typography.Text>
      </div>
      <div className="progress-bar-info-container">
        {!isCompleted ? (
          <>
            <div className="progress-bar-price-container">
              <Typography.Text className="progress-bar-text">
                <span className="progress-bar-price">{challenge.collectedAmount} €</span> {t("collected-out-of")}{" "}
                <span className="progress-bar-price">{challenge.price} €</span>
              </Typography.Text>
            </div>
            <div className="progress-bar-container">
              <div className="progress-bar" style={{ width: `${progressPercentage}%` }}></div>
              <img
                src={ProgressBarGif}
                alt="Progress bar icon"
                className="progress-bar-icon"
                style={{ left: `calc(${progressPercentage}% - 31px)` }}
              />
            </div>
          </>
        ) : (
          <div className="progress-bar-success">
            <Typography.Text className="progress-bar-success-text">{t("challenge-success")}</Typography.Text>
          </div>
        )}
      </div>
    </div>
  );
};

export default ChallengeProgressionBarComponent;
