import { Space, Typography, Upload, message } from "antd";
import type { RcFile, UploadFile, UploadProps } from "antd/es/upload/interface";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import VideoIcon from "../../assets/icons/jouer.svg";
import LockIcon from "../../assets/icons/lock-outlined.svg";
import PlusIcon from "../../assets/icons/plus-black.svg";
import { fileService } from "../../services";
import { compressImage, convertHeicFile, generateThumbnailURL, isImageAcceptedFormat } from "../../utls/FunctionsUtil";
import MailboxMediaModal from "../MailboxMediaModal";
import "./index.less";

type props = {
  disabled?: boolean;
  maxCount?: number;
  defaultList?: any[];
  onFilesChange?: (fileList: UploadFile<[]>[]) => void;
  conversationId?: string;
  receiverId?: string;
  messageId?: string;
  quickFiles?: any;
  allFiles?: any;
  fromCollection?: boolean;
  collectionTitle?: string;
};

const MediaPushMediaComponent: React.FC<props> = ({
  disabled = false,
  maxCount,
  defaultList,
  onFilesChange,
  conversationId,
  messageId,
  receiverId,
  quickFiles,
  allFiles,
  fromCollection = false,
  collectionTitle = "",
}) => {
  const { t } = useTranslation();
  const [fileList, setFileList] = useState<UploadFile[]>(allFiles);
  const [isMediaModal, setIsMediaModal] = useState(false);
  const uploadRef = useRef<any>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const params: any = new URLSearchParams(location.search);
  const paramsFiles: Array<any[]> = JSON.parse(params.get("selected"));
  const [paramIds, setParamIds] = useState<any>([]);

  const getAllSelectedFiles = async (fileIds: Array<any>) => {
    const selectedFileIds = fileList.map((file) => file.uid);
    const filesToFetch = fileIds.filter((id) => !selectedFileIds.includes(id));
    await fileService.allSelectedFiles(filesToFetch).then((response: any) => {
      const filesToAdd: any = [];
      response.forEach((item: any) => {
        let fileType = "";
        let fileExt = "";
        let isVideo = false;
        if (item.url.includes(".mp4")) {
          fileType = "video/mp4";
          fileExt = "mp4";
          isVideo = true;
        } else {
          fileType = "image/webp";
          fileExt = "webp";
        }
        let originFileObj2 = {
          isPrivate: true,
          status: "done",
          uid: item._id,
          name: item._id,
          percent: 0,
          thumbUrl: "",
          type: "quick-link",
        };
        let JsonString = JSON.stringify(originFileObj2);
        let blob = new Blob([JsonString], { type: fileType });
        let file = new File([blob], `newQuickFile.${fileExt}`, { type: fileType });

        filesToAdd.push({
          ...item,
          imgType: "quick-file",
          isPrivate: true,
          status: "done",
          uid: item._id,
          name: item._id,
          percent: 0,
          thumbUrl: isVideo ? item?.thumbnailUrl : item?.url,
          type: fileType,
          originFileObj: file,
        });

        //setQuickFiles((prev: any) => [...prev, { id: item._id, isPrivate: true }]);
      });

      setFileList((prevFiles: any) => {
        return [...prevFiles, ...filesToAdd];
      });
    });
  };

  useEffect(() => {
    if (paramsFiles?.length !== 0) {
      setParamIds(paramsFiles);
    }
    return () => {
      setParamIds([]);
    };
  }, []);

  useEffect(() => {
    if (paramIds !== null && paramIds !== undefined && paramIds?.length !== 0) {
      getAllSelectedFiles(paramIds);
    }
  }, [paramIds]);

  useEffect(() => {
    if (onFilesChange !== undefined) {
      onFilesChange(fileList);
    }
  }, [fileList]);

  const customItemRender = (originNode: React.ReactNode, file: any) => {
    let lock: boolean = false;
    let isThumbnail: boolean = false;
    let isVideo: boolean = file.type?.includes("video")!;
    defaultList?.forEach((e) => {
      if (file.uid === e.uid) {
        if (e.isPrivate) {
          lock = true;
        }
        isThumbnail = e.isThumbnail;
      }
    });

    return (
      <>
        {originNode}
        {fromCollection && isThumbnail && (
          <div className="collection-thumbnail">
            <Typography.Text className="collection-thumbnail-text">{t("miniature")}</Typography.Text>
          </div>
        )}
        {lock && (
          <img
            src={LockIcon}
            width={30}
            style={{ position: "relative", top: fromCollection && isThumbnail ? -110 : -90, right: -35, color: "white" }}
            alt="lock icon"
          />
        )}
        {isVideo && (
          <img src={VideoIcon} style={!lock ? { top: -147, right: -73 } : { top: -160, right: -45 }} alt="video icon" className="videoIcon" />
        )}
      </>
    );
  };

  const beforeUpload: UploadProps["beforeUpload"] = async (file: RcFile): Promise<File | boolean | string> => {
    try {
      //console.log("in before **upload** file", file.uid, file);
      // If the file is in an accepted format, proceed without conversion
      if (isImageAcceptedFormat(file.type)) {
        // compress file
        const compressedImage = await compressImage(file);
        //console.log("beforeUpload 1 compressedImage", compressedImage);
        if (compressedImage !== null) {
          handleConvertedFile(file.uid, compressedImage, true);
        }
        //handleConvertedFile(file.uid, file, true);
        return false;
      } else if (file.type === "image/heic" || file.type === "") {
        handleConvertedFile(file.uid, file, false);
        // convert file
        const convertedFile = await convertHeicFile(file);
        //console.log("beforeUpload 2 convertedFile", convertedFile);
        // compress file
        const compressedImage = await compressImage(convertedFile);
        //console.log("beforeUpload 3 compressedImage", compressedImage);
        if (compressedImage !== null) {
          handleConvertedFile(file.uid, compressedImage, true);
        }
        return false;
      } else if (file.type.includes("video/")) {
        handleConvertedFile(file.uid, file, true);
        return false;
      } else {
        message.error(t("unsupported-file-format-error-message"));
        return Upload.LIST_IGNORE;
      }
    } catch (error) {
      removeUnsupportedFiles(file.uid);
      message.error(t("unsupported-file-format-error-message"));
      return Upload.LIST_IGNORE;
    }
  };

  const handleConvertedFile = async (uid: string, file: File, converted: boolean) => {
    if (!converted) {
      const fileObj: UploadFile = {
        uid: uid,
        name: file.name,
        status: "uploading",
        percent: 50,
      };
      setFileList((prevList) => [fileObj, ...prevList]);
    } else {
      const fileObj: UploadFile = {
        uid: uid,
        name: file.name,
        status: "done",
        type: file.type,
        size: file.size,
        originFileObj: file as RcFile,
      };
      if (file.type.includes("video")) {
        const result = await generateThumbnailURL(file as RcFile);
        fileObj.url = result;
      }
      setFileList((prevList) => [fileObj, ...prevList.filter((file) => file.uid !== fileObj.uid)]);
    }
  };

  const isImageUrl = (): boolean => {
    return true;
  };

  const removeUnsupportedFiles = (uid: string) => {
    setFileList((prevList) => [...prevList.filter((file) => file.uid !== uid)]);
  };

  const onRemove = async (file: UploadFile<any>) => {
    //setAllFiles((prev: any) => prev.filter((f: any) => f.uid !== file.uid));
    //setQuickFiles((prev: any) => prev.filter((f: any) => f.id !== file.uid));
    setFileList((prev: any) =>
      prev.filter((f: any) => {
        return f.uid !== file.uid;
      })
    );
  };

  const handleDeviceClick = () => {
    if (uploadRef.current) {
      uploadRef.current.click();
      setIsMediaModal(false);
    }
  };

  return (
    <Space className={`w-full ${fileList.length === 0 ? "mediaPushMediaWrapper" : "addMediaWrapperRectangularShape"} `}>
      {fileList.length === 0 ? (
        <div className="d-flex justify-content-center" onClick={() => setIsMediaModal(true)}>
          {maxCount ? (
            fileList.length < maxCount && (
              <div className={`addCircleGreen ${disabled ? "disabled" : ""}`}>
                <img src={PlusIcon} width={70} alt="plus" />
              </div>
            )
          ) : (
            <div className={`addCircleGreen ${disabled ? "disabled" : ""}`}>
              <img src={PlusIcon} width={70} alt="plus" />
            </div>
          )}
        </div>
      ) : (
        <div className="rectangularShape" onClick={() => setIsMediaModal(true)}>
          {maxCount ? (
            fileList.length < maxCount && (
              <div className={`addCircle ${disabled ? "disabled" : ""}`}>
                <img src={PlusIcon} width={70} alt="plus" />
              </div>
            )
          ) : (
            <div className={`addCircle ${disabled ? "disabled" : ""}`}>
              <img src={PlusIcon} width={70} alt="plus" />
            </div>
          )}
        </div>
      )}

      <MailboxMediaModal
        isOpen={isMediaModal}
        onClose={() => setIsMediaModal(false)}
        onMyAlbums={() => {
          if (fromCollection) {
            navigate(`/creator/quickLink?id=collection&collectionTitle=${collectionTitle}`);
          } else {
            navigate(`/creator/quickLink?id=mediaPush&conversationId=${conversationId}&receiverId=${receiverId}&messageId=${messageId}`);
          }
        }}
        onMyDevice={handleDeviceClick}
      />

      <div>
        <Upload
          accept="image/*,video/*"
          disabled={disabled}
          action=""
          itemRender={(originNode: any, file: any) => customItemRender(originNode, file)}
          defaultFileList={defaultList}
          fileList={fileList}
          listType="picture-card"
          maxCount={maxCount}
          onRemove={onRemove}
          //onChange={onChangeU}
          beforeUpload={beforeUpload}
          isImageUrl={isImageUrl}
          showUploadList={{
            showRemoveIcon: true,
            showPreviewIcon: false,
          }}
          multiple={true}
        >
          <div ref={uploadRef}></div>
        </Upload>
      </div>
    </Space>
  );
};

export default MediaPushMediaComponent;
