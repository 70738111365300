import { SendOutlined } from "@ant-design/icons";
import { Row, Space, message } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import GiftIcon from "../../../assets/icons/gift.svg";
import ShareIcon from "../../../assets/icons/share.svg";
import SendCommentIcon from "../../../assets/icons/send-comment.svg";
import InputComponent from "../../../components/InputComponent";
import LiveCommentsComponent from "../../../components/LiveCommentsComponent";
import LiveTipModalComponent from "../../../components/LiveTipModalComponent";
import LiveTipsComponent from "../../../components/LiveTipsComponent";
import ShareModalComponent from "../../../components/ShareModalComponent";
import { PAYMENT_TYPES } from "../../../helpers/constant";
import { ILive, ILiveTip } from "../../../helpers/types";
import { addLiveComment, editLiveComment, newLiveTip } from "../../../redux/lives/liveDataSlice";
import { clearToken, editLive } from "../../../redux/lives/livesSlice";
import { AppDispatch, StoreState } from "../../../redux/store";
import { authService, liveService } from "../../../services";
import { socket } from "../../../socket";
import LiveTipsLeaderboardComponent from "../../../components/LiveTipsLeaderboardComponent";
import "./index.less";

interface Props {
  id: string;
  isChatAvailable: boolean;
  live: ILive | null;
}

export const FanLiveFooter = (props: Props) => {
  const { id, isChatAvailable, live } = props;
  const dispatch = useDispatch<AppDispatch>();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const liveComments = useSelector((state: StoreState) => state.liveStreamStats.comments.list);
  const liveComment = useSelector((state: StoreState) => state.liveStreamStats.comments.item);
  const [isShareModalOpen, setIsShareModalOpen] = useState<boolean>(false);
  const [isTipModalOpen, setIsTipModalOpen] = useState<boolean>(false);
  const [isPersonalAmount, setIsPersonalAmount] = useState<boolean>(false);
  const [tipAmount, setTipAmount] = useState<number | null>(null);

  useEffect(() => {
    socket.on("comment_added", commentAdded);
    socket.on("tip_updated", tipUpdated);
    return () => {
      socket.off("comment_added", commentAdded);
      socket.off("tip_updated", tipUpdated);
    };
  }, [dispatch]);

  const tipUpdated = async (data: any) => {
    if (data?.tipData) {
      await dispatch(newLiveTip(data.tipData));
    }
    if(data?.top3Tips) {
      await dispatch(editLive({ ...live, currentChallenge: data.currentChallenge, top3Tips: data.top3Tips }));
    }
  };

  const commentAdded = async (data: any) => {
    await dispatch(addLiveComment(data.comments));
  };

  const changedComment = (text: string | null) => {
    dispatch(editLiveComment({ ...liveComment, text }));
  };

  const handleSendComment = async (event: any) => {
    if (!liveComment?.text) return;

    if (!liveComment?.streamId || !liveComment?.user)
      await dispatch(
        editLiveComment({
          ...liveComment,
          streamId: id,
          user: authService.getId(),
        })
      );

    socket.emit("public-stream/add-comment", {
      id,
      text: liveComment.text,
    });

    await dispatch(
      editLiveComment({
        ...liveComment,
        text: null,
      })
    );
  };

  const onKeyPress = async (event: any) => {
    if (event?.charCode !== 13) return;

    if (!liveComment?.text) return;

    if (!liveComment?.streamId || !liveComment?.user)
      await dispatch(
        editLiveComment({
          ...liveComment,
          streamId: id,
          user: authService.getId(),
        })
      );

    socket.emit("public-stream/add-comment", {
      id,
      text: liveComment.text,
    });

    await dispatch(
      editLiveComment({
        ...liveComment,
        text: null,
      })
    );
  };

  const onSubmitTip = () => {
    if (!id || !tipAmount) return message.warning("Please select amount.");

    liveService
      .createTip(id, tipAmount)
      .then((data: ILiveTip) => {
        if (data?._id) {
          dispatch(clearToken());
          navigate(
            `/fan/payment?creatorId=${live?.creator?._id}&type=${PAYMENT_TYPES.LIVE_TIP}&liveTipId=${data._id}&redirectUrl=fan/live/${live?._id}`
          );
        }
      })
      .catch((error) => {});
  };

  return (
    <>
      <Space className="z-fan-live footer-shadow mb-5" direction="vertical">
        {live?.top3Tips && <LiveTipsLeaderboardComponent topTips={live?.top3Tips} />}
        <Row>
          <LiveCommentsComponent comments={liveComments} topTips={live?.top3Tips} />
        </Row>
        <Row className="justify-content-between flex-no-wrap gap-5" align="middle">
          {isChatAvailable && (
            <Space.Compact style={{ width: "100%", position: "relative" }}>
              <InputComponent
                className="liveCommentInput"
                value={liveComment?.text}
                onChange={(e) => {
                  const newValue = e.target.value;
                  if (newValue.length <= 150) {
                    changedComment(newValue);
                  }
                }}
                onKeyPress={onKeyPress}
                placeholder={`${t("comment")}`}
              />
              <div className="liveCommentInputSendBtn" onClick={handleSendComment}>
                <img src={SendCommentIcon} className="mr-5" width={16} alt="send" />
              </div>
            </Space.Compact>
          )}
          <div className="fan-footer-right-container">
            <LiveTipsComponent fromFan={true} />
            <img className="gift-icon" src={GiftIcon} alt="gift" onClick={() => setIsTipModalOpen(true)} />
          </div>
          {/* <img src={ShareIcon} width={22} alt="share" onClick={() => setIsShareModalOpen(true)} /> */}
        </Row>
      </Space>
      <ShareModalComponent
        isOpen={isShareModalOpen}
        onClose={() => setIsShareModalOpen(false)}
        id={live?.creator?._id}
        username={live?.creator?.username}
      />
      <LiveTipModalComponent
        userName={live?.creator?.name || null}
        isOpen={isTipModalOpen}
        amount={tipAmount}
        isPersonalAmount={isPersonalAmount}
        setIsPersonalAmount={(value) => setIsPersonalAmount(value)}
        onChangeValue={(value) => setTipAmount(value)}
        onSubmit={onSubmitTip}
        onClose={() => {
          setTipAmount(null);
          setIsTipModalOpen(false);
        }}
      />
    </>
  );
};
