import React, { useEffect, useState } from "react";
import { AreaChart, Area, XAxis, YAxis, Tooltip, ResponsiveContainer } from "recharts";
import { useTranslation } from "react-i18next";
import dateUtil from "../../utls/DateUtil";
import dayjs from "dayjs";

interface Props {
  statsData?: any;
  graphData?: string;
}

interface StatsData {
  count: number;
  label: string | number;
}

// Custom dot component with conditional radius based on value
const CustomDot = (props: any) => {
  const { cx, cy, value } = props;
  // alert(value)
  if (value?.toString() === "0,0") {
    // Render a smaller dot if the value is 0
    return <circle cx={cx} cy={cy} r={0} fill="#7FFAA1" />;
  }
  // Render a larger dot for non-zero values
  return <circle cx={cx} cy={cy} r={3} fill="#7FFAA1" />;
};

const CustomTooltipContent = ({ active, payload, label }: any) => {
  if (active && payload && payload.length) {
    return (
      <div
        style={{
          backgroundColor: "rgba(0, 0, 0, 0.6)",
          padding: "5px 14px",
          borderRadius: "8px",
          color: "#FFFFFF",
        }}
      >
        <p className="font-12-bold mt-1 mb-0">{label}</p> {/* Date or day label */}
        <p className="font-12-bold mb-0">€{Number(payload[0].value).toFixed(2)}</p> {/* Formatted value */}
      </div>
    );
  }

  return null;
};

const CreatorRevenueChartComponent: React.FC<Props> = ({ statsData, graphData }) => {
  const { t } = useTranslation();

  const weekLabels = dateUtil.getWeekLabelsForRevenuesPage(t("current-date"));
  const [labels, setLabels] = useState<any>(weekLabels);
  const [count, setCount] = useState<any>([0]);
  const dayAbbreviations: any = {
    Sunday: "Sun",
    Monday: "Mon",
    Tuesday: "Tue",
    Wednesday: "Wed",
    Thursday: "Thu",
    Friday: "Fri",
    Saturday: "Sat",

    Dimanche: "Sun",
    Lundi: "Mon",
    Mardi: "Tue",
    Mercredi: "Wed",
    Jeudi: "Thu",
    Vendredi: "Fri",
    Samedi: "Sat",

    Domingo: "Sun",
    Lunes: "Mon",
    Martes: "Tue",
    Miércoles: "Wed",
    Jueves: "Thu",
    Viernes: "Fri",
    Sábado: "Sat",
  };

  useEffect(() => {
    if (graphData === "today") {
      let labels = [];
      for (let hour = 0; hour < 24; hour++) {
        let hourString = hour.toString().padStart(2, "0");

        for (let minute = 0; minute < 60; minute += 30) {
          let minuteString = minute.toString().padStart(2, "0");
          labels.push(`${hourString}:${minuteString}`);
        }
      }

      let data = new Array(labels.length).fill(0);
      statsData?.forEach((stat: any) => {
        let date = new Date(stat.label);
        let hour = date.getHours();
        let minute = date.getMinutes();
        let minuteIndex = minute === 0 ? 0 : 1;
        let labelIndex = hour * 2 + minuteIndex;
        data[labelIndex] += stat.count;
      });

      setLabels(labels);
      setCount(data);
    } else if (graphData === "month") {
      const labelsArray = dateUtil.getMonthDaysWithCurrentDayLast();
      setLabels(labelsArray);
      const statsMap = new Map<string, number>(statsData?.map((data: StatsData) => [dayjs(data.label).format("DD/MM/YYYY"), data.count]));
      const countArray: number[] = [];
      labelsArray.forEach((day: string) => {
        const count = statsMap?.get(day) || 0;
        countArray.push(count);
      });
      setCount(countArray);
    } else if (graphData === "week") {
      setLabels(weekLabels);

      const countArray = new Array(7).fill(0);

      weekLabels.forEach((day, index) => {
        const dayAbbreviation = dayAbbreviations[day];

        if (!dayAbbreviation) {
          console.warn(`No abbreviation found for: ${day}`);
        }
        const matchingData = statsData?.find((data: any) => {
          return data.label === dayAbbreviation;
        });

        countArray[index] = matchingData ? matchingData.count : 0;
      });

      setCount(countArray);
    } else if (graphData === "total") {
      if (statsData?.length !== 0) {
        let label = statsData?.map((data: { label: number }) => data.label);
        let count = statsData?.map((data: { count: number }) => data.count);
        setLabels([""].concat(label));
        setCount([""].concat(count));
      } else {
        const currentYear = new Date().getFullYear();
        setLabels(["", currentYear]);
        setCount(["", ""]);
      }
    }
  }, [graphData, statsData]);

  // Prepare data for the chart
  const chartData = labels?.map((label: string, index: number) => ({
    label,
    count: count[index] || 0,
  }));

  return (
    <ResponsiveContainer width="100%" height={263}>
      <AreaChart data={chartData} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
        <defs>
          <linearGradient id="colorOpacity" x1="0" y1="0" x2="0" y2="1">
            {/* Couleur plus foncée en haut */}
            <stop offset="0%" stopColor="#7FFAA1" stopOpacity={0.8} />
            {/* Couleur plus transparente en bas */}
            <stop offset="100%" stopColor="#7FFAA1" stopOpacity={0} />
          </linearGradient>
        </defs>
        {/* Hide X-axis labels */}
        <XAxis dataKey="label" tick={false} axisLine={false} />

        {/* Show Y-axis labels with euro symbol */}
        <YAxis tickFormatter={(value) => `€${value}`} axisLine={false} tickLine={false} />

        {/* Tooltip configuration */}
        <Tooltip content={<CustomTooltipContent />} position={{ y: 0 }} />

        {/* Area and line with green color */}
        <Area
          type="monotone"
          dataKey="count"
          stroke="#7FFAA1" // Green line color
          fill="url(#colorOpacity)" // Green fill with opacity
          strokeWidth={2}
          dot={<CustomDot />} // Use the CustomDot component for conditional dot size
          activeDot={{ r: 6 }} // Hover size for non-zero values
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default CreatorRevenueChartComponent;
