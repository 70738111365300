import { Button, Layout, Row, Space, Typography, message } from "antd";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import useInfiniteScroll from "react-infinite-scroll-hook";
import ArrowLeftIconComponent from "../../../components/ArrowLeftIconComponent";
import FanAvatarComponent from "../../../components/FanAvatarComponent";
import SpinnerComponent from "../../../components/SpinnerComponent/SpinnerComponent";
import { subscriptionsService } from "../../../services";
import { ConfirmationModal } from "../../../components/ModalConfirmation";
import "./index.less";

const CreatorAllSubscribers = () => {
  const { t } = useTranslation();
  const shouldFetch = useRef(true);
  const [allSubscribers, setAllSubscribers] = useState<any[]>([]);
  const [skip, setSkip] = useState<any>(0);
  const [loading, setLoading] = useState<any>(false);
  const [hasNextPage, setHasNextPage] = useState<any>(false);
  const [isConfirmationMoodal, setIsConfirmationMoodal] = useState<any>(false);
  const [deleteId, setDeleteId] = useState<any>("");
  const [totalData, setTotalData] = useState<any>(0);
  const NB_USER_TO_SHOW = 20;

  async function subscriptions(limit: any, skip: any) {
    setLoading(true);
    await subscriptionsService
      .getCreatorAllSubscribers(limit, skip)
      .then((response: any) => {
        setLoading(false);
        setTotalData(response?.total);
        if (allSubscribers.length === 0) {
          setAllSubscribers(response.data);
        } else {
          setAllSubscribers((prevData) => Array.from(new Set([...prevData, ...(response?.data ?? [])])));
        }
      })
      .catch((error) => {
        message.error(t("something-want-wrong"));
      });
  }

  useEffect(() => {
    if (shouldFetch.current) {
      shouldFetch.current = false;
      subscriptions(NB_USER_TO_SHOW, 0);
    }
  }, []);

  async function loadMoreUsers() {
    const nextPage = skip + NB_USER_TO_SHOW;
    setSkip((prev: any) => prev + NB_USER_TO_SHOW);
    const totalPages = Math.ceil(totalData! / NB_USER_TO_SHOW);
    await subscriptions(NB_USER_TO_SHOW, nextPage);
    if (Math.ceil(nextPage / NB_USER_TO_SHOW) >= totalPages) {
      setHasNextPage(false);
    }
  }

  const [sentryRef] = useInfiniteScroll({
    loading: loading,
    hasNextPage,
    onLoadMore: loadMoreUsers,
  });

  async function handleRemoveSubscriber(id: any) {
    if (id) {
      const userId: any = id;
      await subscriptionsService.removeSubscriber(userId).then((res) => {
        if (res?.data) {
          setSkip(0);
          setHasNextPage(false);
          setAllSubscribers([]);
          setLoading(true);
          subscriptions(NB_USER_TO_SHOW, 0);
          setDeleteId("");
          setIsConfirmationMoodal(false);
          message.success("Fan removed");
        } else {
          message.error(t("something-want-wrong"));
        }
      });
    }
  }

  return (
    <Layout>
      <Layout.Content className="content creatorAllSubscribers gradient-background-profile">
        <Row className="relative justify-content-center header-title-container-50 mb-15">
          <ArrowLeftIconComponent />
          <Typography.Text className="header-title">{t("subscribers")}</Typography.Text>
        </Row>
        {allSubscribers
          ?.filter((item) => item.status === "active")
          ?.map((item, index) => {
            return (
              <Space key={index} className="w-full items-center justify-between mt-13">
                <Space className="items-center gap-15">
                  <FanAvatarComponent name={`${item?.fanInfo?.firstName} ${item?.fanInfo?.lastName}`} size={60} />
                  <Space className="gap-0" direction="vertical">
                    <Space>
                      <Typography.Text className="fanSubscriptionCreatorName">
                        {item?.fanInfo?.firstName} {item?.fanInfo?.lastName}
                      </Typography.Text>
                    </Space>
                  </Space>
                </Space>
                <Typography.Text className="fanSubscriptionCreatorName">
                  <Button
                    className="deleteButton"
                    onClick={() => {
                      setIsConfirmationMoodal(true);
                      setDeleteId(item?.fanInfo?._id);
                    }}
                  >
                    {t("Delete")}
                  </Button>
                </Typography.Text>
              </Space>
            );
          })}
        {totalData !== allSubscribers?.length && totalData !== 0 && allSubscribers?.length !== 0 && !loading && !hasNextPage && (
          <Row className={`mx-auto bg-black`}>
            <Typography.Text
              onClick={() => {
                setHasNextPage(true);
              }}
              className="SearchSeeAllResults mx-auto"
            >
              {t(`see-all-results`)}
            </Typography.Text>
          </Row>
        )}
        {(loading || hasNextPage) && <SpinnerComponent refProp={sentryRef} />}
        {!loading && allSubscribers?.length === 0 && <p className="no-data-found">{t("no-subscribers")}</p>}
      </Layout.Content>

      <ConfirmationModal
        confBtnMsg={t("modal-confirm-btn")}
        handleConfirm={() => handleRemoveSubscriber(deleteId)}
        handleToggle={() => setIsConfirmationMoodal(false)}
        open={isConfirmationMoodal}
        title={t("confirm-remove-fan")!}
      />
    </Layout>
  );
};

export default CreatorAllSubscribers;
