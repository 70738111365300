import type { TabsProps } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import AuthSignUpCreatorTabComponent from "../../../components/AuthSignUpCreatorTabComponent";
import AuthSignUpFanTabComponent from "../../../components/AuthSignUpFanTabComponent";
import AuthTabsCompnent from "../../../components/AuthTabsComponent";
import { CREATOR } from "../../../helpers/constant";
import { IUser } from "../../../helpers/types";
import AuthGoogleUnavailableComponent from "../../../components/AuthGoogleUnavailableComponent";

const SignUpPage = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");
  const [authGoogleUnavailable, setAuthGoogleUnavailable] = useState<boolean>(false);
  const [curentStep, setCurrentStep] = useState<Number>(1);
  const [usersId, setUsersId] = useState<string | null>(null);
  const [userEmail, setUserEmail] = useState<string | null>(null);
  const [currentUser, setCurrentUser] = useState<IUser | null>(null);
  const id = searchParams.get("id");
  const email = searchParams.get("email");
  const tabValue: any = searchParams.get("currentTab");

  const googleUnavailable = () => {
    setAuthGoogleUnavailable(true);
  };

  const googleUnavailableCancel = () => {
    setAuthGoogleUnavailable(false);
  };

  const tabs: TabsProps["items"] = [
    {
      key: "creator",
      label: t("auth-tab-creator"),
      children: (
        <AuthSignUpCreatorTabComponent
          setCurrentStep={setCurrentStep}
          curentStep={curentStep}
          setUserEmail={setUserEmail}
          userEmail={userEmail}
          setUsersId={setUsersId}
          usersId={usersId}
          setCurrentUser={setCurrentUser}
          currentUser={currentUser}
          googleUnavailable={googleUnavailable}
        />
      ),
    },
    {
      key: "fan",
      label: t("auth-tab-fan"),
      children: <AuthSignUpFanTabComponent googleUnavailable={googleUnavailable} />,
    },
  ];

  useEffect(() => {
    if (id && email && tabValue) {
      getCurrentUser(id, email, Number(tabValue));
    }
  }, [id, email, tabValue]);

  const getCurrentUser = (id: string, email: string, tabValue: number) => {
    setUserEmail(email);
    setUsersId(id);
    setCurrentStep(tabValue);
    window.history.replaceState({}, "", location.pathname);
  };

  return (
    <>
      {authGoogleUnavailable ? (
        <AuthGoogleUnavailableComponent cancel={googleUnavailableCancel} />
      ) : (
        <div className="gradient-background">
          {curentStep === 0 || curentStep === 1 ? (
            <div className="h-full">
              <AuthTabsCompnent tabs={tabs} defaultActiveKey={tabValue === CREATOR || token ? "creator" : "fan"} />
            </div>
          ) : (
            <div className="h-full-screen">
              <AuthSignUpCreatorTabComponent
                setCurrentStep={setCurrentStep}
                curentStep={curentStep}
                setUserEmail={setUserEmail}
                userEmail={userEmail}
                setUsersId={setUsersId}
                usersId={usersId}
                setCurrentUser={setCurrentUser}
                currentUser={currentUser}
                googleUnavailable={googleUnavailable}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default SignUpPage;
