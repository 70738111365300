import { Checkbox, Col, Layout, Radio, Row, Space, Spin, Switch, Tag, Typography, UploadFile, message } from "antd";
import { RcFile } from "antd/es/upload";
import differenceBy from "lodash/differenceBy";
import uniqBy from "lodash/uniqBy";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import ArrowLeftIcon from "../../../assets/icons/new-arrow-left.svg";
import MediaPushMediaComponent from "../../../components/AddMediaComponent/MediaPushMediaComponent";
import ArrowLeftIconComponent from "../../../components/ArrowLeftIconComponent";
import AuthButtonComponent from "../../../components/AuthButtonCompnent";
import InputComponent from "../../../components/InputComponent";
import MediaPushLimitReachedModalComponent from "../../../components/MediaPushLimitReachedModalComponent";
import MessageTextAreaComponent from "../../../components/MessageTextAreaComponent";
import PriceInputComponent from "../../../components/PriceInputComponent";
import SpinnerComponent from "../../../components/SpinnerComponent/SpinnerComponent";
import { CREATOR, MAX_MEDIAS_TO_SEND, MAX_PRICE, getDiscountExpirePeriods, getMediaAmounts } from "../../../helpers/constant";
import { FilePrivacy, IMediapush, ISegmentations } from "../../../helpers/types";
import { setSelectFiles } from "../../../redux/mediaPush/mediaPushSlice";
import { AppDispatch, StoreState } from "../../../redux/store";
import { mediapushService, storageService } from "../../../services";
import { socket } from "../../../socket";
import { generateThumbnailURL, generateURL } from "../../../utls/FunctionsUtil";
import "./index.less";

type MediaPushFiles = UploadFile & { isPrivate: boolean };

type PrivateType = {
  showDialog: boolean;
  fileId: string;
  fileSrc: any;
  fileType: string;
};

const CreatorMediaPushPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const mediaAmounts = getMediaAmounts(t);
  const discountExpirePeriods = getDiscountExpirePeriods(t);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const isConversation: boolean =
    searchParams.get("conversation") !== null &&
    searchParams.get("conversation") !== undefined &&
    searchParams.get("conversation") !== "" &&
    searchParams.get("conversation") !== "false";
  const conversationId = searchParams.get("conversationId");
  const receiverId = searchParams.get("receiverId");
  const messageId = searchParams.get("messageId");
  const shouldFetch = useRef(true);
  const [selectedTime, setSelectedTime] = useState<any>();
  const [segmentations, setSegmentations] = useState<ISegmentations | null>(null);
  const [radioValue, setRadioValue] = useState<string | undefined>(undefined);
  const [customPrice, setCustomPrice] = useState<number | undefined>(undefined);
  const [promoCodeChecked, setPromoCodeChecked] = useState(false);
  const [promoPercentage, setPromoPercentage] = useState(false);
  const [promoCurrency, setPromoCurrency] = useState(false);
  const [discountPercentage, setDiscountPercentage] = useState<string | undefined>(undefined);
  const [discountCurrency, setDiscountCurrency] = useState<string | undefined>(undefined);
  const [mediapush, setMediapush] = useState<IMediapush>({});
  const [privateFiles, setPrivateFiles] = useState<PrivateType[]>([]);
  const { files, quickFiles } = useSelector((state: StoreState) => state.mediaPushes);
  const [loading, setLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(isConversation ? 2 : 1);
  const [selectedValues, setSelectedValues] = useState(mediapush.segmentation);
  const [componentKey, setComponentKey] = useState(0);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [progress, setProgress] = useState<number>(0);
  const [loadedData, setLoadedData] = useState<number>(0);
  const [totalSize, setTotalSize] = useState<number>(0);

  useEffect(() => {
    if (shouldFetch.current && !isConversation) {
      shouldFetch.current = false;
      mediapushService
        .getSegmentations()
        .then((data: ISegmentations) => setSegmentations(data))
        .catch((error) => {
          message.error(error || "Something went wrong.");
        });
    }
    changedItem("promo", promoCodeChecked);
    const segmentation = window.localStorage.getItem("segmentation");
    if (segmentation !== null && segmentation !== undefined) {
      setMediapush((prevMediapush) => ({
        ...prevMediapush,
        segmentation: JSON.parse(segmentation) as string[],
      }));
      setCurrentStep(2);
    }
  }, []);

  useEffect(() => {
    function onProgressUpdate(data: number) {
      setProgress((prev) => {
        const prog = prev + 1;
        if (prog <= 99) {
          return prog;
        }
        return prev;
      });
    }

    function onProcessEnd(data: any) {
      if (messageId && messageId !== "" && messageId !== "undefined") {
        socket.emit("accept-message", {
          messageId: messageId,
          userType: CREATOR,
        });
      }

      setLoading(false);
      message.success(t("media-push-created-success"));
      if (isConversation) {
        navigate("/creator/conversation/" + conversationId);
      } else {
        navigate("/creator/message");
      }
    }

    socket.on("file-updated", onProcessEnd);
    socket.on("progress-update", onProgressUpdate);

    return () => {
      socket.off("file-updated", onProcessEnd);
      socket.off("progress-update", onProgressUpdate);
    };
  }, []);

  useEffect(() => {
    if (totalSize > 0) {
      const uploadProgress = Math.round((loadedData / totalSize) * 100);
      const globalProgress = Math.round(uploadProgress / 2);
      setProgress((prev) => {
        if (globalProgress > prev) {
          return globalProgress;
        }
        return prev;
      });
    }
  }, [loadedData]);

  useEffect(() => {
    if (!mediapush.price || mediapush.price <= 0) {
      setPromoCodeChecked(false);
      delete mediapush.discount;
      delete mediapush.discountType;
      delete mediapush.expireTimeType;
      setPromoCurrency(false);
      setPromoPercentage(false);
      setDiscountCurrency(undefined);
      setDiscountPercentage(undefined);
    }
  }, [mediapush.price]);

  const changedItem = (field: keyof IMediapush, value: any) => {
    setMediapush((prevMediapush) => ({
      ...prevMediapush,
      [field]: value,
    }));
    if (field === "segmentation") {
      window.localStorage.setItem("segmentation", JSON.stringify(value));
    }
  };

  const handleCustomPriceChange = (value: number | null) => {
    if (value !== null) {
      setCustomPrice(value);
      setRadioValue(undefined);
      changedItem("price", value);

      // maximum price alert 500€
      if (value > MAX_PRICE) {
        message.warning(t("maximum-price-alert"));
      }
    }
  };

  const handleRadioChange = (e: any) => {
    const value = e.target.value;
    setRadioValue(value);
    setCustomPrice(parseInt(value));
    changedItem("price", parseInt(value));

    // maximum price alert 500€
    if (value > MAX_PRICE) {
      message.warning(t("maximum-price-alert"));
    }
  };

  const handleDiscountPercentageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = parseInt(e.target.value);

    if (!isNaN(value)) {
      if (value > 100) {
        value = 100;
      } else if (value < 0) {
        value = 0;
      }
    } else {
      value = 0;
    }

    setPromoPercentage(true);
    setPromoCurrency(false);
    setDiscountPercentage(value.toString());
    setDiscountCurrency(undefined);
    changedItem("discount", value);
    changedItem("discountType", "PERCENTAGE");
  };

  const handleDiscountCurrencyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setPromoCurrency(true);
    setPromoPercentage(false);
    setDiscountCurrency(value);
    setDiscountPercentage(undefined);
    changedItem("discount", parseInt(value));
    changedItem("discountType", "CURRENCY");
  };

  const handleExpireTimeChange = (value: string, timeType: string) => {
    changedItem("expireTime", parseInt(value));
    changedItem("expireTimeType", timeType);
  };

  const handleFilesChange = async (fileList: UploadFile<[]>[]) => {
    const newQuickFiles: any[] = [];
    const newFileList: MediaPushFiles[] = fileList?.map((file: any) => {
      const fileIndex = files.findIndex((currentFile) => {
        return currentFile.uid === file.uid;
      });
      if (file.imgType !== undefined && file.imgType === "quick-file") {
        newQuickFiles.push({ id: file.uid, isPrivate: fileIndex !== -1 ? files[fileIndex].isPrivate : true });
      }
      if (fileIndex !== -1) {
        return {
          ...file,
          isPrivate: files[fileIndex].isPrivate,
        };
      }
      return {
        ...file,
        isPrivate: fileIndex !== -1 ? files[fileIndex].isPrivate : true,
      };
    });

    const filesToSetPrivacy = differenceBy(fileList, files, "uid").filter((file) => file.status === "done");
    if (filesToSetPrivacy) {
      const filesTSP: PrivateType[] = await Promise.all(
        filesToSetPrivacy.map(async (file: any) => {
          let src = file.url as string;
          if (file.imgType !== "quick-file") {
            if (file.type?.includes("video")) {
              src = await generateThumbnailURL(file.originFileObj as RcFile);
            } else {
              src = await generateURL(file.originFileObj as RcFile);
            }
          } else {
            src = file.thumbUrl!;
          }
          return {
            showDialog: true,
            fileId: file.uid,
            fileSrc: src,
            fileType: file.type!,
          };
        })
      );
      setPrivateFiles((prev: PrivateType[]) => uniqBy([...prev, ...filesTSP], "fileId"));
    }

    dispatch(setSelectFiles({ files: newFileList, quickFiles: newQuickFiles }));
  };

  const onNextClick = (privateFile: PrivateType) => {
    setPrivateFiles((prev: PrivateType[]) => prev.filter((file) => file.fileId !== privateFile.fileId));
  };

  const handleLockSwitchChange = async (value: boolean, privateFile: PrivateType) => {
    const newQuickFileList: MediaPushFiles[] = quickFiles?.map((file: any) => {
      if (file.id === privateFile.fileId) {
        return {
          ...file,
          isPrivate: value,
        };
      }
      return file;
    });
    const newFileList: MediaPushFiles[] = files?.map((file) => {
      if (file.uid === privateFile.fileId) {
        return {
          ...file,
          isPrivate: value,
        };
      }
      return file;
    });
    dispatch(setSelectFiles({ files: newFileList, quickFiles: newQuickFileList }));
  };

  const processQuickFiles = (array: any[]): any[] => {
    let uniqueItems: any[] = [];
    array.forEach((item) => {
      if (typeof item === "object" && item.id) {
        const id: string = item.id;
        const isAlready = uniqueItems.find((items: any) => items.id === id);
        if (!isAlready) {
          uniqueItems.push(item);
        }
      } else {
        const isAlready = uniqueItems.find((items: any) => items === item);
        if (!isAlready) {
          uniqueItems.push(item);
        }
      }
    });
    return uniqueItems;
  };

  const handleCreateMediapush = async () => {
    if (!isConversation) {
      if (Number(segmentations?.subscribers) === 0 && Number(segmentations?.oldFollowers) === 0 && Number(segmentations?.followers) === 0) {
        message.error(t("message-no-target-audience"));
        return;
      }

      if (mediapush?.segmentation?.includes("subscribers") && Number(segmentations?.subscribers) === 0) {
        message.warning(t("message-no-subscribers"));
        return;
      } else if (mediapush?.segmentation?.includes("followers") && Number(segmentations?.followers) === 0) {
        message.warning(t("message-no-interested-audience"));
        return;
      } else if (mediapush?.segmentation?.includes("oldFollowers") && Number(segmentations?.oldFollowers) === 0) {
        message.warning(t("message-no-former-subscribers"));
        return;
      }
    }

    if (mediapush?.segmentation?.length === 0 || (!mediapush?.segmentation && !isConversation)) {
      message.warning(t("target-required"));
      return;
    } else if (!mediapush?.text) {
      message.warning(t("text-required"));
      return;
    } else if (mediapush?.price === undefined || mediapush?.price < 0 || Number.isNaN(mediapush.price)) {
      message.warning(t("amount-required"));
      return;
    } else if (mediapush?.price > MAX_PRICE) {
      message.warning(t("maximum-price-alert"));
      return;
    } else if (mediapush?.discount && mediapush?.discount === undefined && mediapush.expireTime === undefined) {
      message.warning(t("validity-time-required"));
      return;
    }
    if (files.length === 0 && quickFiles.length === 0) {
      message.warning(t("files-required"));
      return;
    }
    setLoading(true);
    let mediaToCreate: IMediapush = {
      ...mediapush,
      isConversation,
      conversationId: conversationId !== null ? conversationId : "",
      receivers: receiverId !== null && isConversation ? [receiverId] : [],
    };

    const uniqueQuickFiles = processQuickFiles(quickFiles);

    let formData = new FormData();
    const filesPrivacy: FilePrivacy[] = [];
    const videoFileToChunk: any[] = [];

    files?.forEach((file: any) => {
      const isObject = isPlainObject(file);
      if (isObject && file?.imgType !== "quick-file") {
        if (file?.originFileObj?.type?.includes("image/")) {
          formData.append(`files`, file.originFileObj as any);
          filesPrivacy.push({
            fileId: file.uid,
            isPrivate: file.isPrivate,
          });
        } else {
          videoFileToChunk.push(file);
        }
      }
    });

    for (const [key, value] of Object.entries(mediaToCreate)) {
      formData.set(key, value);
    }
    formData.set("receivers", JSON.stringify(mediaToCreate.receivers));
    formData.set("fileIds", JSON.stringify(filesPrivacy));
    formData.set("quickFiles", JSON.stringify(uniqueQuickFiles));
    if (mediaToCreate.segmentation !== undefined) {
      formData.set("segmentation", JSON.stringify(mediaToCreate.segmentation));
    }

    if (videoFileToChunk.length > 0) {
      const videoFiles: any[] = [];
      const totalSizes = videoFileToChunk.reduce((acc, file) => acc + file.size, 0);
      setTotalSize(totalSizes);

      for (let i = 0; i < videoFileToChunk.length; i++) {
        const file = videoFileToChunk[i];

        const result = await storageService.uploadFileMultiPart(file, setLoadedData);

        videoFiles.push({
          Location: result.Location,
          isPrivate: file.isPrivate,
          Key: result.Key,
          ETag: result.ETag,
          Bucket: result.Bucket,
          filename: file.name,
          size: file.size,
        });
      }

      formData.append("videoFiles", JSON.stringify(videoFiles));
    }

    if (!isConversation) {
      await mediapushService.createMediaPushV2(formData, setProgress).then((resp: any) => {
        removeSegmentationFromLS();
        setLoading(false);
        if (resp.message) {
          if (resp.message === "send-push-media-followers-oldFollowers-once-a-week") {
            setOpenModal(true);
          } else {
            message.error(t(resp.message));
          }
        } else {
          message.info(t("media-push-sent-in-progress"), 3);
          navigate("/creator/message");
          dispatch(setSelectFiles({ files: [], quickFiles: [] }));
        }
      });
    } else {
      await mediapushService.createMediaPush(formData, setProgress).then((resp: any) => {
        removeSegmentationFromLS();
        setLoading(false);
        if (resp.message) {
          if (resp.message === "send-push-media-followers-oldFollowers-once-a-week") {
            setOpenModal(true);
          } else {
            message.error(t(resp.message));
          }
        } else {
          if (messageId && messageId !== "" && messageId !== "undefined") {
            socket.emit("accept-message", {
              messageId: messageId,
              userType: CREATOR,
            });
          }
          //message.success(t("media-push-created-success"));
          if (isConversation) {
            navigate("/creator/conversation/" + conversationId);
          } else {
            navigate("/creator/message");
          }
          dispatch(setSelectFiles({ files: [], quickFiles: [] }));
        }
      });
    }
  };

  function isPlainObject(value: any) {
    if (value === null || typeof value !== "object") {
      return false;
    }
    return Object.getPrototypeOf(value) === Object.prototype;
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const key = e.key;

    if (e.ctrlKey || e.metaKey || key === "Backspace" || key === "Tab" || key === "ArrowLeft" || key === "ArrowRight" || key === "Delete") {
      return;
    }

    if (key < "0" || key > "9") {
      e.preventDefault();
    }
  };

  const removeSegmentationFromLS = () => {
    window.localStorage.removeItem("segmentation");
  };

  const handleNextStep = () => {
    if (Number(segmentations?.subscribers) === 0 && Number(segmentations?.oldFollowers) === 0 && Number(segmentations?.followers) === 0) {
      message.error(t("no-target-audience"));
      return;
    }

    if (mediapush?.segmentation?.includes("subscribers") && Number(segmentations?.subscribers) === 0) {
      message.warning(t("no-subscribers"));
      return;
    } else if (mediapush?.segmentation?.includes("followers") && Number(segmentations?.followers) === 0) {
      message.warning(t("no-interested-audience"));
      return;
    } else if (mediapush?.segmentation?.includes("oldFollowers") && Number(segmentations?.oldFollowers) === 0) {
      message.warning(t("no-former-subscribers"));
      return;
    }

    setCurrentStep(2);
  };

  const handleCheckboxChange = (values: any) => {
    const zeroValues = values.filter((value: any) => {
      if (value === "subscribers" && segmentations?.subscribers === 0) return true;
      if (value === "followers" && segmentations?.followers === 0) return true;
      if (value === "oldFollowers" && segmentations?.oldFollowers === 0) return true;
      return false;
    });

    if (zeroValues.length > 0) {
      zeroValues
        .map((value: any) => {
          if (value === "subscribers") return message.warning(t("no-subscribers"));
          if (value === "followers") return message.warning(t("no-interested-audience"));
          if (value === "oldFollowers") return message.warning(t("no-former-subscribers"));
          return "";
        })
        .join(", ");
      return;
    }

    setSelectedValues(values);
    changedItem("segmentation", values);
  };

  const handleRemoveFile = (fileId: string) => {
    setPrivateFiles((prev: any) => prev.filter((f: any) => f.fileId !== fileId));
    dispatch(setSelectFiles({ files: files.filter((f: any) => f.uid !== fileId), quickFiles: undefined }));
    setComponentKey((prevKey) => prevKey + 1);
    removeFileFromSelected(fileId);
  };

  // Function to remove a file from selected list in URL
  const removeFileFromSelected = (fileId: string) => {
    const params = new URLSearchParams(location.search);
    const selectedParam = params.get("selected");

    // Parse the 'selected' parameter as an array of file IDs
    let selectedFiles: string[] = selectedParam ? JSON.parse(selectedParam) : [];

    // Filter out the fileId that needs to be removed
    selectedFiles = selectedFiles.filter((id) => id !== fileId);

    // Update the 'selected' parameter in the URL
    if (selectedFiles.length > 0) {
      params.set("selected", JSON.stringify(selectedFiles));
    } else {
      params.delete("selected"); // Remove the 'selected' parameter if no files are selected
    }

    // Update the URL without reloading the page
    navigate(
      {
        pathname: location.pathname,
        search: params.toString(),
      },
      { replace: true }
    );
  };

  return (
    <Spin
      spinning={loading}
      style={{ maxHeight: "100%" }}
      indicator={<SpinnerComponent progress={true} progressValue={progress} message={t("stay-on-page-during-uploading") as string} />}
    >
      <Layout>
        <div style={{ display: privateFiles.length > 0 ? "none" : "" }}>
          <Layout.Content className="content creatorMediaPushContentWrapper gradient-background">
            <Row className="relative justify-content-center header-title-container-60">
              {!isConversation && currentStep === 2 ? (
                <img className="header-arrow-left-media" style={{ top: 10 }} src={ArrowLeftIcon} alt="arrow left" onClick={() => setCurrentStep(1)} />
              ) : isConversation && currentStep === 2 ? (
                <ArrowLeftIconComponent left={-2} top={10} navigateTo={`/creator/conversation/${conversationId}`} />
              ) : (
                <ArrowLeftIconComponent left={-2} top={10} navigateTo="/creator/profile" callback={removeSegmentationFromLS} />
              )}
              <Typography.Text className="header-title-black-35">{isConversation ? t("private-media") : t("media-push")}</Typography.Text>
            </Row>
            <Space className="w-full gap-21" direction="vertical">
              {currentStep === 1 && !isConversation && (
                <Row className="center-container">
                  <Space className="stepOneDesign gap-0 mt-10" direction="vertical">
                    <Row className="w-full justify-content-center mb-40">
                      <Typography.Text className="font-20-bold text-white-color">{t("select-your-segments")}</Typography.Text>
                    </Row>
                    <Checkbox.Group className="w-full" onChange={handleCheckboxChange} value={selectedValues}>
                      <Col className="w-full">
                        <Row className="mt-7">
                          <Checkbox value={"subscribers"} className="segmentationCheckBox" id="subscribersCheckBox" />
                          <label htmlFor="subscribersCheckBox">
                            <Row className="w-full justify-content-between items-center">
                              <span
                                className={
                                  mediapush.segmentation?.includes("subscribers") ? "text-black-color font-16-bold" : "text-white-color font-16-bold"
                                }
                              >
                                {t("subscribers")}
                              </span>
                              <Tag className="segmentationTag">{segmentations?.subscribers}</Tag>
                            </Row>
                          </label>
                        </Row>
                        <Row className="mt-7">
                          <Checkbox value={"followers"} className="segmentationCheckBox" id="followersCheckBox" />
                          <label htmlFor="followersCheckBox">
                            <Row className="w-full justify-content-between items-center">
                              <span
                                className={
                                  !mediapush.segmentation?.includes("followers") ? "text-white-color font-16-bold" : "text-black-color font-16-bold"
                                }
                              >
                                {t("interested")}
                              </span>
                              <Tag className="segmentationTag">{segmentations?.followers}</Tag>
                            </Row>
                          </label>
                        </Row>
                        <Row className="mt-7">
                          <Checkbox value={"oldFollowers"} className="segmentationCheckBox " id="oldSubscribersCheckBox" />
                          <label htmlFor="oldSubscribersCheckBox">
                            <Row className="w-full justify-content-between items-center">
                              <span
                                className={
                                  !mediapush.segmentation?.includes("oldFollowers")
                                    ? "text-white-color font-16-bold"
                                    : "text-black-color font-16-bold"
                                }
                              >
                                {t("former-subscriber")}
                              </span>
                              <Tag className="segmentationTag">{segmentations?.oldFollowers}</Tag>
                            </Row>
                          </label>
                        </Row>
                      </Col>
                    </Checkbox.Group>
                    <Row className="mt-50 button-width">
                      <AuthButtonComponent
                        height="70px"
                        fontSize="24px"
                        label={t("create")}
                        onClick={() => handleNextStep()}
                        disabled={mediapush?.segmentation?.length === 0 || mediapush?.segmentation?.length === undefined}
                      />
                    </Row>
                  </Space>
                </Row>
              )}
              {currentStep === 2 && (
                <>
                  <Row>
                    <Row className="w-full justify-content-center mt-20 mb-20">
                      <Typography.Text className="font-13-regular text-grey-color mb-3">{t("send-file-description")}</Typography.Text>
                    </Row>
                    <MediaPushMediaComponent
                      key={componentKey}
                      maxCount={MAX_MEDIAS_TO_SEND}
                      onFilesChange={handleFilesChange}
                      defaultList={files}
                      conversationId={conversationId || ""}
                      messageId={messageId || ""}
                      receiverId={receiverId || ""}
                      allFiles={files}
                      quickFiles={quickFiles}
                    />
                  </Row>
                  <Row>
                    <MessageTextAreaComponent
                      value={mediapush.text}
                      handleChange={(value) => {
                        changedItem("text", value.target.value);
                      }}
                    />
                  </Row>
                  <Row>
                    <Space className="w-full gap-10" direction="vertical">
                      <PriceInputComponent customPrice={customPrice} handleCustomPriceChange={handleCustomPriceChange} />
                      <Radio.Group className="w-full mt-10" value={radioValue} onChange={handleRadioChange}>
                        <Row gutter={10}>
                          {mediaAmounts.map((amount, index) => {
                            return (
                              <Col className="mt-3" key={index} span={6}>
                                <Radio.Button className="mediaAmountRadioButton mediaPush" value={amount.value}>
                                  {amount.label}
                                </Radio.Button>
                              </Col>
                            );
                          })}
                        </Row>
                      </Radio.Group>
                    </Space>
                  </Row>
                  <Row className="justify-content-between" align="middle">
                    <Typography.Text className="font-20-bold text-white-color">{t("add-a-promotion")}</Typography.Text>
                    <Switch
                      disabled={mediapush?.price === undefined || mediapush?.price <= 0 || Number.isNaN(mediapush.price)}
                      className="switch mediaPushSwitch"
                      defaultChecked={promoCodeChecked}
                      checked={promoCodeChecked}
                      onChange={(checked) => {
                        setPromoCodeChecked(checked);
                        changedItem("promo", checked);
                        if (!checked) {
                          delete mediapush.discount;
                          delete mediapush.discountType;
                          delete mediapush.expireTimeType;
                          setPromoCurrency(false);
                          setPromoPercentage(false);
                          setDiscountCurrency(undefined);
                          setDiscountPercentage(undefined);
                        }
                      }}
                    />
                  </Row>
                  {promoCodeChecked && (
                    <Row className="justify-content-between mt-16">
                      <Space className="w-full gap-10" direction="vertical">
                        <Typography.Text className="font-30-bold text-white-color">{t("discount")}</Typography.Text>
                        <Row>
                          <Col span={8}>
                            <InputComponent
                              className={`${promoCurrency ? "mediaPushMediumGreenInput" : "mediaPushDarkInput"}`}
                              suffix="€"
                              onChange={handleDiscountCurrencyChange}
                              onKeyDown={handleKeyDown}
                              value={discountCurrency}
                              inputMode="numeric"
                            />
                          </Col>
                          <Col className="d-flex items-center justify-content-center" span={8}>
                            <Typography.Text className="font-13-bold text-grey-color">{t("auth-signUp-divider")}</Typography.Text>
                          </Col>
                          <Col span={8}>
                            <InputComponent
                              className={`${promoPercentage ? "mediaPushMediumGreenInput" : "mediaPushDarkInput"}`}
                              suffix="%"
                              onChange={handleDiscountPercentageChange}
                              onKeyDown={handleKeyDown}
                              value={discountPercentage}
                              inputMode="numeric"
                            />
                          </Col>
                        </Row>
                      </Space>
                    </Row>
                  )}
                  {promoCodeChecked && (
                    <Row className="justify-content-between mt-16">
                      <Space className="w-full gap-10" direction="vertical">
                        <Typography.Text className="font-30-bold text-white-color">{t("duration-of-validity")}</Typography.Text>
                        <Radio.Group
                          className="w-full"
                          //defaultValue={discountExpirePeriods[0].value}
                          value={discountExpirePeriods[selectedTime]?.value}
                          onChange={(e) => {
                            handleExpireTimeChange(e.target.value.value, e.target.value.timeType);
                          }}
                        >
                          <Row gutter={15}>
                            {discountExpirePeriods.map((period, index) => {
                              return (
                                <Col className="mt-3" key={index} span={8}>
                                  <Radio.Button
                                    className="mediaPushAmountPromoButton"
                                    value={period.value}
                                    onClick={() => {
                                      setSelectedTime(index);
                                    }}
                                  >
                                    {period.label}
                                  </Radio.Button>
                                </Col>
                              );
                            })}
                          </Row>
                        </Radio.Group>
                      </Space>
                    </Row>
                  )}
                  <Row className="mt-10">
                    <AuthButtonComponent
                      label={t("propose-private-media")}
                      onClick={handleCreateMediapush}
                      disabled={
                        (promoCodeChecked && selectedTime === undefined) ||
                        (promoCodeChecked &&
                          (discountCurrency === undefined || discountCurrency === null || discountCurrency === "") &&
                          (discountPercentage === undefined || discountPercentage === null || discountPercentage === ""))
                      }
                    />
                  </Row>
                </>
              )}
            </Space>
            <MediaPushLimitReachedModalComponent isOpen={openModal} onClose={() => setOpenModal(false)} />
          </Layout.Content>
        </div>
        {privateFiles.length > 0 &&
          privateFiles.map((privateFile) => (
            <Layout.Content key={privateFile.fileId} className="content creatorMediaPushContentWrapper gradient-background">
              <Row className="relative justify-content-center header-title-container-60">
                <img
                  className="header-arrow-left-media"
                  style={{ top: 10 }}
                  src={ArrowLeftIcon}
                  alt="arrow left"
                  onClick={() => handleRemoveFile(privateFile.fileId)}
                />
                <Typography.Text className="header-title-black-32">{isConversation ? t("private-media") : t("media-push")}</Typography.Text>
              </Row>
              {/* {privateFile.fileType.includes("video") ? (
                <video controls playsInline style={{ width: "100%", marginTop: "20px" }}>
                  <source src={privateFile.fileSrc} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              ) : ( */}
              <Row className="w-full justify-content-center">
                <img className="images-design" src={privateFile.fileSrc} alt="Loly" />
              </Row>
              {/* )} */}
              <Space className="w-full gap-6" direction="vertical">
                <Row className="blur-media-row">
                  <Row className="w-full justify-content-between">
                    <Typography.Text className="font-24-bold text-white-color">{t("want-blur-media")}</Typography.Text>
                    <Switch className="switch revenueSwitch" defaultChecked={true} onChange={(value) => handleLockSwitchChange(value, privateFile)} />
                  </Row>
                  <Row className="w-full padding-y justify-content-center mt-40">
                    <AuthButtonComponent height="65px" fontSize="20px" label={t("next")} onClick={() => onNextClick(privateFile)} />
                  </Row>
                </Row>
              </Space>
            </Layout.Content>
          ))}
      </Layout>
    </Spin>
  );
};

export default CreatorMediaPushPage;
