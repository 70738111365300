import { Checkbox, Col, InputRef, Layout, Row, Space, Spin, Typography, message } from "antd";
import { FC, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { FAN } from "../../helpers/constant";
import { IUser } from "../../helpers/types";
import { authService, userService } from "../../services";
import { capitalizeFirstLetter } from "../../utls/FunctionsUtil";
import AuthButtonComponent from "../AuthButtonCompnent";
import AuthGoogleButtonComponent from "../AuthGoogleButtonComponent";
import AuthInputComponent from "../AuthInputComponent";
import AuthOrDividerComponent from "../AuthOrDividerComponent";
import AuthSelectLanguageComponent from "../AuthSelectLanguageComponent";
import InputComponent from "../InputComponent";
import SpinnerComponent from "../SpinnerComponent/SpinnerComponent";
import { useNavigate } from "react-router-dom";
import "./index.less";

type AuthSignUpFanTabProps = {
  googleUnavailable: () => void;
};

const AuthSignUpFanTabComponent: React.FC<AuthSignUpFanTabProps> = ({ googleUnavailable }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [data, setData] = useState<IUser | null>(null);
  const [user, setUser] = useState<any>(null);
  const [acceptAuthTerms, setAcceptAuthTerms] = useState<boolean>(true);
  const [loading, setLoading] = useState(false);
  const [emailError, setEmailError] = useState<boolean>(false);

  const register = () => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    const isEmail = emailRegex.test(data?.email || "");
    if (!data) return message.warning(t("empty-form-error-message"));
    if (!data?.firstName) return message.warning(t("first-name-required"));
    if (!data?.lastName) return message.warning(t("last-name-required"));
    if (!data?.username || data?.username.length < 3) return message.warning(t("username-required"));
    if (!data?.email || !isEmail) {
      setEmailError(true);
      return message.warning(t("invalid-email"));
    }
    if (!data?.password || data?.password?.length < 6) return message.warning(t("password-required"));
    if (!acceptAuthTerms) return message.warning(t("terms-conditions-error-message"));

    authService
      .register({
        ...data,
        firstName: capitalizeFirstLetter(data.firstName),
        lastName: capitalizeFirstLetter(data.lastName),
        username: data.username?.toLocaleLowerCase(),
        type: FAN,
        lang: localStorage.getItem("lng"),
      })
      .then(async () => {
        window.location.reload();
        // message.success(t("register-success-message"), 10);
        // navigate("/auth/signIn");
      })
      .catch((error) => message.error(t(error)));
  };

  const googleRegister = (accessToken: string) => {
    setLoading(true);
    authService
      .googleSignUp(accessToken, FAN)
      .then(async (res: any) => {
        const userData = { ...res.data, username: "" };
        authService.setToken(res.token);
        setLoading(false);
        navigate("/auth/signUp/google", { state: { userData } });
      })
      .catch((error) => {
        setLoading(false);
        message.error(error);
      });
  };

  const handleChange = (e: { target: { name: any; value: any } }) => {
    let filedValue = e.target.value;
    if (e.target.name === "username") {
      filedValue = filedValue.replace(/\s+/g, "");
    }
    if (e.target.name === "email") {
      setEmailError(false);
    }
    setData({ ...data, [e.target.name]: filedValue });
  };

  return (
    <Spin spinning={loading} style={{ maxHeight: "100%" }} indicator={<SpinnerComponent />}>
      <Layout className="background-transparent">
        <Layout.Content className="authSignupContentWrapper">
          <Row className="signUpContainer">
            <Col className="w-full">
              <Row className="mt-5">
                <AuthGoogleButtonComponent onLoginSuccess={googleRegister} googleUnavailable={googleUnavailable} />
              </Row>
              <Row className="mt-5">
                <AuthOrDividerComponent text={t("auth-signUp-divider")} />
              </Row>
              <Row gutter={11}>
                <Col span={12}>
                  <AuthInputComponent
                    label={t("first-name-label")}
                    placeholder={t("first-name-label")!}
                    type="text"
                    name="firstName"
                    value={data?.firstName || ""}
                    handleChange={handleChange}
                  />
                </Col>
                <Col span={12}>
                  <AuthInputComponent
                    label={t("last-name-label")}
                    placeholder={t("last-name-label")!}
                    type="text"
                    name="lastName"
                    value={data?.lastName || ""}
                    handleChange={handleChange}
                  />
                </Col>
              </Row>
              <Row className="mt-16">
                <AuthInputComponent
                  label={t("user-name-label")}
                  placeholder={t("user-name-label")!}
                  type="text"
                  name="username"
                  value={data?.username?.toLocaleLowerCase() || ""}
                  handleChange={handleChange}
                />
              </Row>
              <Row className="mt-16">
                <AuthInputComponent
                  label={t("email-label")}
                  placeholder={t("email-label")!}
                  type="email"
                  name="email"
                  value={data?.email || ""}
                  handleChange={handleChange}
                  inputError={emailError}
                />
              </Row>
              <Row className="mt-16">
                <Space className="w-full gap-6" direction="vertical">
                  <Typography.Text className="authInputLabel">{t("password-label")}</Typography.Text>
                  <InputComponent
                    className="custom-input-signup"
                    placeholder={t("password-label")!}
                    name="password"
                    onChange={handleChange}
                    value={data?.password || ""}
                    type="password"
                  />
                </Space>
              </Row>
              <Row>
                <Checkbox className="mt-10 authTermsCheckBox" checked={acceptAuthTerms} onChange={(e) => setAcceptAuthTerms(e.target.checked)}>
                  <span>{t("auth-terms-1")}</span>
                  <span className="text-color-green">{t("auth-terms-2")}</span>
                  <span>{t("auth-terms-3")}</span>
                  <a href="https://loly.app/conditions-generales-de-vente/" target="_blank">
                    {t("terms-and-conditions")}
                  </a>
                  {t("and")}
                  <a href="https://loly.app/confidentialite-et-protection-des-donnees/" target="_blank">
                    {t("privacy-policy")}
                  </a>
                </Checkbox>
              </Row>
              <Row className="mt-25">
                <AuthButtonComponent label={t("auth-signUp-button")} onClick={register} />
              </Row>
            </Col>
          </Row>

          <Row className="w-full authRowFooter mt-20">
            <AuthSelectLanguageComponent />
          </Row>
        </Layout.Content>
      </Layout>
    </Spin>
  );
};

export default AuthSignUpFanTabComponent;
