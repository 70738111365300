import { Typography } from "antd";
import { useTranslation } from "react-i18next";
import GoogleAuthInstaImg from "../../assets/images/google-auth-insta.webp";
import "./index.less";

type ExplanationProps = {
  cancel: () => void;
  fromPublic?: boolean;
};

const ExplanationComponent: React.FC<ExplanationProps> = ({ cancel, fromPublic = false }) => {
  const { t } = useTranslation();

  return (
    <>
      <div className={`auth-google-insta-img-description-container ${fromPublic ? "public-design" : ""}`}>
        <img src={GoogleAuthInstaImg} className="google-auth-insta-img" alt="auth" />
      </div>
      <div className={`auth-google-insta-content-description-container ${fromPublic ? "public-design" : ""}`}>
        <Typography.Text className="description-text">{t("auth-register-google-desc-1")}</Typography.Text>
        <Typography.Text className="description-text">{t("auth-register-google-desc-2")}</Typography.Text>
        <Typography.Text className="description-text">{t("auth-register-google-desc-3")}</Typography.Text>
      </div>

      <div className={`auth-google-insta-cancel-container ${fromPublic ? "public-design" : ""}`}>
        <Typography.Text className="cancel-text ">
          <span className="text-underline" onClick={cancel}>
            {t("click-here")}
          </span>{" "}
          {t("auth-register-google-cancel")}
        </Typography.Text>
      </div>
    </>
  );
};

export default ExplanationComponent;
