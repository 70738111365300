import { Alert, Layout, Row, Space, Tag, Typography } from "antd";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import CloseIcon from "../../../assets/icons/close-with-shadow.svg";
import MicrophoneIcon from "../../../assets/icons/microphone.svg";
import MuteMicrophoneIcon from "../../../assets/icons/mute-microphone.svg";
import VerifyIcon from "../../../assets/icons/verify.svg";
import UserIcon from "../../../assets/icons/user-white.svg";
import ChallengeLiveIcon from "../../../assets/icons/challenge-live.svg";
import LiveCommentsComponent from "../../../components/LiveCommentsComponent";
import LivePublisherComponent from "../../../components/LivePublisherComponent";
import LiveTipsComponent from "../../../components/LiveTipsComponent/LiveTipsComponent";
import AgoraProvider from "../../../helpers/agoraProvider";
import {
  LIVE_TYPES,
  MIN_STREAMING_SCHEDULED_LIVE_TIME_BEFORE_ENDING,
  MIN_STREAMING_TIME_BEFORE_UPDATE,
  UPDATE_STREAMING_TIME_EVERY_20_SECONDS,
  USER_TYPES,
} from "../../../helpers/constant";
import { clearLiveData, editLive, getLiveById } from "../../../redux/lives/livesSlice";
import { AppDispatch, StoreState } from "../../../redux/store";
import { socket } from "../../../socket";
import liveUtil from "../../../utls/LiveUtil";
import TipsImg from "../../../assets/images/tips.webp";
import { ConfirmationModal } from "../../../components/ModalConfirmation";
import { updateStreamStats, addLiveComment, newLiveTip, clearStreamStats } from "../../../redux/lives/liveDataSlice";
import { liveService } from "../../../services";
import AvatarComponent from "../../../components/AvatarComponent";
import { formatNumber } from "../../../utls/FunctionsUtil";
import CreateChallengeLiveModalComponent from "../../../components/ChallengeLive/Creator/CreateChallengeLiveModalComponent";
import "./index.less";
import LiveTipsLeaderboardComponent from "../../../components/LiveTipsLeaderboardComponent";
import ChallengeProgressionBarComponent from "../../../components/ChallengeLive/ChallengeProgressionBarComponent";

const CreatorLivePublishPage = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const publisherRef = useRef<any>(null);
  const isStartedRef = useRef<boolean>(false);
  const streamingTimeRef = useRef<number>();
  const streamingRestTimeRef = useRef<number>();
  const dispatch = useDispatch<AppDispatch>();
  const live = useSelector((state: StoreState) => state.lives.item);
  //const token = useSelector((state: StoreState) => state.lives.token);
  const [token, setToken] = useState("");
  const liveComments = useSelector((state: StoreState) => state.liveStreamStats.comments.list);
  const streamStats = useSelector((state: StoreState) => state.liveStreamStats.streamStats.stats);
  const [streamingTime, setStreamingTime] = useState<number | null>(null);
  const [streamingRestTime, setStreamingRestTime] = useState<number | null>(null);
  const [showStreamingRestTime, setShowStreamingRestTime] = useState<boolean>(false);
  const [isAudioMuted, setIsAudioMuted] = useState<boolean>(false);
  const [confirmCloseModal, setConfirmCloseModal] = useState<boolean>(false);
  const [shouldRefundFans, setShouldRefundFans] = useState<boolean>(false);
  const [challengeLiveModalVisible, setChallengeLiveModalVisible] = useState<boolean>(false);

  useEffect(() => {
    if (!id) return;
    if (!live || live._id !== id) dispatch(getLiveById(id));
  }, [id]);

  useEffect(() => {
    if (!isStartedRef.current && live && live.sessionId) {
      const getToken = async (sessionId: string) => {
        await liveService.buildToken(sessionId).then((responseToken) => {
          if (responseToken && typeof responseToken === "string") {
            setToken(responseToken);
          }
        });
      };
      if (live && live?.streamingTime && live.streamingTime > 0) {
        setStreamingTime(live.streamingTime);
        handleRestTimeToEndLive(live.streamingTime);
      }
      getToken(live.sessionId);
    }
  }, [live]);

  useEffect(() => {
    if (token) join();
  }, [token]);

  const join = async () => {
    if (!live || !token) return;
    publisherRef.current && (await publisherRef.current.publish());
    //socket.emit("public-stream/join", { id: live._id });
  };

  const leave = async () => {
    publisherRef.current && (await publisherRef.current.leave());
    socket.emit("public-stream/leave", {
      id: live?._id,
      streamingTime: streamingTimeRef.current,
      role: USER_TYPES.CREATOR,
    });
  };

  const streamingStatusChanged = (started: boolean) => {
    if (started) {
      isStartedRef.current = true;
    }
  };

  const handleDuration = (time: number) => {
    streamingTimeRef.current = time;
    setStreamingTime(time);
    handleRestTimeToEndLive(time);
    handleUpdateStreamingTime(time);
  };

  const handleUpdateStreamingTime = (time: number) => {
    if (!id && streamingTime && streamingTime < MIN_STREAMING_TIME_BEFORE_UPDATE) return;
    if (time % MIN_STREAMING_TIME_BEFORE_UPDATE === 0) {
      socket.emit("public-stream/update-streamingTime", { id, streamingTime: time });
    }
  };
  const handleRestTimeToEndLive = (time: number) => {
    if (!live || !live.dailyRestQuotaTime || live.dailyRestQuotaTime === -1) return;
    const restTime = live.dailyRestQuotaTime - time;
    if (restTime === 0) {
      setShowStreamingRestTime(false);
      handleConfirmeEndLive();
      return;
    }
    const shouldShowRestTime = (restTime >= 290 && restTime <= 300) || (restTime >= 110 && restTime <= 120) || (restTime >= 1 && restTime <= 10);
    setStreamingRestTime(restTime);
    setShowStreamingRestTime(shouldShowRestTime);
  };

  const userJoined = async (data: any) => {
    if (data.streamStats) await dispatch(updateStreamStats(data.streamStats));
    if (data.comments) await dispatch(addLiveComment(data.comments));
    if (data.lastStreamTip) await dispatch(newLiveTip(data.lastStreamTip));
  };
  const addedComment = async (data: any) => {
    await dispatch(addLiveComment(data.comments));
  };

  const tipUpdated = async (data: any) => {
    if (data?.tipData) {
      await dispatch(newLiveTip(data.tipData));
    }
    if (data?.totalPrice) {
      await dispatch(editLive({ ...live, tipPrice: data.totalPrice, currentChallenge: data.currentChallenge, top3Tips: data.top3Tips }));
    }
  };

  const userLeaved = async (data: any) => {
    if (!live || !live._id) return;
    if (data.role === "fan" && data.streamStats && data.streamId === live?._id) {
      console.log("user leaving live id ", live._id, " leaving live id ", data.streamId);
      await dispatch(updateStreamStats(data.streamStats));
    }
  };

  const onClickMute = async () => {
    if (publisherRef.current) {
      const muted = await publisherRef.current.mute();
      setIsAudioMuted(muted);
    }
  };

  const onClickUnmute = async () => {
    if (publisherRef.current) {
      const muted = await publisherRef.current.unmute();
      setIsAudioMuted(muted);
    }
  };

  const handleEndLive = () => {
    if (!live) return;
    if (
      live?.amount !== 0 &&
      live.startType === LIVE_TYPES.SCHEDULE &&
      streamingTime &&
      streamingTime < MIN_STREAMING_SCHEDULED_LIVE_TIME_BEFORE_ENDING
    ) {
      setShouldRefundFans(true);
    } else {
      setShouldRefundFans(false);
    }
    setConfirmCloseModal(!confirmCloseModal);
  };

  const handleConfirmeEndLive = () => {
    leave();
    dispatch(clearLiveData());
    dispatch(clearStreamStats());
    navigate("/creator/profile");
  };

  const handleCancelEndLive = () => {
    setConfirmCloseModal(!confirmCloseModal);
  };

  useEffect(() => {
    socket.on("user_joined", userJoined);
    socket.on("comment_added", addedComment);
    socket.on("tip_updated", tipUpdated);
    socket.on("user_leaved", userLeaved);

    return () => {
      socket.off("user_joined", userJoined);
      socket.off("comment_added", addedComment);
      socket.off("tip_updated", tipUpdated);
      socket.off("user_leaved", userLeaved);
    };
  }, []);

  useEffect(() => {
    return () => {
      if (isStartedRef.current) {
        leave();
      }
    };
  }, []);

  const renderModalBody = () => {
    if (shouldRefundFans) {
      return (
        <>
          <p>{t("modal-end-live-msg")}</p>
          <span>{t("modal-end-live-refund-msg")}</span>
        </>
      );
    }
    return <p className="font-28-black">{t("modal-end-live-msg")}</p>;
  };

  const handleChallengeLive = () => {
    setChallengeLiveModalVisible(true);
  };

  const closeChallengeLiveModal = () => {
    setChallengeLiveModalVisible(false);
  };

  return (
    <AgoraProvider config={{ mode: "live", codec: "h264", role: "host" }}>
      <Layout>
        <Layout.Content className="content creatorLivePublishPage">
          <LivePublisherComponent
            streamingTime={streamingTime}
            onStatusChanged={streamingStatusChanged}
            handleDuration={handleDuration}
            token={token}
            ref={publisherRef}
          />
          <Row className="relative z-fan-live">
            <Space className="w-full" direction="vertical">
              {showStreamingRestTime && (
                <Row className="live-ends">
                  <Alert
                    className="alert-end-live"
                    message={`${t("live-ends-in")} : ${streamingRestTime && liveUtil.getDurationString(streamingRestTime)}`}
                    type="error"
                  />
                </Row>
              )}
              <Row className="justify-content-between">
                <Space className="header-container-left-live-creator gap-3" align="center">
                  <AvatarComponent image={live?.creator?.avatar} size={25} />
                  <Typography.Text className="fullname">{live?.creator?.username}</Typography.Text>
                  {live?.creator?.verifiedEmail && <img src={VerifyIcon} alt="verify" />}
                  {streamingTime !== null && (
                    <Typography.Text className="ml-5 font-12-regular text-white-color">
                      {streamingTime && liveUtil.getDurationString(streamingTime)}
                    </Typography.Text>
                  )}
                </Space>
                <Space className="gap-13">
                  {live && streamStats && (
                    <div className="spectateursTag">
                      <img src={UserIcon} width={10} className="mr-5" />
                      <Typography.Text className="font-12-bold text-white-color">{formatNumber(streamStats.members)}</Typography.Text>
                    </div>
                  )}
                  <img src={ChallengeLiveIcon} width={24} className="mr-5 ml-5" alt="challenge" onClick={handleChallengeLive} />
                  <img src={CloseIcon} width={22} alt="close" onClick={handleEndLive} className="ml-10" />
                </Space>
              </Row>
            </Space>
            <div className="mt-10 w-full">
              {live?.currentChallenge && <ChallengeProgressionBarComponent challenge={live?.currentChallenge} isCreator={true} />}
            </div>
          </Row>

          <Row className="relative z-fan-live footer-shadow">
            <Space className="w-full gap-18" direction="vertical">
              {live?.top3Tips && <LiveTipsLeaderboardComponent topTips={live?.top3Tips} />}
              <Row>
                <LiveCommentsComponent comments={liveComments} topTips={live?.top3Tips} />
              </Row>
              <Row className="tips-micro-row">
                {/* <Tag className="tipTag">Tips : {live?.tipPrice ? `${live?.tipPrice} €` : "0 €"}</Tag> */}
                <div className="tips-row">
                  <div className="tips-container mr-20">
                    <img src={TipsImg} width={24} className="mr-10" />
                    <Typography.Text className="font-18-black text-white-color">{live?.tipPrice ? `${live?.tipPrice} €` : "0 €"}</Typography.Text>
                  </div>
                  <LiveTipsComponent />
                </div>

                {isAudioMuted ? (
                  <img src={MuteMicrophoneIcon} alt="microphone" onClick={onClickUnmute} />
                ) : (
                  <img src={MicrophoneIcon} alt="microphone" onClick={onClickMute} />
                )}
              </Row>
            </Space>
          </Row>
        </Layout.Content>
        <ConfirmationModal
          handleConfirm={handleConfirmeEndLive}
          handleToggle={handleCancelEndLive}
          open={confirmCloseModal}
          confBtnMsg={t("modal-confirme-end-live-btn")}
          title={t("modal-end-live-msg")!}
          subTitle={shouldRefundFans ? t("modal-end-live-refund-msg")! : ""}
        />
        <CreateChallengeLiveModalComponent streamId={live?._id} visible={challengeLiveModalVisible} onClose={closeChallengeLiveModal} />
      </Layout>
    </AgoraProvider>
  );
};

export default CreatorLivePublishPage;
