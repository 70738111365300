import { message, Row, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import SpinnerComponent from "../SpinnerComponent/SpinnerComponent";
import { userService } from "../../services";
import { IDetailedRevenueCategory } from "../../helpers/types";
import "./index.less";

const revenueColors: { [key: string]: string } = {
  subscription: "#FF5733",
  privateMediaPush: "#33FF57",
  publicMediaPush: "#3357FF",
  decrypt: "#FF33A1",
  collection: "#FFD433",
  stream: "#33FFF5",
  streamTips: "#8A33FF",
};

interface CreatorRevenueDetailedProps {
  fromDate: string;
  toDate: string;
  monthLabel: string | null;
  year: number | null;
  visible: boolean;
  onClose: () => void;
}

const CreatorRevenueDetailedComponent: React.FC<CreatorRevenueDetailedProps> = ({ visible, onClose, fromDate, toDate, monthLabel, year }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [detailedRevenue, setDetailedRevenue] = useState<IDetailedRevenueCategory>();

  useEffect(() => {
    if (fromDate && toDate && visible) {
      setIsLoading(true);
      userService
        .getDetailedRevenueCategory(fromDate, toDate)
        .then((data: any) => {
          setIsLoading(false);
          setDetailedRevenue(data);
        })
        .catch(() => {
          setIsLoading(false);
          message.error(t("something-want-wrong"));
        });
    }
  }, [fromDate, toDate]);

  if (!visible) return null;

  const handleOverlayClick = () => {
    onClose();
  };

  const handleModalClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
  };

  return (
    <div className="revenue-modal-overlay" onClick={handleOverlayClick}>
      <div className="revenue-modal-content" onClick={handleModalClick}>
        <Row className="w-full justify-content-center mt-10">
          <div className="header-icon-grey" onClick={handleOverlayClick}></div>
        </Row>

        <div className={`modal-content ${isLoading ? "center-loader" : ""}`}>
          {isLoading ? (
            <SpinnerComponent />
          ) : (
            <div className="detailed-revenue-wrapper">
              <div className="total-earning-container">
                <Typography.Text className="date-label">{monthLabel + " " + year}</Typography.Text>
                <Typography.Text className="price-label">{detailedRevenue?.totalEarning} €</Typography.Text>
              </div>

              <div className="detailed-revenue-chart-container">
                <div className="detailed-revenue-chart">
                  {detailedRevenue &&
                    Object.keys(revenueColors).map((key) => {
                      const value = detailedRevenue[key as keyof IDetailedRevenueCategory];
                      if (value && value > 0) {
                        const percentage = (value / detailedRevenue.totalEarning) * 100;
                        return (
                          <div
                            key={key}
                            className="revenue-chart-segment"
                            style={{
                              width: `${percentage}%`,
                              backgroundColor: revenueColors[key],
                            }}
                          ></div>
                        );
                      }
                      return null;
                    })}
                </div>
              </div>

              <div className="detailed-revenue-container">
                {/* Abonnement */}
                <div className="detailed-revenue-row">
                  <div className="circle-label-container">
                    <div className="detailed-revenue-color" style={{ backgroundColor: revenueColors.subscription }}></div>
                    <Typography.Text className="detailed-revenue-label">Abonnements</Typography.Text>
                  </div>
                  <Typography.Text className="detailed-revenue-price">{detailedRevenue?.subscription} €</Typography.Text>
                </div>

                {/* Média privé */}
                <div className="detailed-revenue-row">
                  <div className="circle-label-container">
                    <div className="detailed-revenue-color" style={{ backgroundColor: revenueColors.privateMediaPush }}></div>
                    <Typography.Text className="detailed-revenue-label">Média privé</Typography.Text>
                  </div>
                  <Typography.Text className="detailed-revenue-price">{detailedRevenue?.privateMediaPush} €</Typography.Text>
                </div>

                {/* Média push */}
                <div className="detailed-revenue-row">
                  <div className="circle-label-container">
                    <div className="detailed-revenue-color" style={{ backgroundColor: revenueColors.publicMediaPush }}></div>
                    <Typography.Text className="detailed-revenue-label">Média push</Typography.Text>
                  </div>
                  <Typography.Text className="detailed-revenue-price">{detailedRevenue?.publicMediaPush} €</Typography.Text>
                </div>

                {/* Unlock */}
                <div className="detailed-revenue-row">
                  <div className="circle-label-container">
                    <div className="detailed-revenue-color" style={{ backgroundColor: revenueColors.decrypt }}></div>
                    <Typography.Text className="detailed-revenue-label">Unlock</Typography.Text>
                  </div>
                  <Typography.Text className="detailed-revenue-price">{detailedRevenue?.decrypt} €</Typography.Text>
                </div>

                {/* Collection */}
                <div className="detailed-revenue-row">
                  <div className="circle-label-container">
                    <div className="detailed-revenue-color" style={{ backgroundColor: revenueColors.collection }}></div>
                    <Typography.Text className="detailed-revenue-label">Collections</Typography.Text>
                  </div>
                  <Typography.Text className="detailed-revenue-price">{detailedRevenue?.collection} €</Typography.Text>
                </div>

                {/* Live */}
                <div className="detailed-revenue-row">
                  <div className="circle-label-container">
                    <div className="detailed-revenue-color" style={{ backgroundColor: revenueColors.stream }}></div>
                    <Typography.Text className="detailed-revenue-label">Live</Typography.Text>
                  </div>
                  <Typography.Text className="detailed-revenue-price">{detailedRevenue?.stream} €</Typography.Text>
                </div>

                {/* Don en live */}
                <div className="detailed-revenue-row">
                  <div className="circle-label-container">
                    <div className="detailed-revenue-color" style={{ backgroundColor: revenueColors.streamTips }}></div>
                    <Typography.Text className="detailed-revenue-label">Dons en live</Typography.Text>
                  </div>
                  <Typography.Text className="detailed-revenue-price">{detailedRevenue?.streamTips} €</Typography.Text>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CreatorRevenueDetailedComponent;
