import { Button, Col, Image, Layout, Modal, Row, Space, Typography, message } from "antd";
import dayjs from "dayjs";
import { SetStateAction, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import VideoIcon from "../../../assets/icons/jouer.svg";
import ArrowLeftIconComponent from "../../../components/ArrowLeftIconComponent";
import ShareModalComponent from "../../../components/ShareModalComponent";
import VideoPlayer from "../../../components/VideoPlayerComponent";
import { FALLBACK_IMAGE, THUMBNAIL_TYPES } from "../../../helpers/constant";
import { IDecryptDetailsDTO } from "../../../helpers/types";
import { decryptService } from "../../../services/decrypt.service";
import "./index.less";
import { copyToClipboardCustom } from "../../../utls/FunctionsUtil";
import ExpireUnlockLinkModalComponent from "../../../components/ExpireUnlockLinkModalComponent";

const CreatorDecryptDetailsPage = () => {
  const { t } = useTranslation();
  const { decryptId } = useParams();
  const shouldFetch = useRef(true);
  const [decryptDetails, setDecryptDetails] = useState<IDecryptDetailsDTO>({});
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isShareModalOpen, setIsShareModalOpen] = useState<boolean>(false);
  const [isExpired, setIsExpired] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);
  const [title, setTitle] = useState("");

  useEffect(() => {
    if (decryptDetails.decrypt?.title) {
      setTitle(decryptDetails.decrypt.title);
    }
  }, [decryptDetails.decrypt?.title]);

  useEffect(() => {
    if (shouldFetch.current && decryptId !== undefined) {
      shouldFetch.current = false;
      decryptService.getDecryptDetails(decryptId).then((data) => {
        setDecryptDetails(data);
        if (dayjs(data.decrypt?.expiresOn).isBefore(dayjs()) || data.decrypt?.status === "inactive") {
          setIsExpired(true);
        }
      });
    }
  }, [decryptId]);

  const copyLink = async () => {
    const link = `${window.location.origin}/decrypt?token=${decryptDetails?.decrypt?.slug}`;
    await copyToClipboardCustom(link, t("something-want-wrong"), t("link-successfully-copied")!);
  };

  const shareLink = () => {
    setIsShareModalOpen(true);
  };

  const closeShareModal = () => {
    setIsShareModalOpen(false);
  };

  const expireLink = () => {
    if (decryptId !== undefined && !isExpired) {
      decryptService.expireLink(decryptId).then((data) => {
        setIsExpired(true);
      });
      setIsModalOpen(false);
    }
  };

  const openModal = () => {
    if (decryptId !== undefined && !isExpired) {
      setIsModalOpen(true);
    }
  };

  const onClose = () => {
    setIsModalOpen(false);
  };

  const handleTitleChange = (event: { target: { value: SetStateAction<string> } }) => {
    setTitle(event.target.value);
  };

  const saveTitle = () => {
    if (title.length < 2) {
      message.error(t("minimum-two-characters"));
      return;
    }
    decryptService.editTitle({ title }, decryptId!);
  };

  let expireIn: number = 0;
  let expireInLabel: string = "";
  if (dayjs(decryptDetails.decrypt?.expiresOn).diff(new Date(), "days") > 0) {
    expireIn = dayjs(decryptDetails.decrypt?.expiresOn).diff(new Date(), "days");
    expireInLabel = t("days");
  } else if (dayjs(decryptDetails.decrypt?.expiresOn).diff(new Date(), "hours") > 0) {
    expireIn = dayjs(decryptDetails.decrypt?.expiresOn).diff(new Date(), "hours");
    expireInLabel = t("hours");
  } else if (dayjs(decryptDetails.decrypt?.expiresOn).diff(new Date(), "seconds") > 0) {
    expireIn = dayjs(decryptDetails.decrypt?.expiresOn).diff(new Date(), "seconds");
    expireInLabel = t("seconds");
  }

  useEffect(() => {
    if (isModalOpen) {
      document.body.addEventListener("touchmove", preventTouchMove, { passive: false });
    } else {
      document.body.removeEventListener("touchmove", preventTouchMove);
    }

    return () => {
      document.body.removeEventListener("touchmove", preventTouchMove);
    };
  }, [isModalOpen]);

  const preventTouchMove = (e: Event) => {
    e.preventDefault();
  };

  return (
    <Layout>
      <Layout.Content className="content unlockLinkDetailsContentWrapper gradient-background-blue-settings">
        <Row className="relative justify-content-center header-title-container-60">
          <ArrowLeftIconComponent top={8} />
          <Typography.Text className="header-title-black-35">{t("details")}</Typography.Text>
        </Row>
        {/* <div className="center-content">
            <Input className="input-title" value={title} onChange={handleTitleChange} onBlur={saveTitle} maxLength={15} minLength={2} />
          </div> */}
        <Space className="w-full gap-21 mt-20" direction="vertical">
          <Col className="unlockLinkDetailsWrapper">
            <div className="firstRow">
              <Col className="imagePriceWrapper">
                {decryptDetails.decrypt?.files[0]?.url?.includes(".mp4") && (
                  <img src={VideoIcon} alt="Loly - video icon" className="unlockVideoIcon" onClick={() => setVisible(true)} />
                )}
                <Image
                  className="decrypt-img"
                  src={
                    decryptDetails.decrypt?.files[0]?.url?.includes(".mp4")
                      ? decryptDetails.decrypt?.files[0]?.thumbnails?.find((thumb: any) => thumb.type === THUMBNAIL_TYPES.CLEAR)?.absolutePath
                      : decryptDetails.decrypt?.files[0]?.url
                  }
                  fallback={FALLBACK_IMAGE}
                  preview={
                    !decryptDetails.decrypt?.files[0]?.url?.includes(".mp4")
                      ? {}
                      : {
                          destroyOnClose: true,
                          visible,
                          onVisibleChange: (value) => {
                            setVisible(value);
                          },
                          modalRender: () => (
                            <div className="video-preview-wrapper">
                              <VideoPlayer videoUrl={decryptDetails.decrypt?.files[0]?.url!} thumbnailUrl={""} watermarkText={""} autoPlay={true} />
                            </div>
                          ),
                        }
                  }
                />
              </Col>
              <Col className="detailWrapper">
                <Row className="detailTopRow">
                  <Typography.Text className="font-12-light text-white-color">
                    {isExpired ? (
                      <>{t("expired-link")}</>
                    ) : (
                      <>
                        {t("expire-in")} {expireIn} {expireInLabel}
                      </>
                    )}
                  </Typography.Text>
                </Row>
                <Row className="detailMiddleRow">
                  <Typography.Text className="font-30-bold text-white-color truncate">{title}</Typography.Text>
                </Row>
                <Row>
                  <Typography.Text className="mt-5 text-black-color unitPriceWrapper">
                    <span className="font-14-bold">
                      {t("price-per-unit")} : {decryptDetails.decrypt?.price!.toFixed(2)} €
                    </span>
                  </Typography.Text>
                </Row>
              </Col>
            </div>

            <Space size={"middle"} className="mt-20 actionBtnWrapper">
              <Button className={"whiteOutlinedButtonWithRoundedBorder w-full text-white-color"} size={"large"} onClick={copyLink}>
                {t("copy-link")}
              </Button>
              <Button className={"whiteOutlinedButtonWithRoundedBorder w-full text-white-color"} size={"large"} onClick={shareLink}>
                {t("share-link")}
              </Button>
            </Space>

            <Row className="justify-content-around mt-20 gap-14">
              <div className="statWrapper">
                <Typography.Text className="font-22-bold text-white-color">{decryptDetails.decrypt?.numberOfClicks}</Typography.Text>
                <Typography.Text className="font-12-light text-white-color">{t("cliques")}</Typography.Text>
              </div>
              <div className="statWrapper">
                <Typography.Text className="font-22-bold text-white-color">
                  {decryptDetails.totalEarnings !== undefined && decryptDetails.totalEarnings.toFixed(2)} €
                </Typography.Text>
                <Typography.Text className="font-12-light text-white-color">{t("revenue-generated")}</Typography.Text>
              </div>
              <div className="statWrapper">
                <Typography.Text className="font-22-bold text-white-color">{decryptDetails.decrypt?.numberOfUnlocks}</Typography.Text>
                <Typography.Text className="font-12-light text-white-color">{t("openings")}</Typography.Text>
              </div>
            </Row>

            <Row className="justify-content-center mt-30">
              <Button
                className={`w-full ${isExpired ? "redButton text-red-color" : "delete-access-button text-black-color"}`}
                size={"large"}
                onClick={openModal}
              >
                {isExpired ? <>{t("expired-link")}</> : <>{t("delete-access")}</>}
              </Button>
            </Row>

            <Row className="justify-content-start mt-30">
              <Typography.Text className="font-28-bold text-white-color">{t("transactions")}</Typography.Text>
            </Row>

            <Row className="justify-content-start mt-20">
              {decryptDetails?.previousReport?.map((transaction: any, key: number) => (
                <div className="transactionWrapper" key={key}>
                  <Typography.Text className="font-20-bold text-white-color">{dayjs(transaction.date).format("DD/MM/YY")}</Typography.Text>
                  {/* <span className={`statsBadge ${transaction.stats.includes("+") ? "" : "negative"}`}>{transaction.stats}</span> */}
                  <Typography.Text className="font-20-bold text-white-color">{transaction.total_amount.toFixed(2)} €</Typography.Text>
                </div>
              ))}
            </Row>
          </Col>
        </Space>
      </Layout.Content>
      <ExpireUnlockLinkModalComponent isOpen={isModalOpen} onClose={onClose} expireLink={expireLink} />
      <ShareModalComponent
        isOpen={isShareModalOpen}
        onClose={closeShareModal}
        link={`${window.location.origin}/decrypt?token=${decryptDetails?.decrypt?.slug}`}
      />
    </Layout>
  );
};

export default CreatorDecryptDetailsPage;
