import { Button, Modal, Row, Typography } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { deleteFeedById } from "../../redux/feeds/feedsSlice";
import { AppDispatch } from "../../redux/store";
import "./index.less";

type props = {
  isOpen: boolean;
  selectedFeed: string;
  onClose?: () => void;
};

const CreatorDeletePostModal: React.FC<props> = ({ isOpen, onClose, selectedFeed }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  if (!isOpen) return null;

  const handleOverlayClick = () => {
    if (onClose) {
      onClose();
    }
  };

  const handleModalClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
  };

  const handleFeedDelete = async () => {
    const res = await dispatch(deleteFeedById(selectedFeed));
    if (res) {
      navigate("/creator/profile");
    }
  };

  return (
    // <Modal
    //   className="deletePostModal"
    //   title={t("Do-you-really-want-delete-a-publication?")}
    //   open={isOpen}
    //   centered
    //   closable={false}
    //   onCancel={onClose}
    //   footer={
    //     <div className="w-full mt-20">
    //       <Row className="row">
    //         <Button type="link" className="actionBtn" onClick={handleFeedDelete}>
    //           <div className="confirm-btn-background">{t("Yes-delete")}</div>
    //         </Button>
    //       </Row>
    //       <Row className="row">
    //         <Button className="rejectBtn" type="link" onClick={onClose}>
    //           {t("cancel")}
    //         </Button>
    //       </Row>
    //     </div>
    //   }
    // />

    <div className="bottom-modal-overlay" onClick={handleOverlayClick}>
      <div className="bottom-modal-content" onClick={handleModalClick}>
        <Row className="w-full justify-content-center mt-10">
          <div className="header-icon-grey" onClick={handleOverlayClick}></div>
        </Row>
        <div className="bottom-modal-header">
          <Typography.Text className="bottom-modal-title f-26">{t("Do-you-really-want-delete-a-publication?")}</Typography.Text>
        </div>
        <div className="bottom-modal-content">
          <button className="bottom-modal-button" onClick={handleFeedDelete}>
            <Typography.Text className="bottom-modal-button-title">{t("Yes-delete")}</Typography.Text>
          </button>

          <button className="bottom-modal-cancel-button" onClick={onClose}>
            <Typography.Text className="bottom-modal-cancel-button-title">{t("cancel")}</Typography.Text>
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreatorDeletePostModal;
