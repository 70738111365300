import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Input, InputNumber, message, Row, Typography } from "antd";
import AuthButtonComponent from "../../../AuthButtonCompnent";
import { useDispatch, useSelector } from "react-redux";
import { createStreamChallenge, deleteChallenge } from "../../../../redux/lives/livesSlice";
import { AppDispatch, StoreState } from "../../../../redux/store";
import "./index.less";

interface CreateChallengeLiveModalProps {
  visible: boolean;
  onClose: () => void;
  streamId: string | undefined;
}

const CreateChallengeLiveModalComponent: React.FC<CreateChallengeLiveModalProps> = ({ visible, onClose, streamId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const currentChallenge = useSelector((state: StoreState) => state.lives.item?.currentChallenge);
  const [price, setPrice] = useState<number | undefined>(undefined);
  const [showInput, setShowInput] = useState<boolean>(false);
  const [title, setTitle] = useState<string | undefined>(undefined);
  const [challengeInProgress, setChallengeInProgress] = useState<boolean>(false);

  useEffect(() => {
    setChallengeInProgress(currentChallenge !== null);
  }, [currentChallenge]);

  if (!visible) return null;

  const handleOverlayClick = () => {
    setTitle("");
    setPrice(undefined);
    onClose();
  };

  const handleModalClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
  };

  const handlePriceChange = (value: number | null) => {
    if (value !== null) {
      setPrice(value);

      if (value > 9999) {
        message.warning(t("maximum-price-alert"));
      }
    }
  };

  const createNewChallenge = () => {
    setChallengeInProgress(false);
  };

  const deleteCurrentChallenge = () => {
    if (!currentChallenge) {
      message.warning(t("something-want-wrong"));
      return;
    }
    dispatch(deleteChallenge(currentChallenge._id!));
  };

  const createChallengeLive = () => {
    if (!title && !price && !streamId) {
      message.warning(t("something-want-wrong"));
      return;
    }
    dispatch(createStreamChallenge({ streamId: streamId!, title: title!, price: price! }));
    setTitle("");
    setPrice(undefined);
    onClose();
  };

  return (
    <div className="challenge-live-modal-overlay" onClick={handleOverlayClick}>
      <div className="challenge-live-modal-content" onClick={handleModalClick}>
        <Row className="w-full justify-content-center mt-10">
          <div className="header-icon-grey" onClick={handleOverlayClick}></div>
        </Row>
        {!challengeInProgress ? (
          <>
            <div className="challenge-live-header">
              <Typography.Text className="challenge-live-title">{t("create-your-challenge")}</Typography.Text>
            </div>
            <div className="challenge-live-content">
              <div>
                <Row className="w-full justify-content-center">
                  <Typography.Text className="challenge-input-label">{t("your-title")}</Typography.Text>
                </Row>
                <Input
                  className="challenge-input-title"
                  bordered={false}
                  size="large"
                  step="0.01"
                  onChange={(value) => {
                    if (value.target.value.length <= 58) {
                      setTitle(value.target.value);
                    }
                  }}
                  value={title}
                  placeholder={t("title")!}
                />
                <div className="divider-wrapper">
                  <div className="challenge-divider-input" />
                </div>
              </div>
              <div className="challenge-price-wrapper">
                <Row className="w-full justify-content-center">
                  <Typography.Text className="challenge-input-label">{t("your-price")}</Typography.Text>
                </Row>

                {!showInput ? (
                  <div
                    className={price ? "challenge-price-input-completed" : "challenge-price-input"}
                    onClick={() => {
                      setShowInput(true);
                    }}
                  >
                    {price !== undefined ? price : "0.00"} €
                  </div>
                ) : (
                  <InputNumber
                    className={"challenge-price-input-completed"}
                    min={5}
                    bordered={false}
                    onChange={(value) => {
                      handlePriceChange(value);
                    }}
                    value={price}
                    autoFocus={true}
                    onBlur={() => {
                      setShowInput(false);
                    }}
                    inputMode="numeric"
                  />
                )}
              </div>
            </div>
            <div className="challenge-live-footer">
              <AuthButtonComponent height="50px" label={t("continue")} onClick={createChallengeLive} disabled={!title || !price} />
            </div>
          </>
        ) : (
          <>
            <div className="challenge-live-header">
              <Typography.Text className="challenge-live-title">{t("what-action-do-you-want-to-take")}</Typography.Text>
            </div>
            <div className="new-challenge-button-container">
              <button className="new-challenge-button" onClick={deleteCurrentChallenge}>
                <Typography.Text className="new-challenge-button-title">{t("delete-challenge")}</Typography.Text>
              </button>
              <button className="new-challenge-button mt-14" onClick={createNewChallenge}>
                <Typography.Text className="new-challenge-button-title">{t("create-new-challenge")}</Typography.Text>
                <Typography.Text className="new-challenge-button-desc mt-5">{t("create-new-challenge-desc")}</Typography.Text>
              </button>
              <button className="new-challenge-cancel-button" onClick={onClose}>
                <Typography.Text className="new-challenge-cancel-button-title">{t("back-to-live")}</Typography.Text>
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default CreateChallengeLiveModalComponent;
