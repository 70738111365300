import axios from "../helpers/axios";
import { IFile } from "../helpers/types";

class FileService {
  public uploadPhoto = (formData: FormData) => {
    const url = `/files/upload/photo`;

    return new Promise<IFile>((resolve, reject) => {
      axios
        .post(url, formData)
        .then((response) => {
          if (response?.data?.data) {
            resolve(response.data.data);
          } else {
            reject("Something went wrong.");
          }
        })
        .catch((error) => {
          if (error?.response?.data?.message) reject(error.response.data.message);
          else reject("Something went wrong.");
        });
    });
  };

  public uploadVideo = (formData: FormData) => {
    const url = `/files/upload/video`;

    return new Promise<IFile>((resolve, reject) => {
      axios
        .post(url, formData)
        .then((response) => {
          if (response?.data?.data) {
            resolve(response.data.data);
          } else {
            reject("Something went wrong.");
          }
        })
        .catch((error) => {
          if (error?.response?.data?.message) reject(error.response.data.message);
          else reject("Something went wrong.");
        });
    });
  };

  public uploadMedia = (formData: FormData, setProgress: Function) => {
    const url = `/files/upload/media`;

    return new Promise<IFile>((resolve, reject) => {
      axios
        .post(url, formData, {
          onUploadProgress: (event) => {
            let progress = Math.round((100 * event.loaded) / event.total!);
            progress = Math.round(progress / 2);
            if (progress <= 50) {
              setProgress(progress);
            }
          },
        })
        .then((response) => {
          if (response?.data?.data) {
            resolve(response.data.data);
          } else {
            reject("Something went wrong.");
          }
        })
        .catch((error) => {
          if (error?.response?.data?.message) reject(error.response.data.message);
          else reject("Something went wrong.");
        });
    });
  };

  public deleteFile = (id: string) => {
    const url = `/files/${id}`;

    return new Promise<void>((resolve, reject) => {
      axios
        .delete(url)
        .then((response) => {
          if (response?.data?.data) {
            resolve(response.data.data);
          } else {
            reject("Something went wrong.");
          }
        })
        .catch((error) => {
          if (error?.response?.data?.message) reject(error.response.data.message);
          else reject("Something went wrong.");
        });
    });
  };

  public allSelectedFiles = (fileIds: Array<any>) => {
    const url = `/files/getUrl`;

    return new Promise<any>((resolve, reject) => {
      axios
        .post(url, fileIds)
        .then((response) => {
          if (response?.data?.data) {
            resolve(response.data.data);
          } else {
            reject("Something went wrong.");
          }
        })
        .catch((error) => {
          if (error?.response?.data?.message) reject(error.response.data.message);
          else reject("Something went wrong.");
        });
    });
  };
}

export const fileService = new FileService();
