import { Row, Typography } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

type props = {
  fanSubscriptionModel?: any;
  modifySubscriptionPlan?: any;
  setFanSubscriptionModel?: any;
  unSubscribe?: any;
  isCreator?: any;
};

const FanSubscriptionPageModel: React.FC<props> = ({
  fanSubscriptionModel,
  modifySubscriptionPlan,
  setFanSubscriptionModel,
  unSubscribe,
  isCreator,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  if (!fanSubscriptionModel) return null;

  const handleModalClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
  };

  const convertdate = (date: string) => {
    const createdAt = new Date(date);
    const formattedDate = createdAt
      .toLocaleDateString(`${t("current-language")}`, {
        day: "2-digit",
        month: "short",
        year: "numeric",
      })
      .replace(".", "");
    return formattedDate;
  };

  return (
    <div
      className="bottom-modal-overlay"
      onClick={() => {
        setFanSubscriptionModel(false);
      }}
    >
      <div className="bottom-modal-content" onClick={handleModalClick}>
        <Row className="w-full justify-content-center mt-10">
          <div
            className="header-icon-grey"
            onClick={() => {
              setFanSubscriptionModel(false);
            }}
          ></div>
        </Row>
        <div className="bottom-modal-header">
          <Typography.Text className="bottom-modal-title f-26">{t("what-action-do-you-want-to-take")}</Typography.Text>
        </div>
        <Row className="justify-content-center w-full mt-14">
          <Typography.Text className="w-full text-center font-12-bold text-white">
            {modifySubscriptionPlan.status === "active" ? (
              <>
                {t("subscribed-until")} {convertdate(`${modifySubscriptionPlan?.expiredAt}`)}
              </>
            ) : (
              t("subscribe-for-an-exclusive-relationship")
            )}
          </Typography.Text>
          {modifySubscriptionPlan?.status !== "active" && (
            <Typography.Text className="w-full text-center font-12-bold text-white">
              {t("subscription-valid")} {convertdate(`${modifySubscriptionPlan?.expiredAt}`)}
            </Typography.Text>
          )}
        </Row>
        <div className="bottom-modal-content mt-10">
          {modifySubscriptionPlan.status === "active" ? (
            <button className="bottom-modal-button mt-14" onClick={() => unSubscribe(modifySubscriptionPlan.creatorInfo.id)}>
              <Typography.Text className="bottom-modal-button-title">{t("cancel-renewal-label")}</Typography.Text>
            </button>
          ) : (
            <button
              className="bottom-modal-button subs-button mt-14"
              onClick={() => {
                isCreator
                  ? navigate(`/common/subscribe/${modifySubscriptionPlan?.creatorInfo?.id}`)
                  : navigate(`/fan/subscribe/${modifySubscriptionPlan?.creatorInfo?.id}`);
              }}
            >
              <Typography.Text className="bottom-modal-button-title subs-title">{t("Subscribe-without-obligation")}</Typography.Text>
            </button>
          )}

          <button
            className="bottom-modal-cancel-button"
            onClick={() => {
              setFanSubscriptionModel(false);
            }}
          >
            <Typography.Text className="bottom-modal-cancel-button-title">{t("cancel")}</Typography.Text>
          </button>
        </div>
      </div>
    </div>
  );
};

export default FanSubscriptionPageModel;
