import { Button, Col, Layout, Row, Space, Typography, message } from "antd";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ArrowLeftIconComponent from "../../../components/ArrowLeftIconComponent";
import AuthButtonComponent from "../../../components/AuthButtonCompnent";
import CountDownComponent from "../../../components/CountDownComponent";
import { authService, promotionService } from "../../../services";
import { copyToClipboardCustom } from "../../../utls/FunctionsUtil";
import "./index.less";
import DeletePromoCodeModalProps from "../../../components/DeletePromoCodeModalComponent";

const CreatorCodePromotions = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const shouldFetch = useRef(true);
  const [promoCodes, setPromoCodes] = useState();
  const [promoCode, setPromoCode] = useState();
  const tokenId = authService.getId();

  function getPromoCodes() {
    promotionService
      .GetAllPromotions(tokenId)
      .then((response) => {
        setPromoCodes(response);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    if (shouldFetch.current) {
      shouldFetch.current = false;
      getPromoCodes();
    }
  }, []);

  const DeleteCodePromo = () => {
    promotionService
      .DeletePromo(promoCode._id)
      .then((response) => {
        if (response === true) {
          setPromoCode("");
          getPromoCodes();
          return message.success(t("promo-code-deleted-success-message"));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const promoCopied = async (promoCode) => {
    await copyToClipboardCustom(promoCode, t("something-want-wrong"), t("promo-code-copied-success-message"));
  };

  const closeModal = () => {
    setPromoCode("");
  };

  return (
    <Layout>
      <Layout.Content className="content CreatorCodePromoContentWrapper gradient-background">
        <Row className="relative justify-content-center header-title-container-60">
          <ArrowLeftIconComponent top={10} left={-10} navigateTo="/common/setting" />
          <Typography.Text className="header-title-black-32">{t("promo-code")}</Typography.Text>
        </Row>
        <Space className="w-full relative" direction="vertical">
          {promoCodes &&
            promoCodes.map((promo, index) => {
              return promo.isActive ? (
                <Row className="w-full justify-content-between mt-25 items-center" key={index}>
                  <Col
                    onClick={() => {
                      setPromoCode(promo);
                    }}
                    span={15}
                  >
                    <Space className="w-full" direction="vertical">
                      <Typography.Text className="creatorCodePromoName">{t(`${promo.code}`)}</Typography.Text>
                    </Space>
                    <Space className="w-full mt-7" direction="vertical">
                      <Typography.Text className="creatorCodePromoDiscount ">
                        {`${promo.discount}
                        ${promo.discountType === "PERCENTAGE" ? "%" : promo.discountType === "CURRENCY" ? "€" : ""}
                        ${promo.promoOn === "SUBSCRIBERS" ? t("subscription-on-a") : promo.promoOn === "MEDIA_PUSH" ? t("media-push-on-a") : ""}`}
                      </Typography.Text>
                    </Space>
                  </Col>
                  <Button onClick={() => promoCopied(promo.code)} className="creatorCodePromoCopyButton">
                    {t("copy")}
                  </Button>
                </Row>
              ) : null;
            })}
        </Space>
        <Row className="bottom-sticky-container">
          <Row className="mt-50 createPromoButton" justify={"center"}>
            <AuthButtonComponent label={t("create-promotion-btn")} onClick={() => navigate("/creator/codePromotion/create")} />
          </Row>
        </Row>
        {promoCode && (
          <DeletePromoCodeModalProps
            isOpen={promoCode}
            title={promoCode.code}
            discount={promoCode.discount}
            discountType={promoCode.discountType}
            promoOn={promoCode.promoOn}
            numberOfUses={promoCode.numberOfUses}
            expireTime={promoCode.expireTime}
            expireTimeType={promoCode.expireTimeType}
            expiresOn={promoCode.expiresOn}
            deleteCodePromo={DeleteCodePromo}
            onClose={closeModal}
          />
        )}
      </Layout.Content>
      {/* <FooterComponent type="creator" page={FOOTER_IDS.PROFILE} /> */}
    </Layout>
  );
};

export default CreatorCodePromotions;
