import { Layout, Typography } from "antd";
import { useTranslation } from "react-i18next";
import ShowIcon from "../../assets/icons/show-insta.svg";
import ExplanationComponent from "./ExplanationComponent";
import "./index.less";

type AuthGoogleUnavailableProps = {
  cancel: () => void;
};

const AuthGoogleUnavailableComponent: React.FC<AuthGoogleUnavailableProps> = ({ cancel }) => {
  const { t } = useTranslation();

  return (
    <Layout>
      <Layout.Content className="google-unavailable-content-wrapper gradient-background">
        <div className="show-icon-container">
          <img src={ShowIcon} className="show-icon" alt="login" />
        </div>

        <div className="auth-google-insta-title-container">
          <Typography.Text className="auth-google-insta-title">{t("register-google-external-browser")}</Typography.Text>
        </div>

        <ExplanationComponent cancel={cancel} />
      </Layout.Content>
    </Layout>
  );
};

export default AuthGoogleUnavailableComponent;
