import { Row, Typography } from "antd";
import LiveTipsNumberOneIcon from "../../assets/icons/live-tips-1.svg";
import LiveTipsNumberTwoIcon from "../../assets/icons/live-tips-2.svg";
import LiveTipsNumberThreeIcon from "../../assets/icons/live-tips-3.svg";
import { ITop3Tips } from "../../helpers/types";
import "./index.less";

interface Props {
  topTips: ITop3Tips[];
}
const LiveTipsLeaderboardComponent = (props: Props) => {
  const icons = [LiveTipsNumberOneIcon, LiveTipsNumberTwoIcon, LiveTipsNumberThreeIcon];
  const { topTips } = props;
  return (
    <Row className="live-tips-leaderboard-row">
      {topTips.map((tip, index) => {
        const itemClass = index === 0 ? "first" : index === 1 ? "second" : "third";

        return (
          <div key={tip._id} className={`live-tips-leaderboard-item ${itemClass}`}>
            <img src={icons[index]} className="live-tips-leaderboard-item-icon" alt={`Tips-${index + 1}`} />
            <Typography.Text className="live-tips-leaderboard-item-name">
              {tip.username.length > 10 ? `${tip.username.slice(0, 10)}...` : tip.username}
            </Typography.Text>
            <Typography.Text className="live-tips-leaderboard-item-price">{tip.amount} €</Typography.Text>
          </div>
        );
      })}
    </Row>
  );
};

export default LiveTipsLeaderboardComponent;
