import { Row, Typography } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import "./index.less";

type props = {
  isOpen: boolean;
  hideShareButton?: boolean;
  hideCancelSubButton?: boolean;
  onClose?: () => void;
  onPrivateGalleryClick?: () => void;
  onShareProfileClick?: () => void;
  onCancelSubscriptionClick?: () => void;
};

const ConversationThreeDotsModalComponent: React.FC<props> = ({
  isOpen,
  hideShareButton,
  hideCancelSubButton,
  onClose,
  onPrivateGalleryClick,
  onShareProfileClick,
  onCancelSubscriptionClick,
}) => {
  const { t } = useTranslation();

  if (!isOpen) return null;

  const handleOverlayClick = () => {
    if (onClose) {
      onClose();
    }
  };

  const handleModalClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
  };

  return (
    <div className="bottom-modal-overlay" onClick={handleOverlayClick}>
      <div className="bottom-modal-content" onClick={handleModalClick}>
        <Row className="w-full justify-content-center mt-10">
          <div className="header-icon-grey" onClick={handleOverlayClick}></div>
        </Row>
        <div className="bottom-modal-header">
          <Typography.Text className="bottom-modal-title">{t("what-action-do-you-want-to-take")}</Typography.Text>
        </div>
        <div className="bottom-modal-content">
          {!hideShareButton && (
            <button className="bottom-modal-button" onClick={onShareProfileClick}>
              <Typography.Text className="bottom-modal-button-title">{t("share-profile-via")}</Typography.Text>
            </button>
          )}

          <button className="bottom-modal-button mt-14" onClick={onPrivateGalleryClick}>
            <Typography.Text className="bottom-modal-button-title">{t("private-gallery")}</Typography.Text>
          </button>

          {!hideCancelSubButton && (
            <button className="bottom-modal-button mt-14" onClick={onCancelSubscriptionClick}>
              <Typography.Text className="bottom-modal-button-title">{t("cancel-subcription-label")}</Typography.Text>
            </button>
          )}

          <button className="bottom-modal-cancel-button" onClick={onClose}>
            <Typography.Text className="bottom-modal-cancel-button-title">{t("cancel")}</Typography.Text>
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConversationThreeDotsModalComponent;
